import Utils from "@/utils.js";
import Rights from "@/store/classes/Rights.js";

export default class Carrier{
	constructor( carrier ){
        /* properties list */
        this.id = null;
        this.name = null;
		this.street = null;
		this.hsnr = null;
		this.country = null;
		this.zip = null;
		this.city = null;
		this.tel = null;
		this.fax = null;
		this.email = null;
        this.web = null;
		this.disposalnumber = null;
		this.partner1 = null;
		this.partner2 = null;
		this.iban = null;
		this.bic = null;
		this.bankname = null;
		this.taxnumber = null;
		this.customernumber = null;
		this.contacts = [
			{name: null, email: null, tel: null, fax: null},
			{name: null, email: null, tel: null, fax: null}
		]


		if( !!carrier ){
            if(typeof(carrier.id)          !== "undefined") this.id          = carrier.id;
            if(typeof(carrier.name)        !== "undefined") this.name        = carrier.name;
			if(typeof(carrier.street)      !== "undefined") this.street      = carrier.street;
			if(typeof(carrier.hsnr)        !== "undefined") this.hsnr        = carrier.hsnr;
			if(typeof(carrier.country)     !== "undefined") this.country     = carrier.country;
			if(typeof(carrier.zip)         !== "undefined") this.zip         = carrier.zip;
			if(typeof(carrier.city)        !== "undefined") this.city        = carrier.city;
			if(typeof(carrier.tel)         !== "undefined") this.tel         = carrier.tel;
			if(typeof(carrier.fax)         !== "undefined") this.fax         = carrier.fax;
			if(typeof(carrier.email)       !== "undefined") this.email       = carrier.email;
			if(typeof(carrier.web)         !== "undefined") this.web         = carrier.web;
			if(typeof(carrier.disposalnumber)!== "undefined") this.disposalnumber = carrier.disposalnumber;
			if(typeof(carrier.contacts)       !== "undefined") {					
				this.contacts.splice(0, carrier.contacts.length, ...carrier.contacts)					
			}	

			if(typeof(carrier.iban)        !== "undefined") this.iban        = carrier.iban;
			if(typeof(carrier.bic)     !== "undefined") this.bic     = carrier.bic;
			if(typeof(carrier.bankname)     !== "undefined") this.bankname     = carrier.bankname;
			if(typeof(carrier.taxnumber)         !== "undefined") this.taxnumber         = carrier.taxnumber;
			if(typeof(carrier.customernumber)	    !== "undefined") this.customernumber        = carrier.customernumber;			            
            
		} else {
		}
	}
}
