import Utils from "@/utils.js";
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : process.env.VUE_APP_I18N_LOCALE 
export default class DisposalOutput {
    constructor(disposaloutput) {
        this.id = null;
        this.wastetype = null;
        this.amount = null;        
        this.measure = null;
        this.actiondate = null;
        this.name = null;
        this.remark = null;
        this.locked = false;
        this.staffId = null;                
        this.dismantlerId = null
        this.disposalId = null

        if(!!disposaloutput) {            
            if(typeof disposaloutput.id !== 'undefined') this.id = disposaloutput.id 
            if(typeof disposaloutput.amount !== 'undefined' && disposaloutput.amount !== null) this.amount = disposaloutput.amount.toLocaleString(lang)
            if(typeof disposaloutput.staffId !== 'undefined') this.staffId = disposaloutput.staffId 
            if(typeof disposaloutput.wastetype !== 'undefined') this.wastetype = disposaloutput.wastetype 
            if(typeof disposaloutput.entryIns !== 'undefined') this.entryIns = disposaloutput.entryIns 
            if(typeof disposaloutput.name !== 'undefined') this.name = disposaloutput.name 
            if(typeof disposaloutput.measure !== 'undefined') this.measure = disposaloutput.measure 
            if(typeof disposaloutput.dismantlerId !== 'undefined') this.dismantlerId = disposaloutput.dismantlerId 
            if(typeof disposaloutput.disposalId !== 'undefined') this.disposalId = disposaloutput.disposalId             
            if(typeof disposaloutput.remark !== 'undefined') this.remark = disposaloutput.remark 
            if(typeof disposaloutput.locked !== 'undefined') this.locked = disposaloutput.locked
            if(typeof disposaloutput.actiondate !== 'undefined') this.actiondate = Utils.ISOToDateString(disposaloutput.actiondate );

        }
    }
} 