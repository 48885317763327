import Utils from "@/utils.js";
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : process.env.VUE_APP_I18N_LOCALE 
export default class SparesForm {
    constructor(spares) {
        this.id = null;
        this.amount = null;
        this.ecarId = spares.ecarId;
        this.weight = spares.weight.toLocaleString(lang) ;
        this.onSpareList = spares.onSpareList;
        this.actionDate = null;
        this.spareId = spares.id;
        this.name = spares.name;
        this.nonMetalFactor = spares.nonMetalFactor;
        this.sparecategory = spares.sparecategory; 
/* 
        if( !!spares ) {
            if(typeof(spares.id) !== "undefined") this.spareId = spares.id;
            if(typeof(spares.sparecategory) !== "undefined") this.sparecategory = spares.sparecategory;            
            if(typeof(spares.name) !== "undefined") this.name = spares.name;            
        } */
    }
}