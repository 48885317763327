import Utils from "@/utils.js";
export default class RecyclerOffer {
    constructor(recycleroffer) {
        this.id = null;        
        this.minamount = null        
        this.maxamount = null        
        this.measure = null        
        this.mindistancekm = null        
        this.maxdistancekm = null        
        this.price = null                
        this.recyclerId = null
        this.currency = null
        this.offerterms = null        
        this.cargoTypeId = null        
        this.active = null
        this.selfdelivery = null
        this.negative = null
        this.agblink = null

        if(!!recycleroffer) {
            if(typeof recycleroffer.id !== 'undefined') this.id = recycleroffer.id                                                         
            if(typeof recycleroffer.minamount !== 'undefined') this.minamount = recycleroffer.minamount
            if(typeof recycleroffer.maxamount !== 'undefined') this.maxamount = recycleroffer.maxamount
            if(typeof recycleroffer.measure !== 'undefined') this.measure = recycleroffer.measure
            if(typeof recycleroffer.mindistancekm !== 'undefined') this.mindistancekm = recycleroffer.mindistancekm
            if(typeof recycleroffer.maxdistancekm !== 'undefined') this.maxdistancekm = recycleroffer.maxdistancekm
            if(typeof recycleroffer.price !== 'undefined') this.price = recycleroffer.price
            if(typeof recycleroffer.recyclerId !== 'undefined') this.recyclerId = recycleroffer.recyclerId    
            if(typeof recycleroffer.currency !== 'undefined') this.currency = recycleroffer.currency    
            if(typeof recycleroffer.cargoTypeId !== 'undefined') this.cargoTypeId = recycleroffer.cargoTypeId                                                    
            if(typeof recycleroffer.offerterms !== 'undefined') this.offerterms = recycleroffer.offerterms                                                    
            if(typeof recycleroffer.active !== 'undefined') this.active = recycleroffer.active                    
            if(typeof recycleroffer.selfdelivery !== 'undefined') this.selfdelivery = recycleroffer.selfdelivery                    
            if(typeof recycleroffer.negative !== 'undefined') this.negative = recycleroffer.negative                    
            if(typeof recycleroffer.agblink !== 'undefined') this.agblink = recycleroffer.agblink                    
        }
    }

}