import Vue from 'vue'
import Vuetify from 'vuetify'
import i18n from './i18n'
import 'vuetify/dist/vuetify.min.css'
import themes from './themes'

let i18nInstance = i18n();

Vue.use(Vuetify)

const vuetify_opts = {	
	lang: {
		current : "de",
		t: (key, ...params) => i18nInstance.t(key, params),
		options: {
			customProperties : true	
		},
	},
	theme: {
		dark: false,
		options: {
			customProperties : true	
		},
		themes: {
			
		}	
	}
};

export default new Vuetify(vuetify_opts)