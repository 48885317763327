import Utils from "@/utils.js";
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : process.env.VUE_APP_I18N_LOCALE 
export default class Btbentry {
    constructor(btbentry) {
        this.id = null;
        this.wastetype = null;
        this.amount = null;
        this.measure = null;
        this.actiondate = null;
        this.name = null;
        this.remark = null;
        this.locked = false;
        this.staffId = null;
        this.uiInfo = null;
        this.isSelectable = true;
        this.carId = null;
        this.dismantlerId = null

        if(!!btbentry) {
            
            if(typeof btbentry.id !== 'undefined') this.id = btbentry.id 
            if(typeof btbentry.amount !== 'undefined') this.amount = btbentry.amount !== null ? btbentry.amount.toLocaleString(lang) : btbentry.amount
            if(typeof btbentry.staffId !== 'undefined') this.staffId = btbentry.staffId 
            if(typeof btbentry.wastetype !== 'undefined') this.wastetype = btbentry.wastetype 
            if(typeof btbentry.uiInfo !== 'undefined') this.uiInfo = btbentry.uiInfo 
            if(typeof btbentry.name !== 'undefined') this.name = btbentry.name 
            if(typeof btbentry.measure !== 'undefined') this.measure = btbentry.measure 
            if(typeof btbentry.dismantlerId !== 'undefined') this.dismantlerId = btbentry.dismantlerId 
            if(typeof btbentry.carId !== 'undefined') this.carId = btbentry.carId             
            if(typeof btbentry.remark !== 'undefined') this.remark = btbentry.remark 
            if(typeof btbentry.locked !== 'undefined') this.locked = btbentry.locked 
            if(typeof btbentry.isSelectable !== 'undefined') this.isSelectable = btbentry.isSelectable
            if(typeof btbentry.actiondate !== 'undefined') this.actiondate = Utils.ISOToDateString(btbentry.actiondate);

        }
    }
}