import Utils from "@/utils.js";
import Rights from "@/store/classes/Rights.js";

export default class Employee{
	constructor( employee ){
        /* properties list */        
        this.firstname = null;
		this.lastname = null;
		this.external = false;
		this.qualifications = [];	
		this.hasdoc = false

		if( !!employee ){
            if(typeof(employee.id) !== "undefined") this.id = employee.id;
            if(typeof(employee.hasdoc) !== "undefined") this.hasdoc = employee.hasdoc;
            if(typeof(employee.firstname) !== "undefined") this.firstname = employee.firstname;
			if(typeof(employee.lastname) !== "undefined") this.lastname = employee.lastname;
			if(typeof(employee.external) !== "undefined") this.external = employee.external;
			if(typeof(employee.qualifications) !== "undefined") this.qualifications = employee.qualifications;		            
		} else {
		}
	}
}
