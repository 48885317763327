import Utils from "@/utils.js";
export default class DisposerLite {
  constructor(disposer) {
    /* define base values */
    this.id = null;
    this.disposerId = null;
    this.name = null;
    this.street = null;
    this.hsnr = null;
    this.zip = null;
    this.city = null;
    this.waste = null;    

    if (!!disposer) {
      
      

      if (typeof disposer.id !== "undefined")      this.id      = disposer.id;
      if (typeof disposer.disposerId !== "undefined") this.disposerId = disposer.disposerId;
      if (typeof disposer.street !== "undefined")  this.street  = disposer.street;
      if (typeof disposer.hsnr !== "undefined")  this.hsnr  = disposer.hsnr;
      if (typeof disposer.zip !== "undefined")  this.zip  = disposer.zip;
      if (typeof disposer.city !== "undefined")    this.city    = disposer.city;
      if (typeof disposer.name !== "undefined")     this.name     = disposer.name;
      if (typeof disposer.waste !== "undefined")     this.waste     = disposer.waste;
      
    } else {
      
    }
  }
}
