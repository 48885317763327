import Utils from "@/utils.js";
import Unit from "@/store/classes/Unit.js";
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : process.env.VUE_APP_I18N_LOCALE 
export default class Car {
  constructor(car) {
    /* properties list */
    this.id = null;   
    this.clazz = null;
    this.manufacturer = null;
    this.model = null;
    this.year = null;
    this.hsn = null;
    this.tsn = null;
    this.km = null;
    this.vin = null;
    this.btbWeightTonDifference = null;
    this.quality = null;
    this.licenceplate = null;    
    this.registrated = null;
    this.receptiondate = null;
    this.receptionNumber = null;
    this.vehicleRegistrationDocumentNo = null;
    this.weight = null;
    this.incomplete = null;
    this.colorcode = null;
    this.step = null;
    this.location = null;
    //Extended Attributes
    this.power = null;
    this.capacity = null;
    this.motorcode = null;
    this.transmissioncode = null;
    this.catalystcode = null;
    this.ktype = null;
    this.freetext = null;
    this.purchasePrice = null;

    //Units
    this.shiftsType = null;
    this.trunkType = null;
    this.doorsType = null;
    this.driveType = null;
    this.conditionType = null;
    this.returnpolicyType = null;
    this.colorType = null;
    this.regionType = null;
        
    this.categoryType = null
    this.dmark = "DE"; 
    this.step = null;
    if (!!car) {
      // 
      // 

      if (typeof car.id !== "undefined") this.id = car.id;
      if (typeof car.clazz !== "undefined") this.clazz = car.clazz;
      if (typeof car.manufacturer !== "undefined")
        this.manufacturer = car.manufacturer;
      if (typeof car.model !== "undefined") this.model = car.model;
      if (typeof car.year !== "undefined") this.year = car.year;
      if (typeof car.hsn !== "undefined") this.hsn = car.hsn;
      if (typeof car.tsn !== "undefined") this.tsn = car.tsn;
      if (typeof car.step !== "undefined") this.step = car.step;
      if (typeof car.km !== "undefined") this.km = car.km;
      if (typeof car.vin !== "undefined") this.vin = car.vin;
      if (typeof car.btbWeightTonDifference !== "undefined") this.btbWeightTonDifference = car.btbWeightTonDifference;
      if (typeof car.quality !== "undefined") this.quality = car.quality;
      if (typeof car.location !== "undefined") this.location = car.location;
      if (typeof car.vehicleRegistrationDocumentNo !== "undefined") this.vehicleRegistrationDocumentNo = car.vehicleRegistrationDocumentNo;
      if (typeof car.receptionNumber !== "undefined") this.receptionNumber = car.receptionNumber;
      if (typeof car.licenceplate !== "undefined")
        this.licenceplate = car.licenceplate;
      if (typeof car.dmark !== "undefined") this.dmark = car.dmark;
      if (typeof car.registrated !== "undefined")
        this.registrated = Utils.ISOToDateString(car.registrated);
      if (typeof car.receptiondate !== "undefined")
        this.receptiondate = Utils.ISOToDateString(car.receptiondate);
      if (typeof car.weight !== "undefined") this.weight = car.weight;
      if (typeof car.incomplete !== "undefined")
        this.incomplete = car.incomplete;
      if (typeof car.power !== "undefined") this.power = car.power;
      if (typeof car.capacity !== "undefined") this.capacity = car.capacity;
      if (typeof car.motorcode !== "undefined") this.motorcode = car.motorcode;
      if (typeof car.colorcode !== "undefined") this.colorcode = car.colorcode;
      if (typeof car.transmissioncode !== "undefined")
        this.transmissioncode = car.transmissioncode;
      if (typeof car.catalystcode !== "undefined")
        this.catalystcode = car.catalystcode;
      if (typeof car.ktype !== "undefined") this.ktype = car.ktype;
      if (typeof car.freetext !== "undefined") this.freetext = car.freetext;

      if (typeof car.shiftsType !== "undefined") this.shiftsType = car.shiftsType;
      if (typeof car.trunkType !== "undefined") this.trunkType = car.trunkType;
      if (typeof car.doorsType !== "undefined") this.doorsType = car.doorsType;
      if (typeof car.driveType !== "undefined")
        this.driveType = car.driveType;
      if (typeof car.conditionType !== "undefined")
        this.conditionType = car.conditionType;
      if (typeof car.returnpolicyType !== "undefined")
        this.returnpolicyType = car.returnpolicyType;
      if (typeof car.colorType !== "undefined") this.colorType = car.colorType;
      if (typeof car.purchasePrice !== "undefined") this.purchasePrice = car.purchasePrice !== null ? car.purchasePrice.toLocaleString(lang) : car.purchasePrice;
      if (typeof car.categoryType !== "undefined") this.categoryType = car.categoryType;
      if (typeof car.regionType !== "undefined") this.regionType = car.regionType;
      if (typeof car.step !== "undefined") this.step = car.step;
    } else {
      // 
    }
  }
}
