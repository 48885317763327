import Utils from "@/utils.js";
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : process.env.VUE_APP_I18N_LOCALE 
export default class Spareout {
    constructor(spareout) {
        this.id = null;
        this.amount = null;
        this.actionDate = null;
        this.name = null;
        this.weight = null;
        this.spareId = null;
        this.nonMetalFactor = null;

        if( !!spareout ) {
            if(typeof(spareout.id) !== "undefined") this.id = spareout.id;
            if(typeof(spareout.spareId) !== "undefined") this.spareId = spareout.spareId;
            if(typeof(spareout.name) !== "undefined") this.name = spareout.name;
            if(typeof(spareout.amount) !== "undefined") this.amount = spareout.amount;
            if(typeof(spareout.weight) !== "undefined") this.weight = spareout.weight.toLocaleString(lang);
            if(typeof(spareout.nonMetalFactor) !== "undefined") this.nonMetalFactor = spareout.nonMetalFactor;
            if(typeof(spareout.actionDate) !== "undefined") this.actionDate = Utils.ISOToDateString(spareout.actionDate);
        }
    }
}