export default class CarInventory {
    constructor(CarInventory) {
        this.car = CarInventory.car;
        this.receptiondate = CarInventory.receptiondate;
        this.id = CarInventory.id;
        this.quality = CarInventory.quality;
        this.hasBtbEntries = CarInventory.hasBtbEntries;
        this.location = CarInventory.location;
        this.licenceplate = CarInventory.licenceplate;
        this.recID = CarInventory.recID;
        this.manufacturer = CarInventory.manufacturer;
        this.model = CarInventory.model;
        this.step = CarInventory.step;
        this.vin = CarInventory.vin;
        this.isSelectable = CarInventory.isSelectable;
        this.hasRemanOffers = CarInventory.hasRemanOffers;
    }
}