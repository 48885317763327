import VN_Service from "@/services/VN_Services.js";
import store from "@/store/store";
import i18n from "@/i18n";
import Btboutput from "@/store/classes/BtbOutput"; 

const $t = i18n();

export const namespaced = true;

export const state = {
    btboutputs: [],
    btblist: []
}

export const mutations = {
    clearOutputs(state) {
        state.btboutputs = [];
    },
    addOutputs(state, data) {
        data.forEach(btboutput => {
            if(!btboutput.locked) {
                btboutput.isSelectable = true
            } else {
                btboutput.isSelectable = false
            }
            state.btboutputs.push(new Btboutput(btboutput))
        })
    },
    clearList(state) {
        state.btblist = [];
    },
    addList(state,data) {
        data.forEach(list => {state.btblist.push({wastetype: list})})
    },
    addSingleBtbOutput(state,data) {
        data.forEach(output => {state.btboutputs.push(new Btboutput(output))})
    },
    updateSingleBtbOutput(state, data){        
        data.forEach(output => {
            let newoutput = state.btboutputs.find(
                r => r.id === output.id      
            );
            Object.assign(newoutput, new Btboutput(output));
        })
    },
    deleteSingleBtbOutput(state,data) {
        data.forEach(output => {
            let deleted = state.btboutputs.find(
                r => r.id === output.id      
            );
            const index = state.btboutputs.indexOf(deleted);
            state.btboutputs.splice(index,1)
        })
    }
}

export const actions = {
    getBtbOutputs({commit}, dismantlerId) {
        return VN_Service.getBtbOutputs(dismantlerId).then(r => {
            if(r.status === 200 ) {
                commit('clearOutputs');
                commit('addOutputs', r.data)
                return r.data
            }
        })
    },
    getBtbOutputList({commit}, dismantlerId) {
        return VN_Service.getBtbOutputList(dismantlerId).then(r => {
            if(r.status === 200 ) {
                commit('clearList');
                commit('addList', r.data)
                return r.data
            }
        })
    },        
    getBtbOutputDetails({commit}, data) {        
        return VN_Service.getBtbOutputDetails(data.dismantlerId,data.wastetype).then(r => {
            if(r.status === 200 ) {
                return r.data                
            }
        })
    },        
    async saveUpdateBtbOutputs({commit}, data) {
        var output = null;
        if(typeof data === 'object' && data.length > 1) {
             output = data
        } else {
            output = [];
            output.push(data)
        }
        return await VN_Service.saveUpdateBtbOutputs(output)
        .then(r => {
            if (data.deleted === true ){
            commit("deleteSingleBtbOutput", output)
            } else if(r.status === 200) {
                if(data.id === null) {
                    commit("addSingleBtbOutput", r.data)
                    store.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.btbout.created")
                    );
                } else {
                    commit("updateSingleBtbOutput",r.data)
                    store.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.btbout.edited")
                    );
                }
                return r.data;
            }
        })
        .catch(e => {
            /* store.dispatch(
                "errorhandling/addError",
                $t.t("$gt.msg.system.error.btbout.create")
            ); */
        });
    }
}