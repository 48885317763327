import Vue from "vue";
import store from "@/store/store";
import Router from "vue-router";
import VueConfig from "../vue.config.js";
import i18n from '@/i18n'
import iconBodyshop from '@/assets/icons/icon_bodyshop.png';
import iconPurchaser from '@/assets/icons/icon_purchaser.png';

const $t = i18n();
Vue.use(Router);

const App = () => import("@/App.vue");
const LoginProcessConfirm    = () => import(/* webpackChunkName: "confirm" */ "@/views/login_process/Confirm.vue");
const LoginProcessRegistered = () => import(/* webpackChunkName: "registered" */ "@/views/login_process/Registered.vue");
const LoginProcessAccexists = () => import(/* webpackChunkName: "accexists" */ "@/views/login_process/AccountExists.vue");
const LoginProcessLogin      = () => import(/* webpackChunkName: "login" */ "@/views/login_process/Login.vue");
const PasswordForgotten      = () => import(/* webpackChunkName: "pwdfor" */ "@/views/login_process/PasswordForgotten.vue");
const PasswordChange      = () => import(/* webpackChunkName: "pwdchange" */ "@/views/login_process/PasswordChange.vue");

/* TAKES AN ARRAY OF GUARDS */
const multiGuard = function(guards) {
	return function(to, from, next){
		for( let i=0; i < guards.length; i++ ){
			/* execute and check for returning break command (true) */
			if( guards[i](to, from, next) ){				
				return;
			}
		}
		/* if no one broke, fall through to standard behavior */
		next();
	};
};

const Unauthenticated = (to,from,next) => {
	
	const loggedIn = store.getters["accountsModule/loggedIn"];	
	const isUser = localStorage.getItem('current_user');
	let cookies = document.cookie.split(";");
	if( cookies.length > 0 ){
		let user_cookie_key = "user=";
		for( let i=0; i < cookies.length; i++ ){
			//let cookie = cookies[i];
			let cookie = decodeURIComponent( cookies[i] );
			if( cookie.indexOf(user_cookie_key) === 0 ){
				let cookie_value = cookie.substring(user_cookie_key.length);
				
				store.dispatch("accountsModule/cookie_login", JSON.parse(cookie_value) );
				document.cookie = "user=;expires="+new Date(Date.now()-1000)+";path=/;";
				break; // break the loop...
			}
		}
	}

      if (to.matched.some(record => record.meta.requiresAuth) && !isUser && !loggedIn) {
        next('/')
      }
      next()
}

const checkLatest = function() {
	store.dispatch('stockModule/getLatestEffectiveTill',localStorage.getItem('dismantlerId'))	
}

const AccessRights = function(to, from, next){	
	
}


const collectorCheck = function(error, next, property){	
	
	if(!store.getters["collectorsModule/" + property]){
		store.dispatch(
			"errorhandling/addError",
			error.response
		);
		
		next({ name: "demontagebetriebe" });
		return true;
	} else {
		next();
		return false;
	}
};

const deser = function(to,from,next) {	
	const loggedIn = store.getters["accountsModule/loggedIn"];
	const isUser = localStorage.getItem('current_user');	
	if(isUser) {
		next({name: 'dashboard'})
	} else {
		next({name: 'login'})
	}
}
const checkDismantler = () => {
	if(store.state.departmentsModule.dismantlersExist) {
		return true
	} else {
		return false
	}
}
const colors = {
	dashboard: "grey",
	users: "#20a8d8",
	rv: "#be2edd",
	cars: "#f0932b",
	stammdaten: "#337ab7",   
	btb: "#58b790",
	reman: "#f0932b"
}
const globalview = {
	render(h) { return h(`router-view`,{
		'key': 'test'
	})}
}

const router =  new Router({
	mode: "history",
	base: '/app',
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	routes: [
		{
			path: "/",
			name: "home",
			meta: {
				name: 'home',visible: false
			},
			component: App,
			beforeEnter: function(to,from,next) {
				const isUser = localStorage.getItem('current_user');
				if(to.name != 'paymentsuccess') {
					next()
				}
				if(isUser && to.name != 'paymentsuccess') {
					next({name: 'dashboard'});
					return;
				} else {
					if (to.name === 'home' && to.name != 'confirm') {
						next({
							name: 'login'
						});
					} else {
						next();
					}
				}
			},				
			children: [
				{
					path: "login",
					name: "login",
					meta: { name: 'login',visible: false },
					component: LoginProcessLogin,
					beforeEnter: multiGuard([Unauthenticated])
				},
				{
					path: "confirm/:token",
					name: "confirm",
					meta: { name: 'confirm', visible: false },
					component: LoginProcessConfirm,
					props: true,
					beforeEnter: function(to, from, next){
						if (store.getters["accountsModule/loggedIn"]) {
							store.dispatch("accountsModule/logout").then(() => {
								next();
							});
							return;
						}
						next();
					},
				},
				{
					path: "registered",
					name: "registered",
					meta: { name: 'registered', visible: false },
					component: LoginProcessRegistered,
				},
				{
					path: "accexists",
					name: "accexists",
					meta: { name: 'accexists', visible: false },
					component: LoginProcessAccexists,
				},
				{
					path: "pwf/request",
					name: "pwfrequest",
					meta: { name: 'pwfrequest', visible: false },
					component: PasswordForgotten,
				},
				{
					path: "pwf/confirm",
					name: "pwfchange",
					meta: { name: 'pwfchange', visible: false },
					component: PasswordChange,
				}
			]
		},
		{
			path: '/deser',
			meta: {
				requiresAuth: true,
				name: 'app',
				isRoot: true
			},
			component: () => import(/* webpackChunkName: "deserapp" */ "@/themes/layout/AppLayout.vue"),
			beforeEnter: multiGuard([Unauthenticated]),
			children: [								
				{
					path: "dashboard",
					name: "dashboard",
					meta: { 
						name: "dashboard", requiresAuth: true, 
						isNavLink: true,
						icon : "mdi-home", color: colors.dashboard,						
						/* access: () => store.getters["accountsModule/loggedInUser"] */
					},
					component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
					beforeEnter: multiGuard([Unauthenticated]),
				},
				{
					path: "ordermodule",
					name: "ordermodule",
					meta: { 
						name: "ordermodule", requiresAuth: true, 						
						icon : "mdi-book", color: colors.dashboard,	
						order: true					
						/* access: () => store.getters["accountsModule/loggedInUser"] */
					},
					component: () => import(/* webpackChunkName: "ordermodule" */ "@/views/Payment/OrderModule.vue"),
					beforeEnter: multiGuard([Unauthenticated]),
				},				
				{
					path: "profile",
					meta: { 
						name: "profile", requiresAuth: true, 
						isNavLink: true ,
						icon : "mdi-account", color: colors.users, divider: true						
					},
					component: () => import(/* webpackChunkName: "profile" */ "@/views/Account/Account.vue"),
					beforeEnter: multiGuard([Unauthenticated]),
					children: [
						{
							path: "",
							name: "profile",
							meta: { 
								name: 'profile', requiresAuth: true, isNavLink: true
							},
							component: () => import(/* webpackChunkName: "profile" */ "@/views/Account/AccountDetails.vue"),
							beforeEnter: multiGuard([Unauthenticated]),
						}
					]
				},
				{
					path: "usermanagement",
					name: "usermanagement",
					meta: { 
						name: "usermanagement", requiresAuth: true, isNavLink: true,
						icon: "mdi-account-group", color: colors.users,					
					},
					component: () => import(/* webpackChunkName: "usermanagement" */ "@/views/Stammdaten/UserManagement.vue"),
					beforeEnter: multiGuard([Unauthenticated, AccessRights]),
				},
				{
					path: "company",						
					component: () => import(/* webpackChunkName: "company" */ "@/views/Stammdaten/Company/Company.vue"),
					meta: { 
						name: 'company', requiresAuth: true,
						label: $t.tc('$gt.label.company',2), isNavLink: true,
						icon: "mdi-content-copy", color: colors.stammdaten
					},
					beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
					children: [
						{
							path: "",
							name: "company",
							meta: { name: 'company', requiresAuth: true },
							component: () => import(/* webpackChunkName: "company" */ "@/views/Stammdaten/Company/CompanyDetails.vue"),
							beforeEnter : multiGuard([Unauthenticated,  AccessRights ])
						}
					]
				},
				/* stammdaten */
							
				/* /stammdaten */	
				{
					path: "purchaser",
					meta: {
						name: "purchaser", requiresAuth: true,
						isNavLink: true,
						icon: "mdi-car-select", color: colors.cars
					},
					component: () => import(/* webpackChunkName: "purchaser" */ "@/views/Purchaser/Purchaser.vue"),
					beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
					children: [
						{
							path: "",
							name: "purchaser",
							meta: { name: 'purchaser', requiresAuth: true },
							component: () => import(/* webpackChunkName: "purchaser" */ "@/views/Purchaser/PurchaserView.vue"),
							beforeEnter: multiGuard([Unauthenticated, AccessRights ])
						},
					]
				},												
				{
					path: "carpickup",
					meta: { 
						name: 'carpickup', requiresAuth: true, 
						isNavLink: true ,
						icon : "mdi-car-arrow-left", color: colors.cars,
						
					},
					component: () => import(/* webpackChunkName: "receptions" */ "@/views/Receptions/Receptions.vue"),
					beforeEnter: multiGuard([Unauthenticated, AccessRights]),
					children: [
						{
							path: "",
							name: "carpickup",
							meta: { name: 'carpickup', requiresAuth: true },
							component: () => import(/* webpackChunkName: "receptions" */ "@/views/Receptions/ReceptionsTable.vue"),
							beforeEnter: multiGuard([Unauthenticated,  AccessRights])
						},
						{
							path: "details/:receptionID",
							name: "carpickupDetails",
							meta: { name: 'carpickup', sse: 'reception', requiresAuth: true },
							component: () => import(/* webpackChunkName: "receptions" */ "@/views/Receptions/ReceptionsDetails.vue"),
							beforeEnter: multiGuard([Unauthenticated,  AccessRights])
						},
						{
							path: "neu",
							name: "carpickupNew",
							meta: { name: 'carpickup',  requiresAuth: true },
							component: () => import(/* webpackChunkName: "receptions" */ "@/views/Receptions/ReceptionsDetails.vue"),
							beforeEnter: multiGuard([Unauthenticated,  AccessRights])
						}
					]
				},
				{
					path: 'remanufacturer',
					meta: {
						requiresAuth: true,
						name: 'remanufacturer',
						isRoot: true,
						icon: "mdi-registered-trademark", color: colors.reman
					},
					component: globalview,
					beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
					children: [							
						{
							path: 'import',
							name: 'articleimport',
							meta: {
								requiresAuth: true,
								name: 'articleimport',
								isNavLink: true,
								icon: 'mdi-application-import', color: colors.reman
							},
							component: () => import(/* webpackChunkName: "reman" */ "@/views/Remanufacturer/Reman/Import.vue"),
							beforeEnter: multiGuard([Unauthenticated, AccessRights ])
						},
						{
							path: 'boxes',
							name: 'cart',
							meta: {
								requiresAuth: true,
								name: 'cart',
								isNavLink: true,
								icon: 'mdi-cart', color: colors.reman								
							},
							component: () => import(/* webpackChunkName: "reman" */ "@/views/Remanufacturer/Reman/Carts.vue"),
							beforeEnter: multiGuard([Unauthenticated, AccessRights ])
						},
						{
							path: 'deliveries',
							name: 'remandeliveries',
							meta: {
								requiresAuth: true,
								name: 'remandeliveries',
								isNavLink: true,
								icon: 'mdi-truck-check-outline', color: colors.reman
							},
							component: () => import(/* webpackChunkName: "reman" */ "@/views/Remanufacturer/Reman/MyDeliveries.vue"),
							beforeEnter: multiGuard([Unauthenticated, AccessRights ])
						},
						{
							path: 'deliveries/details/:deliveryId',
							name: 'remandeliveryDetails',
							meta: { name: 'remandeliveries', requiresAuth: true,
							icon: 'mdi-truck-check-outline', color: colors.reman },
							component:  () => import(/* webpackChunkName: "reman" */ "@/views/Remanufacturer/Reman/DeliveryDetails.vue"),
							beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
						},
					]
				},
				{
					path: 'myreman',
					meta: {
						requiresAuth: true,
						name: 'dismantlerReman',
						isRoot: true,
						icon: "mdi-registered-trademark", color: colors.reman
					},
					component: globalview,
					beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
					children: [
						{
							path: 'offers/:carId?',
							name: 'dismantlerRemanOffers',
							props: true ,
							meta: { name: 'dismantlerRemanOffers', requiresAuth: true, isNavLink: true,
							icon: 'mdi-offer', color: colors.reman },
							component: () => import(/* webpackChunkName: "dismantlerReman" */ "@/views/Remanufacturer/Dismantler/Offers.vue"),
							beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
						},			
						{
							path: 'box',
							name: "myboxes",
							meta: { name: 'myboxes', requiresAuth: true, isNavLink: true,
							icon: 'mdi-cart', color: colors.reman	},
							component:  () => import(/* webpackChunkName: "dismantlerReman" */ "@/views/Remanufacturer/Dismantler/MyBoxes.vue"),
							beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
						},
						{
							path: 'deliveries',
							name: "deliveries",
							meta: { name: 'deliveries', requiresAuth: true, isNavLink: true,
							icon: 'mdi-truck-check-outline', color: colors.reman },
							component:  () => import(/* webpackChunkName: "dismantlerReman" */ "@/views/Remanufacturer/Dismantler/MyDeliveries.vue"),
							beforeEnter: multiGuard([Unauthenticated, AccessRights ]),							
						},
						{
							path: 'deliveries/details/:deliveryId',
							name: 'deliveryDetails',
							meta: { name: 'deliveries', requiresAuth: true,
							icon: 'mdi-truck-check-outline', color: colors.reman },
							component:  () => import(/* webpackChunkName: "dismantlerReman" */ "@/views/Remanufacturer/Dismantler/DeliveryDetails.vue"),
							beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
						}
					]
				},
				{
					path: "exchangelooper",
					meta: {
						name: "LOOPER", requiresAuth: true,
						isRoot: true,
						icon: "mdi-currency-eur", color: colors.cars
					},
					component: () => import(/* webpackChunkName: "looper" */ "@/views/Looper/Looper.vue"),
					beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
					children: [
						{
							path: "mycargo",
							name: "loopermycargo",
							meta: { name: 'loopermycargo', color: colors.reman, icon: "mdi-currency-eur", requiresAuth: true, isNavLink: true},
							component: () => import(/* webpackChunkName: "looper" */ "@/views/Looper/LooperDetails.vue"),
							beforeEnter: multiGuard([Unauthenticated, AccessRights ])
						},
						{
							path: "myorders",
							name: "loopermyorders",
							meta: { name: 'loopermyorders', color: colors.reman, icon: "mdi-currency-eur", requiresAuth: true, isNavLink: true},
							component: () => import(/* webpackChunkName: "looper" */ "@/views/Looper/LooperOrdersDetails.vue"),
							beforeEnter: multiGuard([Unauthenticated, AccessRights ])
						},
					]
				},				
				{
					path: "exchangerecycler",
					meta: {
						name: "RECYCLER", requiresAuth: true,
						isNavLink: true,
						icon: "mdi-currency-eur", color: colors.cars
					},
					component: () => import(/* webpackChunkName: "recycler" */ "@/views/Recycler/Recycler.vue"),
					beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
					children: [
						{
							path: "",
							name: "RECYCLER",
							meta: { name: 'RECYCLER', requiresAuth: true },
							component: () => import(/* webpackChunkName: "recycler" */ "@/views/Recycler/RecyclerDetails.vue"),
							beforeEnter: multiGuard([Unauthenticated, AccessRights ])
						},
					]
				},
				{
					path: "carsinventory",
					meta: { 
						name: 'carsinventory', requiresAuth: true, 
						isNavLink: true,
						icon: "mdi-car", color: colors.btb
					},
					component: () => import(/* webpackChunkName: "carsinventory" */ "@/views/Cars/CarsInventory.vue"),
					beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
					children: [
						{
							path: "",
							name: "carsinventory",
							meta: { name: 'carsinventory', requiresAuth: true },
							component: () => import(/* webpackChunkName: "carsinventory" */ "@/views/Cars/CarsInventoryTable.vue"),
							beforeEnter: multiGuard([Unauthenticated, AccessRights ])
						},
						{
							path: "details/:carId",
							name: "carsinventoryDetails",
							meta: { name: 'carsinventory', requiresAuth: true },
							component: () => import(/* webpackChunkName: "carsinventory" */ "@/views/Cars/CarsInventoryDetails.vue"),
							beforeEnter: multiGuard([Unauthenticated, AccessRights ])
						},				
						{
							path: "neu",
							name: "carsinventoryNew",
							meta: { name: 'carsinventory', requiresAuth: true },
							component: () => import(/* webpackChunkName: "carsinventory" */ "@/views/Cars/CarsInventoryDetails.vue"),
							beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
						},
					]
				},																						
				{ //Preparing BTB routing
					path: "btb",
					meta: { 
						name: 'operatingLog', requiresAuth: true, 
						isRoot: true,
						icon: "mdi-book", color: colors.btb
					},
					component: globalview,					
					beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
					children: [						
						{
							path: "btb",
							name: "betriebstagebuch",
							meta: { name: 'betriebstagebuch', requiresAuth: true },
							component: () => import(/* webpackChunkName: "btb" */ "@/views/Btb/Betriebstagebuch.vue"),
							beforeEnter: multiGuard([Unauthenticated, AccessRights ])
						},				
						{
							path: "incidents",
							meta: { 
								name: 'incidents', requiresAuth: true, 
								isNavLink: true,
								icon: "mdi-ambulance", color: colors.btb
							},
							component:  () => import(/* webpackChunkName: "incidents" */ "@/views/Btb/Incidents/Incidents.vue"),
							beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
							children: [
								{	
									path: "",
									name: "incidents",
									meta: { name: 'incidents', requiresAuth: true  },
									component:  () => import(/* webpackChunkName: "incidents" */ "@/views/Btb/Incidents/IncidentsTable.vue"),
									beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
								}
							]	
						},
						{
							path: "",
							component: globalview,
							meta: { name: "datas", requiresAuth: true, isSubRoot: true },
							beforeEnter: multiGuard([Unauthenticated, AccessRights]),
							children: [
								{
									path: "carriers",
									meta: { 
										name: 'carrier', requiresAuth: true, 
										isNavLink: true,
										icon: "mdi-truck", color: colors.btb
									},
									component: () => import(/* webpackChunkName: "carriers" */ "@/views/Btb/Carriers/Carriers.vue"),
									beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
									children: [
										{
											path: "",
											name: "carrier",
											meta: { name: 'carrier', requiresAuth: true },
											component: () => import(/* webpackChunkName: "carriers" */ "@/views/Btb/Carriers/CarriersTable.vue"),
											beforeEnter: multiGuard([Unauthenticated, AccessRights ])
										},
										{
											path: "details/:carrierID",
											name: "carriersDetails",
											meta: { name: 'carrier', requiresAuth: true },
											component: () => import(/* webpackChunkName: "carriers" */ "@/views/Btb/Carriers/CarriersDetails.vue"),
											beforeEnter: multiGuard([Unauthenticated, AccessRights ])
										},				
										{
											path: "neu",
											name: "carrierNew",
											meta: { name: 'carrier', requiresAuth: true },
											component: () => import(/* webpackChunkName: "carriers" */ "@/views/Btb/Carriers/CarriersDetails.vue"),
											beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
										},
									]
								},						
								{
									path: "disposers",
									meta: { 
										name: 'disposer', requiresAuth: true, 
										isNavLink: true,
										icon: "mdi-recycle", color: colors.btb
									},
									component: () => import(/* webpackChunkName: "disposers" */ "@/views/Btb/Disposers/Disposers.vue"),
									beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
									children: [
										{
											path: "",
											name: "disposer",
											meta: { name: 'disposer', requiresAuth: true },
											component: () => import(/* webpackChunkName: "disposers" */ "@/views/Btb/Disposers/DisposersTable.vue"),
											beforeEnter: multiGuard([Unauthenticated, AccessRights ])
										},
										{
											path: "details/:disposerID",
											name: "disposersDetails",
											meta: { name: 'disposer', requiresAuth: true },
											props: true,
											component: () => import(/* webpackChunkName: "disposers" */ "@/views/Btb/Disposers/DisposersDetails.vue"),
											beforeEnter: multiGuard([Unauthenticated, AccessRights ])
										},				
										{
											path: "neu",
											name: "disposersNew",
											meta: { name: 'disposer', requiresAuth: true },
											props: true,
											component: () => import(/* webpackChunkName: "disposers" */ "@/views/Btb/Disposers/DisposersDetails.vue"),
											beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
										},
									]
								},
								{
									path: "employee",
									meta: { 
										name: 'employee', requiresAuth: true, 
										isNavLink: true,
										icon: "mdi-account", color: colors.btb
									},
									props: true,
									component:  () => import(/* webpackChunkName: "employees" */  "@/views/Btb/Employees/Employees.vue"),
									beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
									children: [
										{
											path: "",
											name: "employee",
											meta: { name: 'employee', requiresAuth: true },
											component:  () => import(/* webpackChunkName: "employees" */ "@/views/Btb/Employees/EmployeesTable.vue"),
											beforeEnter: multiGuard([Unauthenticated, AccessRights ])
										},
										{
											path: "details",
											name: "employeeDetails",
											meta: { name: 'employee', requiresAuth: true },
											component:  () => import(/* webpackChunkName: "employees" */  "@/views/Btb/Employees/EmployeeDetails.vue"),
											beforeEnter: multiGuard([Unauthenticated, AccessRights ])
										}
									]
								},
							]
						},
						{
							path: "",
							component: globalview,
							meta: { name: "fractions", requiresAuth: true, isSubRoot: true },
							beforeEnter: multiGuard([Unauthenticated, AccessRights]),
							children: [
								{
									path: "entries",
									meta: { 
										name: 'btbentry', requiresAuth: true, 
										isNavLink: true,
										icon: "mdi-recycle-variant", color: colors.btb
									},
									component: () => import(/* webpackChunkName: "btbentry" */ "@/views/Btb/Entry/Entry.vue"),
									beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
									children: [
										{
											path: "",
											name: "btbentry",
											meta: { name: 'btbentry', requiresAuth: true },
											component:  () => import(/* webpackChunkName: "btbentry" */ "@/views/Btb/Entry/EntryTable.vue"),
											beforeEnter: multiGuard([Unauthenticated, AccessRights ])
										},
										{
											path: "details/:carID",
											name: "btbentryDetails",
											meta: { name: 'btbentry', requiresAuth: true },
											component:  () => import(/* webpackChunkName: "btbentry" */ "@/views/Btb/Entry/EntryDetails.vue"),
											beforeEnter: multiGuard([Unauthenticated, AccessRights ])
										},				
										{
											path: "neu",
											name: "btbentryNew",
											meta: { name: 'btbentry', requiresAuth: true },
											component:  () => import(/* webpackChunkName: "btbentry" */ "@/views/Btb/Entry/EntryDetails.vue"),
											beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
										}
									]
								},	
								{
									path: "output",
									meta: { 
										name: 'btboutput', requiresAuth: true, 
										isNavLink: true,
										icon: "mdi-recycle-variant", color: colors.btb, class: "reflect-icon" 
									},
									component:  () => import(/* webpackChunkName: "btboutput" */ "@/views/Btb/Output/Output.vue"),
									beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
									children: [
										{	
											path: "",
											name: "btboutput",
											meta: { name: 'btboutput', requiresAuth: true },
											component:  () => import(/* webpackChunkName: "btboutput" */ "@/views/Btb/Output/OutputTable.vue"),
											beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
										},
										{
											path: "details/:disposalId",
											name: "btboutputDetails",
											meta: { name: 'btboutput', requiresAuth: true },
											component:  () => import(/* webpackChunkName: "btboutput" */ "@/views/Btb/Output/DisposalDetails.vue"),
											beforeEnter: multiGuard([Unauthenticated, AccessRights ])
										},				
										{
											path: "neu",
											name: "btboutputNew",
											meta: { name: 'btboutput', requiresAuth: true },
											component:  () => import(/* webpackChunkName: "btboutput" */ "@/views/Btb/Output/DisposalDetails.vue"),
											beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
										},
										{
											path: "spares/details/:spareId",
											name: "spareoutDetails",
											meta: { name: 'btboutput', requiresAuth: true },
											component:  () => import(/* webpackChunkName: "spareout" */ "@/views/Btb/Spares/SpareOutDetails.vue"),
											beforeEnter: multiGuard([Unauthenticated, AccessRights ])
										},				
										{
											path: "spares/neu",
											name: "spareoutNew",
											meta: { name: 'btboutput', requiresAuth: true },
											component:  () => import(/* webpackChunkName: "spareout" */ "@/views/Btb/Spares/SpareOutDetails.vue"),
											beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
										}
									]	
								},
								/* {
									path: "spareout",
									meta: { 
										name: 'btbspareout', requiresAuth: true, 
										icon: "mdi-recycle-variant", color: colors.btb, class: "reflect-icon" 
									},
									component:  () => import(webpackChunkName: "spareout"  "@/views/Btb/Spares/Spares.vue"),
									beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
									children: [		
										{
											path: "details/:spareId",
											name: "spareoutDetails",
											meta: { name: 'btbspareout', requiresAuth: true },
											component:  () => import( webpackChunkName: "spareout"  "@/views/Btb/Spares/SpareOutDetails.vue"),
											beforeEnter: multiGuard([Unauthenticated, AccessRights ])
										},				
										{
											path: "neu",
											name: "spareoutNew",
											meta: { name: 'btbspareout', requiresAuth: true },
											component:  () => import( webpackChunkName: "spareout" "@/views/Btb/Spares/SpareOutDetails.vue"),
											beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
										}
									]
								}, */				
								{
									path: "statistic",
									meta: { 
										name: 'btbstock', requiresAuth: true, 
										isNavLink: true,
										icon: "mdi-chart-bar", color: colors.btb,
									},
									component:  () => import(/* webpackChunkName: "stocks" */ "@/views/Btb/Statistics/Statistics.vue"),
									beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
									children: [
										{	
											path: "",
											name: "btbstock",
											meta: { name: 'btbstock', requiresAuth: true  },
											component:  () => import(/* webpackChunkName: "stocks" */ "@/views/Btb/Statistics/FractionStatistic.vue"),
											beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
										}
									]	
								}
							]
						},
						{
							path: "statements",
							meta: { 
								name: 'statements', requiresAuth: true, 
								isNavLink: true,
								icon: "mdi-chart-box-plus-outline", color: colors.btb
							},
							component:  () => import(/* webpackChunkName: "Statements" */ "@/views/Btb/Statements/Statements.vue"),
							beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
							children: [
								{	
									path: "",
									name: "statements",
									meta: { name: 'statements', requiresAuth: true  },
									component:  () => import(/* webpackChunkName: "Statements" */ "@/views/Btb/Statements/StatementsTable.vue"),
									beforeEnter: multiGuard([Unauthenticated, AccessRights ]),
								},
								{
									path: "details/:statementId",
									name: "statementDetails",
									meta: { name: 'statements', requiresAuth: true },
									component:  () => import(/* webpackChunkName: "statement" */ "@/views/Btb/Statements/StatementDetails.vue"),
									beforeEnter: multiGuard([Unauthenticated, AccessRights ])
								},
							]	
						},						
					]
				},				
				{
					path: "evaluations",
					meta: { 
						name: "evaluations", requiresAuth: true, 
						isNavLink: true ,
						icon : "mdi-chart-pie", color: colors.users						
					},
					component: () => import(/* webpackChunkName: "Elevations" */ "@/views/Evaluations/Evaluations.vue"),
					beforeEnter: multiGuard([Unauthenticated]),
					children: [
						{
							path: "",
							name: "evaluations",
							meta: { 
								name: 'evaluations', requiresAuth: true, isNavLink: true
							},
							component: () => import(/* webpackChunkName: "Elevations" */ "@/views/Evaluations/EvaluationsDetails.vue"),
							beforeEnter: multiGuard([Unauthenticated]),
						}
					]
				},
				{
					path: "documents",
					meta: { 
						name: "documents", requiresAuth: true, 
						isNavLink: true ,
						icon : "mdi-file-document", color: colors.users						
					},
					component: () => import(/* webpackChunkName: "Documents" */ "@/views/Documents/Documents.vue"),
					beforeEnter: multiGuard([Unauthenticated]),
					children: [
						{
							path: "",
							name: "documents",
							meta: { 
								name: 'documents', requiresAuth: true, isNavLink: true
							},
							component: () => import(/* webpackChunkName: "Documents" */ "@/views/Documents/DocumentsDetails.vue"),
							beforeEnter: multiGuard([Unauthenticated]),
						}
					]
				},
			]
		},
		{
			path: "/payment-success",
			name: "paymentsuccess",
			meta: { name: 'paymentsuccess', visible:false },
			component: () => import('@/views/Payment/PaymentReceive.vue')
		}
	]
});

const checkDismantlerSet = async function (to, from, next) {	
	
}

function getdismantlerId () {
	return new Promise((resolve, reject) => {
		
	  if (store.state.accountsModule.dismantlerId === null) {
		const unwatch = store.watch(
		  () => store.state.accountsModule.dismantlerId,
		  (value) => {
			unwatch()
			resolve(value)
		  }
		)
	  } else {
		resolve(store.state.accountsModule.dismantlerId)
	  }
	})
  }
  
router.beforeEach(async (to,from,next) => {		
	const user = localStorage.getItem('current_user')
	const userstorage = JSON.parse(user)	
	const modules = {
		/* dismantler : "STD",
		collector : "STD",
		carpickup : "VN",
		usermanagement : "RV", */
		operatingLog : "BTB",
		betriebstagebuch : "BTB",
		disposers: "BTB",
		btb : "BTB",
		btboutput : "BTB",
		btbentry : "BTB",
		carrier: "BTB",
		disposer: "BTB",
		carsinventory : "BTB",
		incidents : "BTB",
		employee : "BTB",
		btbstock : "BTB",
		remanufacturer: "RM",
		dismantlerReman: "RMD"
	}	
	console.log(to.meta.name)
	store.state.appModule.helpType = to.meta.name
	if(store.state.appModule.helpbar === true) {
		if(from.meta.name === to.meta.name) {
			next()
		} else {
			store.commit("appModule/SET_HELPBAR", false)
		}		
	}
	if(await store.getters["accountsModule/loggedIn"]){				
		if(to.meta.name in modules && !userstorage) {
			const errorItem = {
				response: {
				  'customerror': true,
				  'data': {
					"message":"Unauthorized",
					'status': 401,					
				  }
				}
			  };
			  store.dispatch("errorhandling/addError", errorItem.response);
			store.dispatch("accountsModule/logout");
		}
		let loggedInUser = await store.getters["accountsModule/loggedInUser"];
	
		/* if(!loggedInUser.myRights.accessGranted(modules[to.meta.name], "R")) {
			
			next('dashboard')
			return false;
		} */
		/* if(to.name === 'dismantler' && loggedInUser.mandators[0].departments && loggedInUser.mandators[0].departments.length !== 0) {
			next()			
			return false
		} else  */		
		if(to.name !== 'company' && loggedInUser.mandators[0].departments && loggedInUser.mandators[0].departments.length === 0 ) {
			next({name: "company"})
			return false
		} else {
			next()			
		}
		const hasCollectors = await store.dispatch("collectorsModule/getAssignableCollectors")	
		// Refactoring for loop2win
		if(hasCollectors.length !== 0 && !loggedInUser.myRights.accessGranted('RM', "R")) {			
			const disId = await getdismantlerId() 
			if( disId === null) {
				return false
			} 
			if(disId) {
				next()
				return true
			}
		}
		if(loggedInUser.head === true && to.meta.name in modules && !loggedInUser.myRights.accessGranted(modules[to.meta.name], "R")){			
			next({name: "ordermodule"})			
			return false;
		} 
		if(to.meta.name in modules && loggedInUser.myRights.accessGranted(modules[to.meta.name], "R") || to.path.includes('myreman')) {	
			
			if(to.matched[1].meta.name === 'operatingLog') {
				checkLatest()
			}
						
			if(hasCollectors.length !== 0) {      
				next()
				return true;
			} else {
				const error = {
					response: {
						'customerror': true,
						'data': {            
						'status': 3,
							'errnum': 5
						}
					}
				};	
				next({ name: 'company'})	
				store.dispatch("errorhandling/addError", error.response)
				return false;	
			}			
		}  		
	}	
	next();
	return true;
})

/* 
router.beforeEach((to, from, next) => {
	const lastRouteName = localStorage.getItem('LS_ROUTE_KEY');
	
	const shouldRedirect = Boolean(
	  to.name === "home" &&
	  lastRouteName 
	);
  
	if (shouldRedirect) next({ name: lastRouteName });
	else next();
  });
router.afterEach(to => {
	localStorage.setItem('LS_ROUTE_KEY', to.name);
  }); */

export default router;
