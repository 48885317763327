import VN_Service from "@/services/VN_Services.js";
import i18n from "@/i18n";
import { client } from "@/services/client.js";
import Vue from 'vue';
const $t = i18n();
export const namespaced = true;

export const state = {
    ssemodal: false,
    ssedata: null
}

export const mutations = {
    setSSEModal: (state, value) => (state.ssemodal = value),
    setSSEData: (state, value) => (state.ssedata = value),
}

var sse = null;
var msgServer = null;
export const actions = {
    async init({commit}) {
        var init = await client.base.get('sse/init');
        if(init.status === 200) {
            

            Vue.prototype.$sse('https://dev2.kaputt-gmbh.de/api/sse/start/' + init.data, { format: 'plain' }) // or { format: 'plain' }
            .then(sse => {
                var context = ''
            // Store SSE object at a higher scope
                msgServer = sse;
                msgServer.subscribe('', (data, rawEvent) => {
                    var context = ''
                    data = JSON.parse(data);                     
                    if(data.dataClass && typeof data.dataClass !== 'undefined') {
                        context = data.dataClass.toLowerCase() +'ID'
                        
                    }
                    
                    if(data.event === 'PING' || data.event === 'OFFLINE' || data.event === 'ONLINE') {
                        
                        /* commit('setSSEModal', true);       
                        commit('setSSEData', data);  */
                        
                    }
                    if(data.event === 'CLIENTEMIT' || data.event === 'LOCK') {
                        commit('setSSEModal', true);       
                        commit('setSSEData', data); 
                    }				
                    
                    
                    if(data.dataId) {		
                        if(this.$app.$route.meta.sse === data.dataClass.toLowerCase() && this.$app.$store.state.accountsModule.userData.id === data.senderAccountId && this.$app.$route.params[context] === data.dataId) {							
                            console.warn('Received a message w/o an event!', data);
                            commit('setSSEModal', true);       
                            commit('setSSEData', data); 
                        }      
                    }
                });
        
                sse.onError(e => {
                    console.error('lost connection; giving up!', e);
                        this.commit('setSSEModal', true);       
                        this.commit('setSSEData', e); 
        
                    // This is purely for example; EventSource will automatically
                    // attempt to reconnect indefinitely, with no action needed
                    // on your part to resubscribe to events once (if) reconnected
                    //sse.close();
                });
            })
        }
    }
}

export const getters = {    
    getsse(state) {
        return !!state.ssedata;
    }
}