import Utils from "@/utils.js";

export default class Certificate{
	constructor( certificate ){
		/* properties list */
		this.id = null;
		this.validto = null;
		this.validfrom = null;
		this.identnr = null;
		this.issued = null;
		this.hasdoc = null;
		this.cert_file = null; // non-API property!!!
		
		/* if given data from API, assign it into field values */
		if( !!certificate ){
			
			if(typeof(certificate.id)        !== "undefined") this.id        = certificate.id;
			if(typeof(certificate.validto)   !== "undefined") this.validto   = Utils.ISOToDateString(certificate.validto);
			if(typeof(certificate.validfrom) !== "undefined") this.validfrom = Utils.ISOToDateString(certificate.validfrom);
			if(typeof(certificate.issued)    !== "undefined") this.issued 	 = Utils.ISOToDateString(certificate.issued);
			if(typeof(certificate.identnr)   !== "undefined") this.identnr   = certificate.identnr;
			if(typeof(certificate.hasdoc)    !== "undefined") this.hasdoc    = certificate.hasdoc;
			if(typeof(certificate.certifier) !== "undefined") this.certifier = certificate.certifier;		
			

		} else {
			// 

			/**/
		}
	}
}
