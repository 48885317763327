import VN_Service from "@/services/VN_Services.js";
import { Config } from "@/services/client.js";
import Employee from "@/store/classes/Employee.js"; 
import store from "@/store/store";
import i18n from '@/i18n'
import Utils from "@/utils.js";

const $t = i18n();

export const namespaced = true;

export const state = {
	employees: [],
};

export const mutations = {
	clearEmployees(state){
		state.employees = [];
    },
    addEmployees(state, employees_data){
        employees_data.forEach(e => {state.employees.push(new Employee(e))})
    },
    addEmployee(state, employee_data) {
        state.employees.unshift(new Employee(employee_data));
    },
    updateEmployee(state, new_employee_data){
        let employee_to_be_replaced = state.employees.find(
            r => r.id === new_employee_data.id      
        );
        Object.assign( employee_to_be_replaced, new Employee(new_employee_data));
    }
};

export const actions = {
    getAllEmployees({commit}, dismantlerId) {
        return VN_Service.indexEmployees(dismantlerId)
        .then(r => {
            if( r.status === 200 ){
                commit("clearEmployees");
                commit("addEmployees", JSON.parse(JSON.stringify(r.data)) );        
                return r.data;        
            }
        })
        .catch(e => {
            
            
        });
    },
    saveNewEmployee(dismantlerId, employee) {
        return VN_Service.newEmployee(employee.dismantlerId,employee.data)
        .then(r => {
            if(r.status === 200) {
                return new Employee(r.data);
            }
        })
        .catch(e => {
            
            
        });
    },
    updateEmployee(dismantlerId, employee) {
        return VN_Service.newEmployee(employee.dismantlerId,employee.data)
        .then(r => {
            if(r.status === 200) {                
                return new Employee(r.data);
            }
        })
        .catch(e => {
            
            
        });
    },
    getEmployeeQualiCert({commit}, id) {
        Utils.downloadFile(
            Config.baseURL + '/btb/staff/qualification/doc/' + id,
            store.state.accountsModule.userData.token
          );/* 
        return VN_Service.getQualificationCert(id).then(r => { // ignore this :D nur zum schauen ^^
            var blob = new Blob([r]);
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "myFileName.pdf";
            link.click();
        }) */
    }
}

export const getters = {
	getEmployeeByID: state => id => {
		return state.employees.find(e => e.id === id);
	},
	
	getEmployees(state){
		return state.employees;
    },
    
};
