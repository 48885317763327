
import Utils from "@/utils.js";
import Company from "@/store/classes/Company.js";
import Certifier from "@/store/classes/Certifier.js";
import Certificate from "@/store/classes/Certificate.js";
import Rights from "@/store/classes/Rights.js";
export default class DepartmentFormComposite {
  constructor(department) {
    /* define base values */
    this.id = null;
    this.expertId = null;
    this.btbnoprefill = false;
    this.dismantlerId = null; 
    this.dismantler = null; 
    this.department = null; 
    this.identNr = null;
    this.editable = null;
    this.vnrdigits = 4;
    this.vnrnext = 1;
    this.vnrprefix = new Date().getFullYear() + '/';    
    this.company = new Company();
    this.certificate = new Certificate();
    this.authority = new Certifier();
    this.theirRights = new Rights();
    this.openinghours = null;
    this.contactname = null;
    this.contactphone = null;
    this.contactmail = null;
    this.hasparkingspace = false;
    this.hascontainerspace = false;
    this.hasforklift = false;
    this.hascrane = false;
    this.agblink = null;
    if (!!department) {
      
      
      if (typeof department.id !== "undefined") this.id = department.id;
      if (typeof department.btbnoprefill !== "undefined") this.btbnoprefill = department.btbnoprefill;
      if (typeof department.expertId !== "undefined")
        this.expertId = department.expertId;
        if (typeof department.dismantler !== "undefined")
        this.dismantler = department.dismantler;
      //if (typeof department.dismantlerId !== "undefined")
      if (department.department === 'DISMANTLER' || department.department === 'COLLECTOR')      
        this.dismantlerId = department.dismantlerId;
      if (typeof department.identNr !== "undefined")
        this.identNr = department.identNr;
      if (department.vnrprefix !== null) { this.vnrprefix = department.vnrprefix } else { this.vnrprefix = new Date().getFullYear() + '/'}                
      if (department.vnrdigits == null) { this.vnrdigits = 4} else { this.vnrdigits = department.vnrdigits }         
      if (department.vnrnext !== null) {this.vnrnext = department.vnrnext } else { this.vnrnext = 1 }
      if (department.hasparkingspace !== null) {this.hasparkingspace = department.hasparkingspace }
      if (department.hascontainerspace !== null) {this.hascontainerspace = department.hascontainerspace }
      if (department.hasforklift !== null) {this.hasforklift = department.hasforklift }
      if (department.hascrane !== null) {this.hascrane = department.hascrane }
      if (department.openinghours !== null) {this.openinghours = department.openinghours }
      if (department.contactname !== null) {this.contactname = department.contactname }
      if (department.contactmail !== null) {this.contactmail = department.contactmail }
      if (department.contactphone !== null) {this.contactphone = department.contactphone }
      if (department.agblink !== null) {this.agblink = department.agblink }
      if (typeof department.vnprintdate !== "undefined") { this.vnprintdate = department.vnprintdate}  
      if (typeof department.vnprintstamp !== "undefined") { this.vnprintstamp = department.vnprintstamp}  
      if (typeof department.vnsornc !== "undefined") { this.vnsornc = department.vnsornc}  
      if (typeof department.vnsornd !== "undefined") { this.vnsornd = department.vnsornd}  
      if (typeof department.editable !== "undefined")
        this.editable = department.editable;
      if (typeof department.company !== "undefined")
        this.company = new Company(department.company);
      if (typeof department.certificate !== "undefined")
        this.certificate = new Certificate(department.certificate);
      if (typeof department.authority !== "undefined")
        this.authority = new Certifier(department.authority);
      
      this.theirRights = new Rights(!department.access ? "FFFF" : department.access);
      
      
    } else {
      
    }
  }
}
