import VN_Service from "@/services/VN_Services.js";
import store from "@/store/store";
import i18n from "@/i18n";

const t = i18n();
export const namespaced = true;

export const state = {
    scraps: [
        /* {id: 'mat-1', name: 'Metal scrap', weight: '40', price: 150, inCart: false},
        {id: 'mat-2', name: 'Copper', kg: 23, price: 23*2.83, inCart: false},    
        {id: 'mat-3', name: 'Gold', g: 5, price: 5*53.28, inCart: false}, */
    ],
    partsKeys: [
        'hood',
        'boot',
        'roof',
        'frontBumper',
        'backBumper',
        'frontRightDoor',
        'frontLeftDoor',
        'backRightDoor',
        'backLeftDoor',
        'frontRightWheel',
        'frontLeftWheel',
        'backRightWheel',
        'backLeftWheel',
        'windshield',
        'backWindow',
        'rightMirror',
        'leftMirror',
        'grill',
        'frontRightLight',
        'frontLeftLight',
        'backRightLight',
        'backLeftLight',
    ],
    car: {
        kba: null,
        data: null,
        parts: {
            //inside                        
            _7079_Federbein_vl: {id: 7079, name: '_7079_Federbein_vl', price: 30, broken: false, inCart:false}, 
            _7080_Federbein_vr: {id: 7080, name: '_7080_Federbein_vr', price: 30, broken: false, inCart:false},             
            _4455_Klimakompressor: {id: 4455, name: '_4455_Klimakompressor', price: 30, broken: false, inCart:false}, 
            _9639_Zündspule: {id: 9639, name: '_9639_Zündspule', price: 30, broken: false, inCart:false}, 
            _8142_Luftmengenmesser: {id: 8142, name: '_8142_Luftmengenmesser', price: 30, broken: false, inCart:false}, 
            _4415_Heizungsgebläse: {id: 4415, name: '_4415_Heizungsgebläse', price: 30, broken: false, inCart:false}, 
            _8833_Auspuff_hinten: {id: 8833, name: '_8833_Auspuff_hinten', price: 30, broken: false, inCart:false}, 
            _8827_Auspuff_mitte: {id: 8827, name: '_8827_Auspuff_mitte', price: 30, broken: false, inCart:false}, 
            _8131_Kraftstoffpumpe: {id: 8131, name: '_8131_Kraftstoffpumpe', price: 30, broken: false, inCart:false}, 
            _9159_Bremssattel_hl: {id: 9159, name: '_9159_Bremssattel_hl', price: 30, broken: false, inCart:false}, 
            _9160_Bremssattel_hr: {id: 9160, name: '_9160_Bremssattel_hr', price: 30, broken: false, inCart:false}, 
            _7148_Bremssattel_vr: {id: 7148, name: '_7148_Bremssattel_vr', price: 30, broken: false, inCart:false}, 
            _7147_Bremssattel_vl: {id: 7147, name: '_7147_Bremssattel_vl', price: 30, broken: false, inCart:false}, 
            _8149_Drosselklappe: {id: 8149, name: '_8149_Drosselklappe', price: 30, broken: false, inCart:false}, 
            _7461_Servopumpe: {id: 7461, name: '_7461_Servopumpe', price: 30, broken: false, inCart:false}, 
            _7241_ABS_Hydro: {id: 7241, name: '_7241_ABS_Hydro', price: 30, broken: false, inCart:false}, 
            _7035_Antriebswelle_l: {id: 7035, name: '_7035_Antriebswelle_l', price: 30, broken: false, inCart:false}, 
            _7036_Antriebswelle_r: {id: 7036, name: '_7036_Antriebswelle_r', price: 30, broken: false, inCart:false}, 
            _8169_Steuergerät: {id: 8169, name: '_8169_Steuergerät', price: 30, broken: false, inCart:false}, 
            _7900_Motor: {id: 7900, name: '_7900_Motor', price: 30, broken: false, inCart:false}, 
            _4057_Tank: {id: 4057, name: '_4057_Tank', price: 30, broken: false, inCart:false}, 
            _8501_Anlasser: {id: 8501, name: '_8501_Anlasser', price: 30, broken: false, inCart:false}, 
            _1450_Wischermotor_v: {id: 1450, name: '_1450_Wischermotor_v', price: 30, broken: false, inCart:false}, 
            _3221_Wischermotor_h: {id: 3221, name: '_3221_Wischermotor_h', price: 30, broken: false, inCart:false}, 
            _8451_Zündverteiler: {id: 8451, name: '_8451_Zündverteiler', price: 30, broken: false, inCart:false}, 
            _8809_Lambda_Sonde: {id: 8809, name: '_8809_Lambda_Sonde', price: 30, broken: false, inCart:false}, 
            _8720_Turbo: {id: 8720, name: '_8720_Turbo', price: 30, broken: false, inCart:false}, 
            _8411_Lichtmaschine: {id: 8411, name: '_8411_Lichtmaschine', price: 30, broken: false, inCart:false}, 
            _7300_Lenkgetriebe: {id: 7300, name: '_7300_Lenkgetriebe', price: 30, broken: false, inCart:false}, 
            _8851_Getriebe: {id: 8851, name: '_8851_Getriebe', price: 30, broken: false, inCart:false}, 
            _8820_Katalysator: {id: 8820, name: '_8820_Katalysator', price: 30, broken: false, inCart:false}, 
            _7400_Lenkrad: {id: 7400, name: '_7400_Lenkrad', price: 30, broken: false, inCart:false}, 
            _7761_Kühler: {id: 7761, name: '_7761_Kühler', price: 30, broken: false, inCart:false}, 
            _9625_Batterie: {id: 9625, name: '_9625_Batterie', price: 30, broken: false, inCart:false}, 
            _31_Keilriemen: {id: 31, name: '_31_Keilriemen', price: 30, broken: false, inCart:false}, 
            _9201_Stossdämpfer_hl: {id: 9201, name: '_9201_Stossdämpfer_hl', price: 30, broken: false, inCart:false}, 
            _9202_Stossdämpfer_hr: {id: 9202, name: '_9202_Stossdämpfer_hr', price: 30, broken: false, inCart:false}, 
            _7370_Lenksäule: {id: 7370, name: '_7370_Lenksäule', price: 30, broken: false, inCart:false}, 
            
            _9187_Bremse_hl: {id: 9187, name: '_9187_Bremse_hl', price: 30, broken: false, inCart:false}, 
            _9188_Bremse_hr: {id: 9188, name: '_9188_Bremse_hr', price: 30, broken: false, inCart:false}, 
            _8810_Schalldämpfer: {id: 8810, name: '_8810_Schalldämpfer', price: 30, broken: false, inCart:false}, 
            _7048_Querlenker_vr: {id: 7048, name: '_7048_Querlenker_vr', price: 30, broken: false, inCart:false}, 
            _7047_Querlenker_vl: {id: 7047, name: '_7047_Querlenker_vl', price: 30, broken: false, inCart:false}, 
            _7142_Bremsscheibe_vr: {id: 7142, name: '_7142_Bremsscheibe_vr', price: 30, broken: false, inCart:false}, 
            _7141_Bremsscheibe_vl: {id: 7141, name: '_7141_Bremsscheibe_vl', price: 30, broken: false, inCart:false}, 
            _8551_Einspritzdüse: {id: 8551, name: '_8551_Einspritzdüse', price: 30, broken: false, inCart:false}, 

            // Carcass
            grill: {id: 410, name: 'Kühlergrill', price: 30, broken: false, inCart: false},
            hood: {id: 471, name: 'Motorhaube', price: 130, broken: false, inCart: false},
            boot: {id: 2931, name: 'Heckklappe', price: 100, broken: false, inCart: false},
            roof: {id: 2353, name: 'Dach', price: 150, broken: false, inCart: false},
            frontBumper: {id: 281, name: 'Stoßfänger vorne', price: 160, broken: false, inCart: false},
            backBumper: {id: 2581, name: 'Stoßfänger hinten', price: 120, broken: false, inCart: false},
  
            // Doors
            frontRightDoor: {id: 1482, name: 'Tür vorne rechts', price: 70, broken: false, inCart: false},
            frontLeftDoor: {id: 1481, name: 'Tür vorne links', price: 70, broken: false, inCart: false},
            backRightDoor: {id: 1782, name: 'Tür hinten rechts', price: 50, broken: false, inCart: false},
            backLeftDoor: {id: 1781, name: 'Tür hinten links', price: 50, broken: false, inCart: false},
  
            // Wheels
            frontRightWheel: {id: 7227, name: 'Reifen vorne rechts', price: 60, broken: false, inCart: false},
            frontLeftWheel: {id: 7227, name: 'Reifen vorne links', price: 60, broken: false, inCart: false},
            backRightWheel: {id: 7227, name: 'Reifen hinten rechts', price: 60, broken: false, inCart: false},
            backLeftWheel: {id: 7227, name: 'Reifen hinten links', price: 60, broken: false, inCart: false},
  
            // Glass
            windshield: {id: 1400, name: 'Frontscheibe', price: 50, broken: false, inCart: false},
            backWindow: {id: 3150, name: 'Heckscheibe', price: 40, broken: false, inCart: false},
            rightMirror: {id: 1738, name: 'Aussenspiegel Rechts', price: 60, broken: false, inCart: false},
            leftMirror: {id: 1737, name: 'Aussenspiegel Links', price: 60, broken: false, inCart: false},
  
            // Lights
            frontRightLight: {id: 562, name: 'Scheinwerfer rechts', price: 75, broken: false, inCart: false},
            frontLeftLight: {id: 561, name: 'Scheinwerfer links', price: 75, broken: false, inCart: false},
            backRightLight: {id: 3282, name: 'Rückleuchte rechts', price: 70, broken: false, inCart: false},
            backLeftLight: {id: 3281, name: 'Rückleuchte links', price: 70, broken: false, inCart: false},
          }
    },
    topseller: null,
    topsellerkba: null,
    topsellercount: null,
    topsellerkbacount: null,
}

export const mutations = {
    setInitial(state) {
        Object.assign(state, JSON.parse(JSON.stringify(initial)))
    }, 
    clearTopsellerKba(state) {
        state.topsellerkba = null
    },
    clearTopsellerKbaCount(state) {
        state.topsellerkbacount = null
    },
    clearTopsellerCount(state) {
        state.topseller = null
    },
    clearTopseller(state) {
        state.topsellercount = null
    },
    setTopsellerKba(state, data) {
        
    },
    setTopsellerKbaCount(state, data) {
        
    },
    setTopsellerCount(state, data) {
        
    },
    setTopseller(state, data) {
        
    },
    clearMaterials(state) {
        state.scraps = []
    },
    setMaterials(state, data) {
        data.forEach(element => {
            state.scraps.push({
                id: element.id, 
                name: element.nameDe,
                price: 2,
                percentage: element.percentage,
                measure: element.measure,
                priceKg: element.price,
                inCart: false
            })
        });
    },
}

export const actions = {
    getCarByKba({commit}, kba) {
        return VN_Service.getCarByKba(kba).then(car => {
            return car.data
        })
    },
    getTopsellerbyKBA({commit}, kba) {
        return VN_Service.getTopsellerbyKBA(kba).then(topseller => {
            topseller.data.forEach(element => {
                element.inCart = false
                element.broken = false
            });
            return topseller.data
        })
    },
    getTopsellerbyKBACount({commit}, kba) {
        return VN_Service.getTopsellerbyKBACount(kba).then(topseller => {
            topseller.data.forEach(element => {
                element.inCart = false
                element.broken = false
            });
            return topseller.data
        })
    },
    getTopseller({commit}, kba) {
        return VN_Service.getTopseller().then(topseller => {
            topseller.data.forEach(element => {
                element.inCart = false
                element.broken = false
            });
            return topseller.data
        })
    },
    getTopsellerbyCount({commit}, kba) {
        return VN_Service.getTopsellerbyCount().then(topseller => {
            topseller.data.forEach(element => {
                element.inCart = false
                element.broken = false
            });
            return topseller.data
        })
    },
    getPartsByIds({commit}, data) {
        return VN_Service.getPartsByIds(data).then(parts => {
            return parts.data
        })
    },
    getMaterials({commit}, data) {
        return VN_Service.getMaterials().then(materials => {
            if(materials.status === 200) {
                commit("clearMaterials")
                commit("setMaterials", materials.data)
            }
        })
    }
}

export const getters = {
    
}


const initial = JSON.parse(JSON.stringify(state));
