import VN_Service from "@/services/VN_Services.js";
import store from "@/store/store";
import i18n from "@/i18n";
import Incident from "@/store/classes/Incident"; 

const $t = i18n();
export const namespaced = true;

export const state = {
    incidents: []
}

export const mutations = {
    clearIncidents(state) {
        state.incidents = [];
    },
    addIncidents(state,data) {
        data.forEach(incident => {state.incidents.push(new Incident(incident))})
    },
    clearIncident(state) {
        state.disposal = new Incident();
    },
    addIncident(state,data) {
        state.incidents.push(new Incident(data))
    },
    updateIncident(state, data){        
        let newincident = state.incidents.find(
            r => r.id === data.id      
        );
        Object.assign( newincident, data);        
    },
}

export const actions = {
    getIncidents({commit},dismantlerId) {
        return VN_Service.getIncidentsOf(dismantlerId).then(r => {
            if(r.status === 200 ) {
                commit("clearIncidents")
                commit("addIncidents", r.data)  
                return r.data
            }
        })
    },
    saveUpdateIncident({commit}, {dismantlerId, incident}) {
        
        
        return VN_Service.saveUpdateIncident(dismantlerId,incident).then(r => {
            if(r.status === 200 ) {
                if(incident.id === null) {
                    commit("addIncident", r.data)
                    store.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.incident.created")
                    );
                } else {
                    commit("updateIncident",r.data)
                    store.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.incident.edited")
                    );
                }
                return new Incident(r.data)
            }
        })
    }
}