import Utils from "@/utils.js";
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : process.env.VUE_APP_I18N_LOCALE 
export default class Btboutput {
    constructor(btboutput) {
        this.id = null;
        this.wastetype = null;
        this.amount = null;
        this.measure = null;
        this.actiondate = null;
        this.name = null;
        this.remark = null;
        this.locked = false;
        this.staffId = null;
        this.uiInfo = null;
        this.isSelectable = true;
        this.carId = null;
        this.dismantlerId = null

        if(!!btboutput) {
            
            if(typeof btboutput.id !== 'undefined') this.id = btboutput.id 
            if(typeof btboutput.amount !== 'undefined') this.amount = btboutput.amount.toLocaleString(lang) 
            if(typeof btboutput.staffId !== 'undefined') this.staffId = btboutput.staffId 
            if(typeof btboutput.wastetype !== 'undefined') this.wastetype = btboutput.wastetype 
            if(typeof btboutput.uiInfo !== 'undefined') this.uiInfo = btboutput.uiInfo 
            if(typeof btboutput.name !== 'undefined') this.name = btboutput.name 
            if(typeof btboutput.measure !== 'undefined') this.measure = btboutput.measure 
            if(typeof btboutput.dismantlerId !== 'undefined') this.dismantlerId = btboutput.dismantlerId 
            if(typeof btboutput.carId !== 'undefined') this.carId = btboutput.carId             
            if(typeof btboutput.remark !== 'undefined') this.remark = btboutput.remark 
            if(typeof btboutput.locked !== 'undefined') this.locked = btboutput.locked
            if(typeof btboutput.isSelectable !== 'undefined') this.isSelectable = btboutput.isSelectable
            if(typeof btboutput.actiondate !== 'undefined') this.actiondate = Utils.ISOToDateString(btboutput.actiondate );

        }
    }
} 