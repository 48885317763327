import { client } from "@/services/client.js";
import VN_Service from "@/services/VN_Services.js";
import DepartmentFormComposite from "@/store/classes/composite/DepartmentFormComposite.js";
import CompanyLite from "@/store/classes/composite/CompanyLite.js";

import i18n from '@/i18n'

const $t = i18n();

export const namespaced = true;

export const state = {
    company: new DepartmentFormComposite(),
    department: [],
    dismantlersFetched: false,
	dismantlersExist : false,
    dismantlers: [],
    disCmantlers: [],
    collectors: [],
    externalizableCollectors: [],
    assignableCollectors: [],
    loadedExterizableCollectors : false,
    collectorsFetched : false,
    assignableCollectorsFetched : false,
    recyclers: [],
    loopers: [],
    remanufacturers: [],
}
export const mutations = {
    clearCompany(state) {        
		state.company = new DepartmentFormComposite();				
    },
    addCompany(state, company) { 
		state.company = new DepartmentFormComposite(company);
    },
    updateCompany(state, company) {        // add type of
        
        state.company = new DepartmentFormComposite(company);        
    },
    clearDismantlers(state) {
        state.dismantlers = []
    },
    clearDisCmantlers(state){
        state.disCmantlers = [];	
    },
    addDisCmantlers(state, arr_of_dismantler_data){
        arr_of_dismantler_data.forEach(d => {
            state.disCmantlers.push(d);
        });
        localStorage.setItem( "disCmantler_count", state.disCmantlers.length );        
    },
    addDismantlers(state, dismantlers){
        dismantlers.forEach(dismantler => {
            state.dismantlers.push(dismantler);
        });      
        localStorage.setItem( "dismantler_count", state.dismantlers.length );        
    },
    addDismantler(state, dismantler){
        state.dismantlers.push(new DepartmentFormComposite(dismantler));
        localStorage.setItem( "dismantler_count", state.dismantlers.length );
        /* REFACTOR: sort after pushing into dismantlers */
    },
    updateDismantler(state, dismantler) {           
        let dismantlerNew = state.dismantlers.find(
            r => r.id === dismantler.id      
          );       
        Object.assign(dismantlerNew, new DepartmentFormComposite(dismantler))
        localStorage.setItem("collector_count", state.dismantlers.length)
    },
    setDismantlerExist(state, exist){
        state.dismantlersExist = exist;
    },
    clearLoopers(state) {
        state.loopers = []
    },
    addLoopers(state, loopers){
        loopers.forEach(looper => {
            state.loopers.push(looper);
        });           
    },
    addLooper(state, looper){
        state.loopers.push(new DepartmentFormComposite(looper));        
    },
    updateLooper(state, looper) {        
        let looperNew = state.loopers.find(
            r => r.id === looper.id      
          );       
        Object.assign(looperNew, new DepartmentFormComposite(looper))
    },
    addReman(state, reman){
        state.remans.push(new DepartmentFormComposite(reman));        
    },
    updateReman(state, reman) {        
        let remanNew = state.remanufacturers.find(
            r => r.id === reman.id      
          );       
        Object.assign(remanNew, new DepartmentFormComposite(reman))
    },
    addRemanufacturers(state, data){
        data.forEach(d => {
            state.remanufacturers.push(d);
        });
        
    },
    clearRemanufacturers(state){
        state.remanufacturers = [];
    },
    clearRecyclers(state) {
        state.recyclers = []
    },
    addRecyclers(state, recyclers){
        recyclers.forEach(recycler => {
            state.recyclers.push(recycler);
        });           
    },
    addRecycler(state, recycler){
        state.recyclers.push(new DepartmentFormComposite(recycler));        
    },
    updateRecycler(state, recycler) {        
        let recyclerNew = state.recyclers.find(
            r => r.id === recycler.id      
          );       
        Object.assign(recyclerNew, new DepartmentFormComposite(recycler))
    },
    clearCollectors(state) {
        state.collectors = []
    },
    addCollectors(state, collectors){        
        collectors.forEach(collector => {
            state.collectors.push(new CompanyLite(collector));
        });        
        localStorage.setItem( "collector_count", state.collectors.length );        
    },
    addCollector(state, collector) {                
        state.collectors.push(new CompanyLite(collector.dtr));        
        localStorage.setItem("collector_count", state.collectors.length);        
    },
    updateCollector(state, collector) {     
        let collectorNew = state.collectors.find(
            r => r.id === collector.id      
          );       
        Object.assign(collectorNew, new CompanyLite(collector.dtr))
        localStorage.setItem("collector_count", state.collectors.length);        
    },
    setExternalizable(state, externalizable_data) {
        externalizable_data.forEach(d => {
          state.externalizableCollectors.push(new CompanyLite(d));
        });
    },
    clearAssignable(state) {
        state.assignableCollectors = []
    },
    setAssignable(state, assignable_data) {
        assignable_data.forEach(d => {
            state.assignableCollectors.push(new CompanyLite(d));
        });
    },
}
export const actions = {    
    getAllDismantlers({ commit , state}){
        //if(!state.dismantlersFetched){
            state.dismantlersFetched = true;
            return VN_Service.indexDismantlers()
            .then(r => {
                if( r.status === 200 ){  
                    //commit('clearCompany')
                    commit("clearDismantlers");
                    commit("addDismantlers", r.data);
                }
            })
        //}        
    },
    getAllDisCmantlers({ commit }){
        return VN_Service.getDismantlersForCurrentCollector()
        .then(r => {
            if( r.status === 200 ){
                commit("clearDisCmantlers");
                commit("addDisCmantlers",
                    JSON.parse(JSON.stringify(r.data))						
                );
                return r.data;
            }
        });
    },
    async getDismantler({ commit }, dismantlerId){
        return await VN_Service.getDismantler(dismantlerId)
        .then(r => {
            if( r.status === 200 ){
                commit('clearCompany')
                commit('addCompany', r.data)
                return r.data;
            }
        })
        ;
    },
    async saveUpdateDismantler({commit, dispatch}, department) {
        if(department.id === null) {
            return VN_Service.createDismantler(department)
			.then(r => {
				if ((r.status === 200) || (r.status===201)) {    
                    
                    commit('addDismantler', r.data)          
                    commit('addCompany', r.data)
                    this.commit("accountsModule/updateAccountMandator", r.data.mandator)
                    this.dispatch("accountsModule/getmyAccount")
                    this.dispatch(
						"errorhandling/addMessage",
						$t.t("$gt.msg.system.success.dismantler.created")
					);    
                    if(department.certificate.cert_file !== null) {
                        dispatch("uploadCertificate",{department: department, r: r.data}) 
                    }               
                }
            })
        } else {
            return VN_Service.updateDismantler(department)
			.then(r => {
				if ((r.status === 200) || (r.status===201)) {  
                    
                    if(department.certificate.cert_file !== null) {
                        dispatch("uploadCertificate", {department: department, r: r.data})     
                    } else {
                        
                    commit('updateDismantler', r.data)               
                    commit('updateCompany', r.data)
                    }
                    this.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.dismantler.edited")
                    );                    
                }
            })
        }     
    },
    getDismantlerExist({commit}){
        return VN_Service.dismantlersExist().then(r => {
            if( r.status === 200 ){
                commit("setDismantlerExist", r.data);
            };
        });                    
    },
    updateDismantlerVnr({commit}, vnr){
        return VN_Service.updateDismantlerVnr(vnr)
        .then(r => {
            if( r.status === 200 ){
                return r.data;
            }
        })
    },
    uploadCertificate({commit} ,{department, r}) {
        let temp_form_data = new FormData();
        temp_form_data.append("file", department.certificate.cert_file);
        VN_Service.uploadCertificate(
            r.certificate.id,
            temp_form_data
        ) 
        .then(response => {
            
            Object.assign(r.certificate, response.data)
            
            commit('updateDismantler', r)               
            commit('updateCompany', r)
        })
        .catch(error => {
            console.log(
                $t.t("$gt.msg.error.certificate.upload"),
                error
            );
        });
    },
    async getAllRecyclers({ commit , state}){
        //if(!state.dismantlersFetched){
            return await VN_Service.getAllRecyclers()
            .then(r => {
                if( r.status === 200 ){  
                    commit('clearCompany')
                    commit("clearRecyclers");
                    commit("addRecyclers", r.data);
                    return r.data
                }
            })
        //}        
    },
    async getRecycler({ commit }, recyclerId){
        return await VN_Service.getRecycler(recyclerId)
        .then(r => {
            if( r.status === 200 ){
                commit('clearCompany')
                commit('addCompany', r.data)
                return new DepartmentFormComposite(r.data);
            }
        })
        ;
    },
    saveUpdateRecycler({commit}, department) {
        if(department.id === null) {
        return VN_Service.createRecycler(department)
			.then(r => {
				if ((r.status === 200) || (r.status===201)) {
                    commit('addCompany', r.data)                
                    commit('addRecycler', r.data)                    
                    this.commit("accountsModule/updateAccountMandator", r.data.mandator)
                    this.dispatch("accountsModule/getmyAccount")
                    this.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.recycler.created")
                    ); 
                }
            })
        } else {
            return VN_Service.updateRecycler(department)
			.then(r => {
				if ((r.status === 200) || (r.status===201)) {                    
                    commit('updateCompany', r.data)              
                    commit('updateRecycler', r.data)  
                    this.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.recycler.edited")
                    );                                           
                }
            })
        }
    },
    async getAllLoopers({ commit , state}){
        //if(!state.dismantlersFetched){
            return await VN_Service.getAllLoopers()
            .then(r => {
                if( r.status === 200 ){  
                    commit('clearCompany')
                    commit("clearLoopers");
                    commit("addLoopers", r.data);
                    return r.data
                }
            })
        //}        
    },
    async getLooper({ commit }, LooperId){
        return await VN_Service.getLooper(LooperId)
        .then(r => {
            if( r.status === 200 ){
                commit('clearCompany')
                commit('addCompany', r.data)
                return r.data;
            }
        })
        ;
    },
    saveUpdateLooper({commit}, department) {
        if(department.id === null) {
        return VN_Service.createLooper(department)
			.then(r => {
				if ((r.status === 200) || (r.status===201)) {
                    commit('addCompany', r.data)
                    commit('addLooper', r.data)            
                    this.commit("accountsModule/updateAccountMandator", r.data.mandator)
                    this.dispatch("accountsModule/getmyAccount")
                    this.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.looper.created")
                    );   
                }
            })
        } else {
            return VN_Service.updateLooper(department)
			.then(r => {
				if ((r.status === 200) || (r.status===201)) {
                    commit('updateCompany', r.data)              
                    commit('updateLooper', r.data)  
                    this.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.looper.edited")
                    );                   
                }
            })
        }
    },
    saveUpdateReman({commit}, department) {
        if(department.id === null) {
        return VN_Service.createReman(department)
			.then(r => {
				if ((r.status === 200) || (r.status===201)) {
                    commit('addCompany', r.data)
                    commit('addReman', r.data)            
                    this.commit("accountsModule/updateAccountMandator", r.data.mandator)
                    this.dispatch("accountsModule/getmyAccount")
                    this.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.reman.created")
                    );   
                }
            })
        } else {
            return VN_Service.updateReman(department)
			.then(r => {
				if ((r.status === 200) || (r.status===201)) {
                    commit('updateCompany', r.data)              
                    commit('updateReman', r.data)  
                    this.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.reman.edited")
                    );                   
                }
            })
        }
    },
    async getReman({ commit }, RemanId){
        return await VN_Service.getReman(RemanId)
        .then(r => {
            if( r.status === 200 ){
                commit('clearCompany')
                commit('addCompany', r.data)
                return r.data;
            }
        })
        ;
    },
    async getRemanufacturers({commit}) {
        return await client.base.get('/reman').then((r) => {
            if( r.status === 200 ){
                commit("clearRemanufacturers");
                commit("addRemanufacturers", r.data);
            }
        })
    },
    getAllCollectors({ commit, state }, force) {      
        //if(!state.collectorsFetched || force){     
          //state.collectorsFetched = true; 
          return VN_Service.indexCollectors()
          .then(r => {          
            if (r.status === 200) {      
                commit('clearCompany')
                commit("clearCollectors");        
                commit("addCollectors", r.data);
                return r.data
            }
          })
        //}             
    },
    getCollector({ commit }, collector_id) {
        return VN_Service.getCollector(collector_id)
        .then(r => {
            if (r.status === 200) {
                commit('clearCompany')
                commit('addCompany', r.data)              
            }
        })
    },
    saveUpdateCollector({commit,dispatch}, department) {
        if(department.id === null) {
            return VN_Service.createCollector(department)
			.then(r => {
				if ((r.status === 200) || (r.status===201)) {              
                    commit('addCompany', r.data)                    
                    commit('addCollector', r.data)       
                    this.commit("accountsModule/updateAccountMandator", r.data.mandator)
                }
            })
        } else {
            return VN_Service.updateCollector(department)
			.then(r => {
				if ((r.status === 200) || (r.status===201)) {                    
                    commit('updateCompany', r.data)                       
                    commit('updateCollector', r.data)       
                }
            })
        }        
    },
    outSourceCollector({ commit, dispatch }, { collector_id, account_id }) {
        return VN_Service.outSourceCollector(collector_id, account_id)
        .then(r => {
            if (r.status === 200) {
              //Krücke erstmal reload der page, mit neuem datamanagement fliegt das raus
              window.location.reload();
            }
        })
    },
    getExternizableCollectors({ commit }) {
        if(!state.loadedExterizableCollectors){
          state.loadedExterizableCollectors = true;
          VN_Service.getExternizableCollectors()
          .then(r => {
            if (r.status == 200) {
              commit("setExternalizable", r.data);
            }
          })
        }
      },
      
      getAssignableCollectors({ commit }) {
        //if(!state.assignableCollectorsFetched){
          //state.assignableCollectorsFetched = true;
        return VN_Service.getAssignableCollectors()
        .then(r => {
          if (r.status == 200) {
            commit("clearAssignable");
            commit("setAssignable", r.data);
            return r.data
          }
        })
        //}        
    }
}

export const getters = {
    getCollectorByID: state => id => {
      return state.collectors.find(c => c.id === id);
    },
    has_collectors(state) {    
      return state.collectors.length > 0;
    },
    
    has_assignable_collectors(state) {
      return state.assignableCollectors.length > 0;
    },
    hasCollectorsFetched(state){
      return state.collectorsFetched;
    },
    hasAssignableCollectorsFetched(state){
      return state.assignableCollectorsFetched;
    },
    getDismantlers(state){
		return state.dismantlers;
	},
	getDismantlerByID: state => id => {
		return state.dismantlers.find(d => d.id === id);
	},
	has_dismantlers(state) {
		/* check locally stored dismantler count first */
		let stored_dismantler_count = localStorage.getItem( "dismantler_count" );
		if( stored_dismantler_count === null || stored_dismantler_count == 0 ){
			stored_dismantler_count = state.dismantlers.length;
			localStorage.setItem( "dismantler_count", stored_dismantler_count);
		}
		return stored_dismantler_count > 0;
	},
  };