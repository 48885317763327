import VN_Service from "@/services/VN_Services.js";
import store from "@/store/store";
import i18n from "@/i18n";
import Cargo from "@/store/classes/Cargo.js";
const $t = i18n();
export const namespaced = true;

export const state = {
    cargos: [],
    cargotypes: [],
    cargo: new Cargo(),
    offersforlooper: [],
    offer: null,
    ordersoflooper: []
}

export const mutations = {
    clearCargos(state) {
        state.cargos = []
    },
    addCargos(state, cargos) {        
        state.cargos = cargos
        //cargos.forEach(cargo => {state.cargos.push(cargo)})
    },
    clearOffersForLooper(state) {
        state.offersforlooper = []
    },
    addOffersForLooper(state, offers) {
        state.offersforlooper = offers        
        //cargos.forEach(cargo => {state.cargos.push(cargo)})
    },
    clearOrdersOfLooper(state) {
        state.ordersoflooper = []
    },
    addOrdersOfLooper(state, orders) {
        state.ordersoflooper = orders        
        //cargos.forEach(cargo => {state.cargos.push(cargo)})
    },
    clearCargoTypes(state) {
        state.cargotypes = []
    },
    addCargoTypes(state, cargotypes) {        
        cargotypes.forEach(cargotype => {state.cargotypes.push(cargotype)})
    },
    clearCargo(state) {
        state.cargo = []
    },
    addCargo(state, cargo) {
        state.cargos.push(cargo.tr)
    },
    updateCargo(state, cargo) {
        let newcargo = state.cargos.find(
            r => r.id === cargo.id
        )
        Object.assign(newcargo,cargo.tr)
    },
    updateOrderOfLooper(state, order) {        
        let neworder = state.ordersoflooper.find(
            r => r.id === order.id
        )
        Object.assign(neworder,order.tr)
    },
    deleteOrderOfLooper(state, orderId) {
        let deleted = state.ordersoflooper.find(
            r => r.id === orderId      
        );
        const index = state.ordersoflooper.indexOf(deleted);
        state.ordersoflooper.splice(index,1)  
    },
    deleteCargoOfLooper(state,cargo) {        
        let deleted = state.cargos.find(
            r => r.id === cargo.id      
        );
        const index = state.cargos.indexOf(deleted);
        state.cargos.splice(index,1)        
    }    
}

export const actions = {
    getPickupOrdersOfLooper({commit,dispatch}, looperId) {
        return VN_Service.getPickupOrdersOfLooper(looperId).then(r=> {
            if (r.status === 200) {
                commit("clearOrdersOfLooper")
                commit("addOrdersOfLooper", r.data)                                
            }
        })
    },
    getPickupOrderById({commit,dispatch}, orderId) {
        return VN_Service.getPickupOrderById(orderId).then(r=> {
            if (r.status === 200) {
                /* commit("clearOrdersOfLooper")
                commit("addOrdersOfLooper", r.data)      */                           
                return r.data
            }
        })
    },
    deletePickupOrderById({commit,dispatch}, orderId) {
        return VN_Service.deletePickupOrderById(orderId).then(r=> {
            if (r.status === 200) {                
                store.dispatch(
                    "errorhandling/addMessage",
                    $t.t("$gt.msg.system.success.exchange.pickup.deleted")
                ); 
                commit("deleteOrderOfLooper", orderId)                
            }
        })
    },
    executePickupOrderById({commit,dispatch}, orderId) {
        return VN_Service.executePickupOrderById(orderId).then(r=> {
            if (r.status === 200) {
                //commit("clearOrdersOfLooper")
                commit("updateOrderOfLooper", r.data)
                store.dispatch(
                    "errorhandling/addMessage",
                    $t.t("$gt.msg.system.success.exchange.pickup.pickupexecute")
                ); 
                return r.data
            }
        })
    },
    createPickupOrder({commit,dispatch}, {cargoId,offerId}) {        
        return VN_Service.createPickupOrder({cargoId,offerId}).then(r=> {
            if (r.status === 200) {
                store.dispatch(
                    "errorhandling/addMessage",
                    $t.t("$gt.msg.system.success.exchange.pickup.created")
                );                           
                return r.data
            }
        })
    },
    createPickupOrderRequest({commit,dispatch}, request) {        
        return VN_Service.createPickupOrderRequest(request).then(r=> {
            if (r.status === 200) {
                store.dispatch(
                    "errorhandling/addMessage",
                    $t.t("$gt.msg.system.success.exchange.pickuprequest.created")
                );                           
                return r.data
            }
        })
    },
    updatePickupOrder({commit,dispatch}, order) {        
        return VN_Service.updatePickupOrder(order).then(r=> {
            if (r.status === 200) {
                store.dispatch(
                    "errorhandling/addMessage",
                    $t.t("$gt.msg.system.success.exchange.pickup.edited")
                );
                commit("updateOrderOfLooper", r.data)                        
                return r.data
            }
        })
    },
    getAllCargos({commit,dispatch}, looperId) {
        return VN_Service.getAllCargos(looperId).then(r=> {
            if (r.status === 200) {               
                commit("clearCargos")
                commit("addCargos", r.data)                                
            }
        })
    },
    getCargoTypes({commit}) {
        return VN_Service.getCargoTypes().then(r=> {
            if (r.status === 200) {
                commit("clearCargoTypes")
                commit("addCargoTypes", r.data)
            }
        })
    },
    async getCargoById({commit}, cargoId) {
        return await VN_Service.getCargoById(cargoId).then(r=> {
            if(r.status === 200) {
                return new Cargo(r.data)
            }
        })
    },
    deleteCargoById({commit,dispatch}, cargo) {
        return VN_Service.deleteCargoById(cargo.id).then(r=> {
            if (r.status === 200) {                
                store.dispatch(
                    "errorhandling/addMessage",
                    $t.t("$gt.msg.system.success.exchange.cargo.deleted")
                ); 
                commit("deleteCargoOfLooper", cargo)                
            }
        })
    },
    saveUpdateCargo({commit}, cargo) {
        if(cargo.id === null) {
            return VN_Service.createCargo(cargo).then(r=> {            
                if(r.status === 200) {
                    commit("clearOffersForLooper")           
                    commit("clearCargo")              
                    commit("addCargo", r.data)
                    store.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.exchange.cargo.created")
                    );  
                }
            })
        } else {
            return VN_Service.updateCargo(cargo).then(r=> {            
                if(r.status === 200) {
                    commit("clearOffersForLooper")           
                    commit("updateCargo", r.data)
                    store.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.exchange.cargo.edited")
                    );  
                }
            })
        }
    },
    async getOffersForCargoById({commit}, cargo) {
        return await VN_Service.getOffersForCargoById(cargo.id).then(r=> {
            if(r.status === 200) {                
                r.data.cargoamount = cargo.amount
                commit("clearOffersForLooper")
                commit("addOffersForLooper", r.data)
                return r.data
            }
        })
    }
}

export const getters = {

}