import Utils from "@/utils.js";
import { Config } from "@/services/client.js";
import VN_Service from "@/services/VN_Services.js";
import store from "@/store/store";
import i18n from '@/i18n'

const $t = i18n();

import CompanyLite from "@/store/classes/composite/CompanyLite.js";
import DepartmentFormComposite from "@/store/classes/composite/DepartmentFormComposite.js";



export const namespaced = true;
export const state = {
  /* Liste aller sichtbaren Annahmestellen */
  collectors: [],
  externalizableCollectors: [],
  assignableCollectors: [],
  loadedExterizableCollectors : false,
  collectorsFetched : false,
  assignableCollectorsFetched : false
};
export const mutations = {
  /* + */
  /* pushes a single collector into state */
  addCollector(state, collector_data) {
    
    state.collectors.push(new CompanyLite({id: collector_data.id}));
    
    localStorage.setItem("collector_count", state.collectors.length);
    /* REFACTOR: sort after pushing into collectors */
  },
  /* pushes multiple collectors into state */
  addCollectors(state, arr_of_collector_data) {
    arr_of_collector_data.forEach(d => {
      state.collectors.push(new CompanyLite(d));
    });
    localStorage.setItem("collector_count", state.collectors.length);
    /* REFACTOR: sort after pushing into collectors */
  },

  setExternalizable(state, externalizable_data) {
    externalizable_data.forEach(d => {
      state.externalizableCollectors.push(new CompanyLite(d));
    });
  },
  clearAssignable(state) {
    state.assignableCollectors = []
  },
  setAssignable(state, assignable_data) {
    assignable_data.forEach(d => {
      state.assignableCollectors.push(new CompanyLite(d));
    });
  },

  /* - */
  /* removes a collector from state by its id */
  removeCollector(state, collector) {
    let index = state.collectors.indexOf(collector);
    state.collectors.splice(index, 1);
    localStorage.setItem("collector_count", state.collectors.length);
  },
  /* wipes the currently tracked collectors (for reset) */
  clearCollectors(state) {
    state.collectors = [];
    state.collectorsFetched = false
  },

  /* / */
  /* replaces a collector in state by its id */
  replaceCollector(state, new_collector_data) {
    
    

    /* get collector reference */
    let collector_to_be_replaced = state.collectors.find(
      r => r.id === new_collector_data.id      
    );
    if (!!collector_to_be_replaced) {
      /* update collector reference with new data */
      collector_to_be_replaced.company = new_collector_data.company.name;
      collector_to_be_replaced.street = new_collector_data.company.street + " " +  new_collector_data.company.hsnr;
      collector_to_be_replaced.city = new_collector_data.company.city;
      collector_to_be_replaced.tel = new_collector_data.company.tel;
      collector_to_be_replaced.certill = new_collector_data.certificate.validto;
      collector_to_be_replaced.dismantler = new_collector_data.dismantler;
      collector_to_be_replaced.vnprintdate = new_collector_data.vnprintdate;
      collector_to_be_replaced.vnprintstamp = new_collector_data.vnprintstamp;
      collector_to_be_replaced.vnsornc = new_collector_data.vnsornc;
      collector_to_be_replaced.vnsornd = new_collector_data.vnsornd;
      /* REFACTOR: sort after replacing collector */
    } else {
      /* TODO: error handling */
      return null;
    }
  }
  /*??? replaceCollectors(){}, ???*/
  /* REFACTOR: sort after replacing collectors */
};
export const actions = {
  /* ICRUD */
  /* Index collectors */
  getAllCollectors({ commit, state }, force) {      
      if(!state.collectorsFetched || force){     
        state.collectorsFetched = true; 
        return VN_Service.indexCollectors()
        .then(r => {          
          if (r.status === 200) {      
            commit("clearCollectors");        
            commit("addCollectors", JSON.parse(JSON.stringify(r.data)));
            return r.data
          }
        })
      }
      
   
  },

  /* Create collector */
  newCollector({ commit }, collector) {

    /* !!! dependencies are created implicitly !!! */
    return VN_Service.createCollector(collector)
      .then(r => {
        if (r.status === 200 || r.status === 201) {
          
          commit("addCollector", r.data);
          store.dispatch(
            "errorhandling/addMessage",
            $t.t("$gt.msg.system.success.collector.created")
          );
            
          commit("replaceCollector", new DepartmentFormComposite(r.data));          
          return r.data; // need for detailpages
        }
      })
  },

  /* Read collector */
  /**get a single collector by its id **/
  getCollector({ commit }, collector_id) {
    return VN_Service.getCollector(collector_id)
      .then(r => {
        if (r.status === 200) {
          return new DepartmentFormComposite(r.data);
        }
      })
  },

  outSourceCollector({ commit, dispatch }, { collector_id, account_id }) {
    return VN_Service.outSourceCollector(collector_id, account_id)
      .then(r => {
        if (r.status === 200) {
          //Krücke erstmal reload der page, mit neuem datamanagement fliegt das raus
          window.location.reload();
        }
      })
  },

  /* Update (destructive edit) collector */
  updateCollector({ commit }, collector) {

    VN_Service.updateCollector(collector)
      .then(r => {
        if (r.status === 200) {
          
          commit("replaceCollector", new DepartmentFormComposite(r.data));
          store.dispatch(
            "errorhandling/addMessage",
            $t.t("$gt.msg.system.success.collector.edited")
          );
        }
      })
    /* /update collector */
  },

  getExternizableCollectors({ commit }) {
    if(!state.loadedExterizableCollectors){
      state.loadedExterizableCollectors = true;
      VN_Service.getExternizableCollectors()
      .then(r => {
        if (r.status == 200) {
          commit("setExternalizable", r.data);
        }
      })
    }
  },
  
  getAssignableCollectors({ commit }) {
    //if(!state.assignableCollectorsFetched){
      //state.assignableCollectorsFetched = true;
      return VN_Service.getAssignableCollectors()
    .then(r => {
      if (r.status == 200) {
        commit("clearAssignable");
        commit("setAssignable", r.data);
        return r.data
      }
    })
    //}
    
  }

  /* Delete collector */
  /** does not exist yet **/
  /** deleteCollector({ commit }, collector) {}, **/
  /* /ICRUD */
};
export const getters = {
  getCollectorByID: state => id => {
    return state.collectors.find(c => c.id === id);
  },
  has_collectors(state) {    
    return state.collectors.length > 0;
  },
  
  has_assignable_collectors(state) {
    return state.assignableCollectors.length > 0;
  },
  hasCollectorsFetched(state){
    return state.collectorsFetched;
  },
  hasAssignableCollectorsFetched(state){
    return state.assignableCollectorsFetched;
  }
};

export const helper = {};
