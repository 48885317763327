import VN_Service from "@/services/VN_Services.js";
import Carrier from "@/store/classes/Carrier.js";
import store from "@/store/store";
import i18n from '@/i18n'

const $t = i18n();

export const namespaced = true;

export const state = {
	carriers: [],
};

export const mutations = {
	clearCarriers(state){
		state.carriers = [];
    },
    addCarriers(state, carriers_data){
      state.carriers.push({id: -1000, name: $t.t("$gt.label.equaldisposer")})
        carriers_data.forEach(c => {state.carriers.push(new Carrier(c))})
    },
    addCarrier(state, carrier_data) {
        state.carriers.unshift(new Carrier(carrier_data));
    },
    updateCarrier(state, new_carrier_data){
        let carrier_to_be_replaced = state.carriers.find(
            r => r.id === new_carrier_data.id      
        );
        Object.assign( carrier_to_be_replaced, new Carrier(new_carrier_data));
    }
};

export const actions = {
	getAllCarriers({ commit}) {
        return VN_Service.indexCarriers()
        .then(r => {
            if( r.status === 200 ){
                commit("clearCarriers");
                commit("addCarriers", JSON.parse(JSON.stringify(r.data)) );                
            }
        })
        .catch(e => {
            
            
        });
    },
  /**get a single carrier by its id **/
  getCarrier({ commit }, carrierId) {
    return VN_Service.getCarrier(carrierId)
      .then(r => {
        if (r.status === 200) {
          return new Carrier(r.data);
        }
      })
      .catch(e => {
        
        
      });
  },
  newCarrier({ commit }, carrier) {    
    return VN_Service.createCarrier(carrier)
      .then(r => {
        if (r.status === 200 || r.status === 201) {
          commit("addCarrier", r.data);
          store.dispatch(
            "errorhandling/addMessage",
            $t.t("$gt.msg.system.success.carrier.create")
          );    
          return r.data;
        }
      })
      .catch(e => {
        
        
      });
  },
  updateCarrier({ commit }, carrier) {
  
    return VN_Service.updateCarrier(carrier)
      .then(r => {
        if (r.status === 200 || r.status === 201) {  
          commit("updateCarrier", r.data);
          store.dispatch(
            "errorhandling/addMessage",
            $t.t("$gt.msg.system.success.carrier.update")
          );    
          return r.data;
        }
      })
      .catch(e => {
        
        
      });
  },
};

export const getters = {
	getCarrierByID: state => id => {
		return state.carriers.find(e => e.id === id);
	},
	
	getCarriers(state){
		return state.carriers;
	}
};
