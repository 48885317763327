import Utils from "@/utils.js";

export default class Certifier{
	constructor( certifier ){
		/* properties list */
		this.name = null;
		this.country = null;
		this.zip = null;
		this.city = null;
		this.street = null;
		this.hsnr = null;

		/* if given data from API, assign it into field values */
		if( !!certifier ){
			
			

			if(typeof(certifier.name)      !== "undefined") this.name      = certifier.name;
            if(typeof(certifier.country)   !== "undefined") this.country   = certifier.country;
            if(typeof(certifier.zip)       !== "undefined") this.zip       = certifier.zip;
			if(typeof(certifier.city)      !== "undefined") this.city      = certifier.city;
			if(typeof(certifier.street)    !== "undefined") this.street    = certifier.street;
			if(typeof(certifier.hsnr)      !== "undefined") this.hsnr      = certifier.hsnr;
            
		} else {
			// 

			/**/
		}
	}
}
