import VN_Service from "@/services/VN_Services.js";
import store from "@/store/store";
import i18n from "@/i18n";
import SparesForm from "@/store/classes/Spares"; 
import Spareout from "@/store/classes/SpareOut"; 
import Spareoutlist from "@/store/classes/SpareOutList"; 
import SpareOutComposite from "@/store/classes/composite/SpareOutComposite"; 
const $t = i18n();
export const namespaced = true;

export const state = {
    spareoutlists: [],
    spareouts: [],
    spares: [],
    spareoutlist: null
}

export const mutations = {
    clearSpares(state) {
        state.spares = [];
    },
    addSpares(state,data) {
        data.forEach(spare => {state.spares.push(new SparesForm(spare))})
    },
    clearSpareOutLists(state) {
        state.spareoutlists = [];
    },
    addSpareOutLists(state,data) {
        data.forEach(spareoutlist => {state.spareoutlists.push(new Spareoutlist(spareoutlist))})
    },
    clearSpareOutList(state) {
        state.spareoutlist = null;
    },
    addSpareOutList(state,data) {
        state.spareoutlist = new SpareOutComposite(data);
    },
    clearSpareOuts(state) {
        state.spareouts = [];
    },
    addSpareOuts(state,data) {
        data.forEach(spareout => {state.spareouts.push(new Spareout(spareout))})        
    },
    updateSpareOutList(state, data){
        let newspareoutlist = state.spareoutlists.find(
            r => r.id === data.id
        );
        Object.assign( newspareoutlist, data);
    },
}

export const actions = {
    getSpareOutLists({commit},dismantlerId) {
        return VN_Service.getSpareOutLists(dismantlerId).then(r => {
            if(r.status === 200 ) {
                commit("clearSpareOutLists")
                commit("addSpareOutLists", r.data)
                return r.data
            }
        })
    },
    getSpareOuts({commit},dismantlerId) {
        return VN_Service.getSpareOuts(dismantlerId).then(r => {
            if(r.status === 200 ) {
                commit("clearSpareOuts")
                commit("addSpareOuts", r.data)
                return r.data
            }
        })
    },
    getSpareOutListById({commit},spareoutlist) {
        if(typeof spareoutlist.id === 'undefined' || spareoutlist.id === null) {
            state.spareoutlist = new SpareOutComposite();
        } else {
            return VN_Service.getSpareOutListById(spareoutlist).then(r => {
                if(r.status === 200 ) {
                    commit("clearSpareOutList")
                    commit("addSpareOutList", r.data)
                    return r.data
                }
            })
        }
    },
    saveSpareOutList({commit},spareoutlist) {
        
        spareoutlist.spareOuts.forEach(spareout => spareout.nonMetalFactor = spareout.nonMetalFactor / 100)
        return VN_Service.saveUpdateSpareOutList(spareoutlist).then(r => {
            if(r.status === 200) {
                if(spareoutlist.id === null) {
                    store.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.spareout.created")
                    ); 
                    commit("clearSpareOutList")
                    commit("addSpareOutList", r.data)
                    return new SpareOutComposite(r.data);
                } else {
                    store.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.spareout.edited")
                    ); 
                    commit("clearSpareOutList")
                    commit("addSpareOutList", r.data)
                    return new SpareOutComposite(r.data);
                }   
            }
        })
    },
    getSpares({commit},) {
        return VN_Service.getSpares().then(r => {
            if(r.status === 200 ) {
                commit("clearSpares")
                commit("addSpares", r.data)
                return r.data
            }
        })
    }
}

export const getters = {
    
}