
import VN_Service from "@/services/VN_Services.js";
import i18n from "@/i18n";
import WastetypesTree from "@/store/classes/Wastetypes.js";

const $t = i18n();

export const namespaced = true;

export const state = {
    wastetypes:[],
    isFetched: false
}

export const mutations = {
    clearWasteTypesTree(state) {
        state.wastetypes =[]
    },
    addWasteTypesTree(state, wastetypestree) {     
        state.wastetypes.push(wastetypestree)
        
       
        state.isFetched = true
    },
    addWasteTypesByFraction() {}
}

export const actions = {
    getWastetypeByFractionId({commit}, fractionId) {
        return VN_Service.getWasteTypeByFraction(fractionId)
            .then(r => {
                if(r.status === 200) {
                    //commit("clear")
                    //commit("addWasteTypesByFraction", r.data)
                    return r.data;
                }
            })
    },
    async getWastetypesTree({commit}) {        
        if(state.isFetched === false) {
            return await VN_Service.getWasteTypesTree().then(r => {
                if(r.status === 200) {
                    commit('clearWasteTypesTree')
                    commit('addWasteTypesTree', new WastetypesTree(r.data))
                }                  
                return new WastetypesTree(r.data);            
            })
        }
    },
}

export const getters = {

}