import { date_format, required, email, alpha_num, max, mimes, confirmed, ext, min, digits, alpha, numeric ,size,max_value,between } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import store from "@/store/store";
import i18n from "@/i18n";
import { validate } from 'vee-validate';
const $t = i18n();
import { vm } from '@/main'

import * as moment from 'moment'
/* 
extend('max_value', {
  ...max_value,
  message: (field) => field + 'Please, only up to'
}); */

extend("chars", {
  ...alpha,
  message: (field) => field + $t.t('$gt.msg.validation.chars')
});
extend("mimes", {
  ...mimes,
  message: (field) => $t.t('$gt.msg.validation.filetype')
});
extend("digits", {
  ...digits,
  message: (field) => field + $t.t('$gt.msg.validation.digits')
});
extend("size", {
  ...size,
  message: $t.t('$gt.msg.validation.imageTooLarge')
});
extend("lastEffectiveTill", {
  message: (field) => field + $t.t('$gt.msg.validation.lastEffectiveTill'),
  validate: value => {
    var date = moment(value,'L')
    var dateToCheck = moment(date).format("YYYY-MM-DD")
    var lastEffectiveTill = store.state.stockModule.lastEffectiveTill
    if(lastEffectiveTill !== null) {
      var isFuture = dateToCheck >= lastEffectiveTill
      if (isFuture) {        
        return true;
      }
      return false;
    }
    return true
  }
});


extend("date_range", {  
  params: ["validfrom","validto"],
  validate: (value, { validfrom,validto }) => {
    if(validto > validfrom) {
      return true
    } 
    return false
  },
  message: (field,validto) => field + ' darf nicht nach "Gültig von" liegen'
});

extend("chars_dash", {
  message: (field) => field + $t.t('$gt.msg.validation.chars'),
  validate: value => {
    var strongRegex = new RegExp("^[a-zA-Z- ]+$");
    return strongRegex.test(value);
  }
});

extend("weight", {
  message: (field) => field + $t.t('$gt.msg.validation.weight'),
  validate: value => {
    var strongRegex = new RegExp("^[0-9,. ]*$");    
    return strongRegex.test(value);
  }
});
extend("desernr", {
  message: (field) => field + $t.t('$gt.msg.validation.weight'),
  validate: value => {
    var strongRegex = new RegExp("^[0-9,. ]*$");
    return strongRegex.test(value);
  }
});
extend("vnrnumbers", {
  ...numeric,
  message: (field) => field + ' ' + $t.t('$gt.msg.validation.digits')
});
extend("numeric", {
  ...numeric,
  message: (field) => field + ' ' + $t.t('$gt.msg.validation.zip')
});

extend("required", {
  ...required,
  message: $t.t('$gt.msg.validation.compulsory')
});
extend("max_quantity", {
  ...max_value,
  message: $t.t('$gt.msg.validation.quantityexceed')
});
extend("between", {
  ...between,
 
});
extend("finValid", {
  ...alpha_num,
  message: (field) => field + $t.t('$gt.msg.validation.finValid')
});
extend("confirmed", {
  ...confirmed,
  message: (field) => field + $t.t('$gt.msg.validation.passwordMatch')
});
extend("max", {
  ...max,
  message: "This field must be {length} characters or less"
});
extend("min", {
  ...max,
  message: "This field must be {length} characters or less"
});
extend("max_value", {
  ...max,
  params: ["max_value"],
  validate: (value, { max_value }) => {
    if(max_value < value.length ) {      
      return false;
    } 
    return true;
  },
  message: (field,max_value) => field + $t.t('$gt.msg.validation.maxValue', max_value)
});
extend("finMin", {
  ...min,
  message: (field) => field + $t.t('$gt.msg.validation.finMin')
});
extend("betweenexchange", {
  ...between,
  params: ["between"],
  /* validate: (value, { between }) => {
    
    value = Number(value)
    const mina = Number(between[0])
    const maxa = Number(between[1])
    
    if(value > mina || value < maxa) {
      return false
    }
    return true;
  }, */
  message: (field) => field + $t.t('$gt.msg.validation.finMin')
});
extend("email", {
  ...email,
  message: $t.t('$gt.msg.validation.eMailFormat')
});

extend('passwordlogin', {
    ...min,
  message: $t.t('$gt.msg.validation.passwordMin')
});

extend('password', {
  message: 'The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)',
  validate: value => {
      var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
      return strongRegex.test(value);
  }
});