import Utils from "@/utils.js";
export default class Statement {
    constructor(statement) {
        this.id = null;
        this.effectiveTill = new Date().toISOString().substr(0, 10);
        this.dismantlerId = JSON.parse(localStorage.getItem('dismantlerId'))
        this.remark = null
        this.entries = null
        this.isLast = null
        this.locked = false

        if(!!statement) {
            if(typeof statement.id !== 'undefined') this.id = statement.id                                 
            if(typeof statement.effectiveTill !== 'undefined') this.effectiveTill = Utils.ISOToDateString(statement.effectiveTill);
            if(typeof statement.dismantlerId !== 'undefined') this.dismantlerId = statement.dismantlerId
            if(typeof statement.remark !== 'undefined') this.remark = statement.remark
            if(typeof statement.locked !== 'undefined') this.locked = statement.locked
            if(typeof statement.isLast !== 'undefined') this.isLast = statement.isLast
        }
    }

}