import store from "@/store/store";
import { Config } from "@/services/client.js";
import { client } from "@/services/client.js";
const units = {
	"tokg": 1000,
	"tot": 1000
};
export default {
	/* makes untracked copy of any object
	 * A) objects that are tracked by vue can not be inspected by console.log
	 * B) making changes to the copy do not change the copied information
	**/
	changeSort(options) {				
		store.dispatch('sortModule/addUpdateSorter', options)
	},
	make_untracked_copy( object ){
		/* TODO: add proper check */
		/* (???) if( typeof object !== "undefined" && object !== null ){ */
		return JSON.parse(JSON.stringify( object ));
	},
	DateStringToISO( date_string ){
		/* TODO: add check for being date-like */
		if( !!date_string ){
			return date_string + "T00:00:00.000+0000";
		} else {
			return null;
		}
	},
	ISOToDateString( iso_string ){
		/* TODO: add check for being date-like */
		if( !!iso_string ){
			return iso_string.substring(0, iso_string.indexOf("-")+6);
		} else {
			return null;
		}
	},
	findWasteById(data, id) {
		function iter(a) {
			if (a.id === id) {
				result = a;
				return true;
			}
			return Array.isArray(a.children) && a.children.some(iter);
		}
		var result;
		data.some(iter);
		return result
	},
	
	convertMeasure(item, measure) {
		
		
	},

	convertNumber(num, locale) {
		const { format } = new Intl.NumberFormat(locale);
		const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
		return +num
		  .replace(new RegExp(`[^${decimalSign}\\d]`, 'g'), '')
		  .replace(decimalSign, '.');
	  },
	formatNumbers(number) {	// need refactoring!! MAJOR BUG
		let lang = localStorage.getItem('language') ? localStorage.getItem('language') : process.env.VUE_APP_I18N_LOCALE		
		let newn = this.convertNumber(number, lang)		
		return newn		
		/* if(typeof number !== 'string') { number = number.toString()}
		return parseFloat(number.replace(/(,|\.|)([0-9]{11})/g,'$2').replace(/(,|\.)/,'.').replace(/(\s|\g)/,''))     */
	},

	GermanDateToJSON( date_string ){
		/* date_string falsy? fall through to return null */
		if( !!date_string ){
			/* german_date_regex is the counterpart to JSONToGermanDate's returning string */
			let german_date_regex = /^([0-9]{1,2})\.([0-9]{1,2})\.([0-9]+)$/;
			let matches = german_date_regex.exec( date_string );
			/* matches[0] is the WHOLE STRING! */
			/* matches 3 to 1 contains years to days respectively */

			/* no matches? fall through to return null */
			if( !!matches ){
				let date = new Date(
					matches[3].padStart(4, "0") +"-"+ matches[2].padStart(2, "0") +"-"+ matches[1].padStart(2, "0")
				);

				/* invalid date? fall through to return null */
				if( date != "Invalid Date" ){
					return date.toJSON();
				}
			}
		}
		
		return null;
	},
	JSONToGermanDate( json_date ){
		/* json_date falsy? fall through to return null */
		if( !!json_date ){
			let date = new Date( json_date );
			/* invalid date? fall through to return null */
			if( date != "Invalid Date" ){
				/**
				 * REFACTOR: there are official functions for this!
				 *   e.g.: Date.toLocaleDateString()
				 *
				 * return german formated date
				**/
				return date.getDate().toString().padStart(2, "0")
					+ "."
					+ (date.getMonth()+1).toString().padStart(2, "0")
					+ "."
					+ date.getFullYear().toString().padStart(4, "0")
				;
			}
		}
		
		return null;
	},
	
	isMobile() {
		if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			return true
		} else {
			return false
		}
	},
	downloadPDF(url) {
		client.base.get(url, { responseType: 'blob'}) 
            .then((response) => {
				let contentDisposition = response.headers['content-disposition']
				let filename = contentDisposition.match(/filename="(.+)"/)[1];
                const url = window.URL.createObjectURL(new Blob([response.data], {type:response.headers['content-type']}));
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank'
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            }); 
	},
	downloadFile(url, token, target) {
		var lang = localStorage.getItem('language');
		
		const HFID = "downloadFile";
		var form = document.getElementById(HFID);
		if (!form) {
			form = document.createElement("form");
			form.style.display = "none"; 
			form.id = HFID;
			form.method = "POST";			
			form.target = '_blank' // target ? target : "_self";
			var auth = form.appendChild(document.createElement("input"));
			var l = form.appendChild(document.createElement("input"));
			auth.name = "Authorization";
			l.name = "l";
			document.body.appendChild(form);
		}
		form.elements["Authorization"].value = "Bearer " + token;		
		form.elements["l"].value = lang;
		form.action = url;
		form.submit();
		/* form.reset()
		var form = '' */
	},
	exportFile(url, token, target) {
		
		var lang = localStorage.getItem('language');
		const HFID = "exportEval";
		var form = document.getElementById(HFID);
		if (!form) {
			form = document.createElement("form");
			form.style.display = "none";
			form.id = HFID;
			form.method = "POST";			
			form.target = '_blank' //target ? target : "_self";
			var auth = form.appendChild(document.createElement("input"));
			var l = form.appendChild(document.createElement("input"));
			auth.name = "Authorization";
			l.name = "l";
			document.body.appendChild(form);
		}
		form.elements["Authorization"].value = "Bearer " + token;		
		form.elements["l"].value = lang;
		
		form.action = url;
		
		form.submit();
		//form.remove();
		
	},


	/*
	uploadFile(url, token) {
		const HFID = "_hidden_form_file_";
		var file = document.getElementById(HFID);
		if (!file) {
			file = document.createElement("input");
			file.id = HFID;
			file.type = "file";
			file.onchange = function(e) {
				if (!this.value) return;
				var form = new FormData();
				form.append("file", file.files[0]);
				var xhr = new XMLHttpRequest();
				xhr.onreadystatechange = function() {
					if (this.readyState == 4) {
						switch (this.status) {
							case 200:
								alert("Upload Complete");
								break;
							case 0:
								alert("Server down?");
								break;
							default:
								alert("HTTP " + this.status + "\n\n" + this.responseText);
						}
					}
				};
				xhr.open("POST", url);
				if (token) xhr.setRequestHeader("Authorization", "Bearer " + token);
				xhr.send(form);
				this.value = null;
			};
		}
		file.click();
	},
	*/
	createBase64(file){
        //max_size_bytes = max_size * 1048576;
        return new Promise((resolve, reject) => {
            
            var fr = new FileReader();  
            fr.onload = () => {
				var data = fr.result;
                resolve({
					base64: data,
					fileName: file.name,
					fileType: file.type
				})
            };
            fr.readAsDataURL(file);
            
        });
    },


};
