import Vue from "vue";
import Vuex from "vuex";
import i18n from "@/i18n";
const $t = i18n();
/* dynamic state models */
import * as appModule from '@/store/modules/App.js';
import * as accountsModule from "@/store/modules/Accounts.js";
import * as dismantlersModule from "@/store/modules/Dismantlers.js";
import * as collectorsModule from "@/store/modules/Collectors.js";
import * as receptionsModule from "@/store/modules/Receptions.js";
import * as unitsModule from "@/store/modules/Units.js";
import * as carriersModule from "@/store/modules/Carriers.js";
import * as disposersModule from "@/store/modules/Disposers.js";
import * as contactsModule from "@/store/modules/Contacts.js";
import * as employeesModule from "@/store/modules/Employees.js"
import * as paymentsModule from "@/store/modules/Payment.js";
import * as btbentryModule from "@/store/modules/BtbEntry.js";
import * as stockModule from "@/store/modules/Stock.js";
import * as btboutputModule from "@/store/modules/BtbOutput.js";
import * as wastetypesModule from "@/store/modules/Wastetypes.js"
import * as carinventoryModule from "@/store/modules/CarInventory.js";
import * as disposalsModule from "@/store/modules/Disposals.js";
import * as incidentsModule from "@/store/modules/Incidents.js";
import * as spareoutsModule from "@/store/modules/SpareOuts.js";
import * as documentsModule from "@/store/modules/Documents.js";
import * as remanufacturerModule from "@/store/modules/Remanufacturer.js";
import * as contractsModule from "@/store/modules/Contracts.js";
import * as sortModule from "@/store/modules/Sorter.js";
import * as purchaserModule from "@/store/modules/Purchaser.js";
import * as carsearchModule from "@/store/modules/CarSearch.js";
import * as departmentsModule from "@/store/modules/Departments.js";
import * as looperModule from "@/store/modules/Looper.js";
import * as recyclersModule from "@/store/modules/Recyclers.js";
import * as sseModule from "@/store/Sse.js";


/* /dynamic state models */
/* static state models */
import * as expertsModule from "@/store/modules/Experts.js";
import * as errorhandling from "@/store/errorhandling.js";
import { state } from "./modules/App";

Vue.use(Vuex);

const store =  new Vuex.Store({
  modules: {
    accountsModule,
    appModule,
    dismantlersModule,
    departmentsModule,
    collectorsModule,
    receptionsModule,
    expertsModule,
    errorhandling,
    unitsModule,
    carriersModule,
    btbentryModule,
    paymentsModule,
    disposersModule,
    contactsModule,
    wastetypesModule,
    employeesModule,
    btboutputModule,
    stockModule,
    carinventoryModule,
    disposalsModule,
    incidentsModule,
    spareoutsModule,
    documentsModule,
    remanufacturerModule,
    contractsModule,
    sortModule,
    purchaserModule,
    carsearchModule,
    looperModule,
    recyclersModule,
    sseModule,
  },
  state: {
    viewHeadline: '',
    cancelTokens: [],
    oldVersion: false,
    pipe: process.env.VUE_APP_PIPELINE,
  }, 
  actions: {    
    CANCEL_PENDING_REQUESTS(context) {
      // Cancel all request where a token exists
      context.state.cancelTokens.forEach((request, i) => {
        
          if(request.cancel){
              request.cancel();
          }
      });

      // Reset the cancelTokens store
      context.commit('CLEAR_CANCEL_TOKENS');
    },
    certificate_upload(filepath) {

      let upload_file_input = document.querySelector(
        "#upload_file_form > #upload_file"
      );
      let temp_form_data = new FormData();
      temp_form_data.append("file", upload_file_input.files[0]);

      VN_Service.uploadCertificate(r.data.certificate.id, temp_form_data)
        .then(response => {
          if (response.status === 200) {
            commit("replaceDismantler", response.data);
          }
        })
        .catch(error => {
          
        });
    },
    resetAll() { // clear everything on Logout
      this.commit("departmentsModule/clearDismantlers");
      this.commit("collectorsModule/clearCollectors");
      this.commit("receptionsModule/clearReceptions");
      this.commit("accountsModule/clearAccounts");
      this.dispatch("errorhandling/clearError");
      this.dispatch("errorhandling/clearMessage");      
    },
    loginFetch() {
      
    },
    resetState () {
      store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)))
    }
  },
  getters: {
    cancelTokens(state) {
        return state.cancelTokens;
    },
    getPipe(state) {
      return state.pipe
    },
    getviewHeadline(state) {
      return state.viewHeadline
    }
  },
  mutations: {
    setOldVersion(state, status) {
      state.oldVersion = status      
    },
    setviewHeadline(state, headline) {
      state.viewHeadline = headline      
    },
    clearviewHeadline(state) {
      state.viewHeadline = ''
    },
    setPipe(state) {
      state.pipe = process.env.VUE_APP_PIPELINE
    },
    ADD_CANCEL_TOKEN(state, token) {
        state.cancelTokens.push(token);
    },
    CLEAR_CANCEL_TOKENS(state) {
        state.cancelTokens = [];
    },
    setSSEModal: (state, value) => (state.ssemodal = value),
    setSSEData: (state, value) => (state.ssedata = value),
  },
});

const initialStateCopy = JSON.parse(JSON.stringify(store.state))

export default store
