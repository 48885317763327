import Utils from "@/utils.js";
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : process.env.VUE_APP_I18N_LOCALE 
export default class RemanCart {
    constructor(cart) {        
        this.entries = [];
        this.readyForPickup = false;
        this.remanContractId = null;
        this.remanId = null;
        this.remanName = null;
        this.remanProtocolId = null;
        this.total = 0;

        if( !!cart ) {            
            if(typeof(cart.entries) !== "undefined") this.entries = cart.entries;
            if(typeof(cart.readyForPickup) !== "undefined") this.readyForPickup = cart.readyForPickup;
            if(typeof(cart.remanContractId) !== "undefined") this.remanContractId = cart.remanContractId;
            if(typeof(cart.remanId) !== "undefined") this.remanId = cart.remanId;
            if(typeof(cart.remanName) !== "undefined") this.remanName = cart.remanName;
            if(typeof(cart.remanProtocolId) !== "undefined") this.remanProtocolId = cart.remanProtocolId;
            if(typeof(cart.total) !== "undefined") this.total = cart.total;
        }
    }
}
