
export const namespaced = true;

export const state = {
    sorter: []
}

export const mutations = {
    addSorter(state, options) {
        var exist = state.sorter.some(element => {
            if(element.route === options.route) {
                Object.assign( element, options);   
                return true
            } 
            return false
        });
        if(!exist) {
            state.sorter.push(options)
        }
    }
}
export const actions = {    
	addUpdateSorter({commit}, options) {
		commit('addSorter', options)
	},
}

export const getters = {
    getSortOptions(state) {
        return route => {
            return state.sorter.find(sorter => sorter.route === route)
        }
    }
}

