import moment from 'moment';
import store from "@/store/store";

export default {
    formatDate: function (date) {               
        let lang = localStorage.getItem('language') ? localStorage.getItem('language') : process.env.VUE_APP_I18N_LOCALE
        moment.locale(lang)    
        if (date) {
            return moment(date).format('L'); //FixME: UTC Timezones
        }
    },
    truncate: function(value,length) {
        if (!value) return ''
            value = value.toString()
        if(value.length > length){
            return value.substring(0, length) + "..."
        }else{
            return value
        }
    },
    formatNumber(number) {
        let lang = localStorage.getItem('language') ? localStorage.getItem('language') : process.env.VUE_APP_I18N_LOCALE
        return new Intl.NumberFormat(lang, { style: 'currency', currency: 'EUR' }).format(number)
    },
    formatPrice(number) {
        let lang = localStorage.getItem('language') ? localStorage.getItem('language') : process.env.VUE_APP_I18N_LOCALE
        return new Intl.NumberFormat(lang).format(number)
    },
    formatAmount(number) {
        let lang = localStorage.getItem('language') ? localStorage.getItem('language') : process.env.VUE_APP_I18N_LOCALE
        return new Intl.NumberFormat(lang).format(number)
    }
    
}