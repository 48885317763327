import Utils from "@/utils.js";
import { Config } from "@/services/client.js";
import VN_Service from "@/services/VN_Services.js";
import store from "@/store/store";
import i18n from "@/i18n";

const $t = i18n();
import ReceptionLite from "@/store/classes/composite/ReceptionLite.js";
import ReceptionFormComposite from "@/store/classes/composite/ReceptionFormComposite.js";

export const namespaced = true;
export const state = {
  /* Liste aller sichtbaren Verwertungsnachweise */
  receptions: null
};

export const mutations = {
  /* + */
  /* pushes a single reception into state */
  addReception(state, reception_data) {
    let reception = new ReceptionLite({id: reception_data.id});
    state.receptions.push(reception);
    localStorage.setItem("reception_count", state.receptions.length);
    /* REFACTOR: sort after pushing into receptions */
  },
  /* pushes multiple receptions into state */
  addReceptions(state, arr_of_reception_data) {
    arr_of_reception_data.forEach(d => {
      state.receptions.push(new ReceptionLite(d));
    });
    localStorage.setItem("reception_count", state.receptions.length);
    /* REFACTOR: sort after pushing into receptions */
  },

  /* - */
  /* removes a reception from state by its id */
  removeReception(state, reception) {
    let index = state.receptions.indexOf(reception);
    state.receptions.splice(index, 1);
    localStorage.setItem("reception_count", state.receptions.length);
  },
  /* wipes the currently tracked receptions (for reset) */
  clearReceptions(state) {
    state.receptions = [];
  },

  /* / */
  /* replaces a reception in state by its id */
  updateReception(state, new_reception_data) {
    
    /* get reception reference */
    let reception_to_be_replaced = state.receptions.find(
      r => r.id === new_reception_data.id
    );
    if (!!reception_to_be_replaced) {
      const manufacturer = (new_reception_data.car.manufacturer != null) ? new_reception_data.car.manufacturer : "";
      const model = (new_reception_data.car.model != null) ? new_reception_data.car.model : "";
      reception_to_be_replaced.vnr = new_reception_data.vn.identnr;
      reception_to_be_replaced.locked = new_reception_data.vn.locked;
      reception_to_be_replaced.licenceplate =
        new_reception_data.car.licenceplate;
      reception_to_be_replaced.vin = new_reception_data.car.vin;
      reception_to_be_replaced.car =  manufacturer + " " + model
      reception_to_be_replaced.keeper = new_reception_data.keeper.lastname;
      reception_to_be_replaced.company = new_reception_data.company;
      reception_to_be_replaced.date = new_reception_data.vn.date;
      reception_to_be_replaced.result.collectorCertifierOk = new_reception_data.status.collectorCertifierOk;
      reception_to_be_replaced.result.dismantlerCertifierOk = new_reception_data.status.dismantlerCertifierOk;
      reception_to_be_replaced.result.dismantlerCertificateDocOk = new_reception_data.status.dismantlerCertificateDocOk;
      
      return new_reception_data;
    } else {
      /* TODO: error handling */
      return null;
    }
  }
  /*??? replaceReceptions(){}, ???*/
  /* REFACTOR: sort after replacing receptions */
};
export const actions = {
  /* ICRUD */
  /* Index receptions */
  async getAllReceptions({ commit, state }, dates) {
    //state.receptionsFetched = true;   
    return await VN_Service.indexReceptions(dates)
      .then(r => {
        if (r.status === 200) {
          commit("clearReceptions");
          commit("addReceptions", r.data);
          return r.data
        }
      })
      .catch(e => {
        
        
        
      });
  },

  /* Create reception */
  async newReception({ commit }, reception) {
    /* /reformat reception data */
    
    /* !!! dependencies are created implicitly !!! */
    return await VN_Service.createReception(reception)
      .then(r => {
        if (r.status === 200 || r.status === 201) {
          
          commit("addReception", r.data);
          store.dispatch(
            "errorhandling/addMessage",
            $t.t("$gt.msg.system.success.reception.created")
          );
          let receptionFormComposite = new ReceptionFormComposite(r.data);
          commit("updateReception", receptionFormComposite);
          return receptionFormComposite;
        }
      })
      .catch(e => {
        
        
        
      });
  },

  /* Read reception */
  /** update a single reception in the state by its id **/
  async getReception({ commit }, reception_id) {
    return await VN_Service.readReception(reception_id)
      .then(r => {
        if (r.status === 200) {
          return new ReceptionFormComposite(r.data);
        }
      })
      .catch(e => {
        console.log(
          $t.t("$gt.msg.error.reception.create", { id: reception_id })
        );
        
        
      });
  },

  /* Update (destructive edit) reception */
  async updateReception({ commit }, reception) {
    
    return await VN_Service.updateReception(reception)
      .then(r => {
        if (r.status === 200) {
                    
          store.dispatch(
            "errorhandling/addMessage",
            $t.t("$gt.msg.system.success.reception.edited")
          );
          let receptionFormComposite = new ReceptionFormComposite(r.data);
          commit("updateReception", receptionFormComposite);
          return receptionFormComposite;
        }
      })
      .catch(e => {
        
        
        
      });
    /* /update reception */
  },

  /* Delete reception */
  /** does not exist yet **/
  /** deleteReception({ commit }, reception) {}, **/
  /* /ICRUD */

  async lockReception({ commit,dispatch }, reception) {
    return await VN_Service.lockReception(reception.id)
      .then(r => {
        if (r.status === 200) {
          
          store.dispatch(
            "errorhandling/addMessage",
            $t.t("$gt.msg.system.success.reception.locked")
          );

          let receptionFormComposite = new ReceptionFormComposite(r.data);
          commit("updateReception", receptionFormComposite);
          
          return receptionFormComposite;
        }
      })
      .catch(e => {                        
      });
  },
  async unlockReception({ commit,dispatch }, receptionId) {  // Use for free unlock
    return await VN_Service.unlockReception(receptionId)
      .then(r => {
        if (r.status === 200) {
          
          store.dispatch(
            "errorhandling/addMessage",
            $t.t("$gt.msg.system.success.reception.unlocked")
          );

          let receptionFormComposite = new ReceptionFormComposite(r.data);
          commit("updateReception", receptionFormComposite);
          
          return receptionFormComposite;
        } else {
          store.dispatch(
            "errorhandling/addMessage",
            $t.t("$gt.msg.system.success.reception.unlocked")
          );
        }
      })
      .catch(e => {                        
      });
  },
  async updateCount({commit}, data) {
    return await VN_Service.updateCount(data).then(r => {
      console.log(r)
    })
  },
  transferCar({commit},reception) {
    return VN_Service.transferCarToBtb(reception.car.id)
      .then(r => {
        if (r.status === 200) {
          //VN_Service.prefillBtb(reception.car)
          store.dispatch(
            "errorhandling/addMessage",
            $t.t("$gt.msg.system.success.reception.transfered")
          );
          let receptionFormComposite = new ReceptionFormComposite(r.data);
          commit("updateReception", receptionFormComposite);          
          return receptionFormComposite;
        }
      })
      .catch(e => {
        
        
        
      });
  },
  downloadList({ commit }, receptionId) {
    Utils.downloadFile(
      Config.baseURL + Config.disorders + receptionId,
      store.state.accountsModule.userData.token
    );
    //return VN_Service.downloadRemovalList(receptionId)    
  }
};
export const getters = {
  getReceptionByID: state => id => {
    return state.receptions.find(d => d.id === id);
  },
  has_receptions(state) {
    /* check locally stored reception count first */
    let stored_reception_count = localStorage.getItem("reception_count");
    if (stored_reception_count === null || stored_reception_count == 0) {
      stored_reception_count = state.receptions.length;
      localStorage.setItem("reception_count", stored_reception_count);
    }
    return stored_reception_count > 0;
  },
  getReceptions(state) {
    return state.receptions;
  }
};
export const helper = {};
