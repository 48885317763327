import Utils from "@/utils.js";
export default class Cargo {
    constructor(cargo) {
        this.id = null;        
        this.amount = null        
        this.measure = null
        this.looperId = null
        this.cargoTypeId = null
        this.offerExists = false

        if(!!cargo) {
            if(typeof cargo.id !== 'undefined') this.id = cargo.id                                                         
            if(typeof cargo.amount !== 'undefined') this.amount = cargo.amount
            if(typeof cargo.looperId !== 'undefined') this.looperId = cargo.looperId
            if(typeof cargo.cargoTypeId !== 'undefined') this.cargoTypeId = cargo.cargoTypeId
            if(typeof cargo.tr.measure !== 'undefined') this.measure = cargo.tr.measure
            if(typeof cargo.tr.offerExists !== 'undefined') this.offerExists = cargo.tr.offerExists
        }
    }

}