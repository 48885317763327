import VN_Service from "@/services/VN_Services.js";
import store from "@/store/store";
import i18n from "@/i18n";
import Btbentry from "@/store/classes/BtbEntry"; 

const $t = i18n();
export const namespaced = true;

export const state = {
    btbcars: [],
    btbentries: [],
    btbcarentries: []
}

export const mutations = {
    clearBtbCars(state) {
        state.btbcars = [];
    },
    clearBtbEntries(state) {
        state.btbentries = [];
    },
    clearBtbCarEntries(state) {
        state.btbcarentries = [];
    },
    addBtbcars(state,data) {
        data.forEach(ol => {state.btbcars.push(ol)})
    },
    addBtbEntries(state,data) {
        data.forEach(entry => {
            if(!entry.locked) {
                entry.isSelectable = true
            } else {
                entry.isSelectable = false
            }
            state.btbentries.push(new Btbentry(entry))
        })
    },
    addBtbCarEntries(state,data) {        
        data.forEach(entry => {state.btbcarentries.push(new Btbentry(entry))})
    },
    addSingleBtbEntry(state,data) {
        data.forEach(entry => {state.btbentries.push(new Btbentry(entry))})
    },
    updateSingleBtbEntry(state, data){
        data.forEach(entry => {
            
            let newentry = state.btbentries.find(
                r => r.id === entry.id      
            );
            Object.assign(newentry, new Btbentry(entry));
        })
    },
    addBtbCar(state,data) {
        state.btbcars.push(data)
    },
    deleteSingleBtbEntry(state,data) {
        data.forEach(entry => {
            let deleted = state.btbentries.find(
                r => r.id === entry.id      
            );
            const index = state.btbentries.indexOf(deleted);
            state.btbentries.splice(index,1)
        })
    }
}

export const actions = {
    getPrefillTypes() {
        return VN_Service.getPrefillTypes().then(r => {
            if(r.status === 200 ) {
                return r.data
            }
        })
    },
    getBtBEntriesByCar({commit}, id) {
        return VN_Service.getEntriesByCar(id).then(r => {
            
            commit("clearBtbCarEntries")
            commit("addBtbCarEntries", r.data) 
                return r.data
            })
    },
    getBtbCars({commit},data) { // by dismantlerId
        return VN_Service.getBtbCars(data)
        .then(r => {
            if(r.status === 200) {                   
                commit("clearBtbCars")
                commit("addBtbcars", r.data)                             
                return r.data
            }
        }).catch(e => {
            
            
        });
    },    
    getBtbEntries({commit},id) { // by dismantlerId
        return VN_Service.getBtbEntries(id)
        .then(r => {
            if(r.status === 200) {   
                commit("clearBtbEntries")
                commit("addBtbEntries", r.data)                       
                return r.data
            }
        }).catch(e => {
            
            
        });
    },
    async saveBtBEntries({commit}, data) {
        return await VN_Service.saveUpdateBtbEntries(data)
        .then(r => {
            if(r.status === 200) {
                commit("clearBtbCarEntries")
                commit("addBtbCarEntries",r.data)
                    store.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.btbincar.created")
                    ); 
                return r.data;
            }
        })
        .catch(e => {
            /* store.dispatch(
                "errorhandling/addError",
                $t.t("$gt.msg.system.error.btbincar.create")
            ); */
        });
    },    
    async saveUpdateBtbEntries({commit}, data) {
        var entry = null;
        if(typeof data === 'object' && data.length > 1) {
                entry = data
        } else {
            entry = [];
            entry.push(data)
        }
        return await VN_Service.saveUpdateBtbEntries(entry)
        .then(r => {
            if (data.deleted === true ){
                commit("deleteSingleBtbEntry", entry)
            } else if(r.status === 200) {
                if(data.id === null) {
                    commit("addSingleBtbEntry", r.data)
                    store.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.btbin.created")
                    );
                } else {
                    commit("updateSingleBtbEntry",r.data)
                    store.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.btbin.edited")
                    );
                }
                return r.data;
            }
        })
        .catch(e => {
            /* store.dispatch(
                "errorhandling/addError",
                $t.t("$gt.msg.system.error.btbin.create")
            ); */
        });
    }
}

export const getters = {
}