
import VN_Service from "@/services/VN_Services.js";
import i18n from '@/i18n'

const $t = i18n();

export const namespaced = true;

export const state = {
	/* Liste aller Zertifizierungs-Experten */
	experts: [],
};

export const mutations = {
	/* wipes the currently tracked experts (for reset) */
	clearExperts(state){
		state.experts = [];
	},
	addExperts(state, arr_of_expert_data) {
		arr_of_expert_data.forEach(e => {
			/**
			 * This way the selectbox can do more
			 */
			e["data-text"] = e.name;
			e["data-value"] = e.name;
			state.experts.push( e );
		});
	},
};

export const actions = {
	/* REFACTOR: split this fetch-all in multiple calls and a wrapper */
	getAllExperts({ commit, state, getters }) {
		if(!state.experts.length){
			VN_Service.indexExperts()
			.then(r => {
				if( r.status === 200 ){
					commit("clearExperts");
					commit( "addExperts", JSON.parse(JSON.stringify(r.data)) );
				}
			})
			.catch(e => {
				
				
				
			});
		} 
	},
};

export const getters = {
	getExpertByID: state => id => {
		return state.experts.find(e => e.id === id);
	},
	
	getExperts(state){
		return state.experts;
	}
};

export const helper = {};
