
export default class MyDisposer{
	constructor( disposer ){
		/* properties list */
		this.iban = null;
		this.bic = null;
		this.bankname = null;
		this.tel = null;
		//this.abroad = false;
		this.fax = null;
		this.email = null;
		this.taxnumber = null;
		this.customernumber = null;        
		this.contacts = [
			{name: null, email: null, tel: null, fax: null},
			{name: null, email: null, tel: null, fax: null}
		]
		if( !!disposer ){			
			
			if(typeof(disposer.iban)        !== "undefined") this.iban        = disposer.iban;
			if(typeof(disposer.bic)     !== "undefined") this.bic     = disposer.bic;
			if(typeof(disposer.bankname)     !== "undefined") this.bankname     = disposer.bankname;			
							
			if(typeof(disposer.contacts)       !== "undefined") {					
				this.contacts.splice(0, disposer.contacts.length, ...disposer.contacts)					
			}			
			if(typeof(disposer.tel)         !== "undefined") this.tel         = disposer.tel;
			if(typeof(disposer.abroad)         !== "undefined") this.abroad         = disposer.abroad;
			if(typeof(disposer.fax)         !== "undefined") this.fax         = disposer.fax;
			if(typeof(disposer.email)       !== "undefined") this.email       = disposer.email;
			if(typeof(disposer.taxnumber)         !== "undefined") this.taxnumber         = disposer.taxnumber;
			if(typeof(disposer.customernumber)	    !== "undefined") this.customernumber        = disposer.customernumber;
			
		} else {

		}
	}
}
