import VN_Service from "@/services/VN_Services.js";
import store from "@/store/store";
import i18n from "@/i18n";

const t = i18n();
export const namespaced = true;

export const state = {
    makers: null,
    models: null,
    types: null
}

export const mutations = {
    clearMakers(state) {
        state.makers = null
    },
    setMakers(state,makers) {
        state.makers = makers
    },
    clearModels(state) {
        state.models = null
    },
    setModels(state,models) {
        state.models = models
    },
    clearTypes(state) {
        state.types = null
    },
    setTypes(state,types) {
        state.types = types
    }
}

export const actions = {
    getMakers({commit}) {
        return VN_Service.getMakers().then(makers => {
            commit("clearMakers");
            commit("setMakers", makers.data);
            return makers.data;
        })
    },
    getModelsByMaker({commit}, makerId) {
        return VN_Service.getModelsByMaker(makerId).then(models => {
            commit("clearModels");
            commit("setModels", models.data);
            return models.data;
        })
    },
    getTypesByModel({commit}, modelId) {
        return VN_Service.getTypesByModel(modelId).then(types => {
            commit("clearTypes");
            commit("setTypes", types.data);
            return types.data;
        })
    }
}

export const getters = {
    
}