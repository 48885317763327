import Utils from "@/utils.js";
import { client } from "@/services/client.js";
import VN_Service from "@/services/VN_Services.js";
import store from "@/store/store";
import i18n from '@/i18n'
import Account from "@/store/classes/Account.js";
import axios from "axios";
import themes from "../../themes";
import router from "@/router.js";

const $t = i18n();

export const namespaced = true;
export const state = {
	accounts: [],
	userData: null,
	dismantlerId: JSON.parse(localStorage.getItem('dismantlerId')),
	remanId: JSON.parse(localStorage.getItem('remanId'))
};

const setUserdata = function(userData){	
	client.clean.defaults.headers.common["Authorization"] = `Bearer ${
	  userData.token
	}`;
	client.base.defaults.headers.common["Authorization"] = `Bearer ${
	  userData.token
	}`;
	/* if( userData.mandator ) {
		
		axios.defaults.params['m'] = userData.mandator ? userData.mandator.id : null;
		axios.defaults.headers.common["MandatorId"] = userData.mandator ? userData.mandator.id : "";	
		
	} */
}

const removeTokens = function(){	
	client.clean.defaults.headers.common["Authorization"] = ``;
	client.base.defaults.headers.common["Authorization"] = ``;
}
		
	

export const mutations = {
	/* pushes a single account into state */
	addAccount(state, account_data){
		state.accounts.push(new Account(account_data));
		localStorage.setItem( "account_count", state.accounts.length );
		/* REFACTOR: sort after pushing into accounts */
	},
	/* pushes multiple accounts into state */
	addAccounts(state, arr_of_account_data){
		arr_of_account_data.forEach(d => {
			state.accounts.push(new Account(d));
			
		});
		if(state.userData.admin) {
			state.accounts.forEach((item, index) => {				
				item.email = item.email //+ ' (' +item.mandator + ')'				
			})
		}
		localStorage.setItem( "account_count", state.accounts.length );
		/* REFACTOR: sort after pushing into accounts */
	},
	/* removes an account from state */
	removeAccount(state, account){
		let index = state.accounts.indexOf( account );
		state.accounts.splice(index, 1);
		localStorage.setItem( "account_count", state.accounts.length );
	},
	/* wipes the currently tracked accounts (for reset) */
	clearAccounts(state){
		state.accounts = [];
	},

	/* / */
	/* replaces a account in state by its id */
	replaceAccount(state, new_account_data){
		/* get account reference */
		let account_to_be_replaced = state.accounts.find(
			r => r.id === new_account_data.id
		);
		if( !!account_to_be_replaced ){
			/* update account reference with new data */
			Object.assign( account_to_be_replaced, new Account(new_account_data) );
			/* REFACTOR: sort after replacing account */
			return account_to_be_replaced;
		} else {
			/* TODO: error handling */
			return null;
		}
	},
	SET_MANDATOR (state,data) {
		state.userData = new Account(data);        
		
        localStorage.setItem('current_user', JSON.stringify({token: data.data.token, mandator: data.data.mandator}))         
    },
	updateAccountMandator(state, mandator) {
		let mandatorNew = state.userData.mandators.find(r => r.id = mandator.id)
		Object.assign(mandatorNew, mandator)
	},
    SET_USER_DATA (state, userData) {
		let userlang =  userData.language ? userData.language : localStorage.getItem('language') ? localStorage.getItem('language') : process.env.VUE_APP_I18N_LOCALE
		localStorage.setItem('language', userData.language ? userData.language : localStorage.getItem('language') ? localStorage.getItem('language') : process.env.VUE_APP_I18N_LOCALE)
		var checkTheme = themes.find(r => r.name === userData.uitheme)
		var theme = ''
		if(checkTheme === undefined) { 
			theme = 'Default';
		} else {
			theme = userData.uitheme ? userData.uitheme : localStorage.getItem("appTheme");
		}
		this.$app.$root.$vuetify.theme.dark = localStorage.getItem('dark') ? localStorage.getItem('dark') : false
		
		store.dispatch('appModule/setAppTheme', theme)
		store.dispatch('appModule/setAppLang', userlang)
		this.$app.$root.$vuetify.lang.current = userData.language ? userData.language : localStorage.getItem('language') ? localStorage.getItem('language') : process.env.VUE_APP_I18N_LOCALE
		this.$app.$root.$i18n.locale = userData.language ? userData.language : localStorage.getItem('language') ? localStorage.getItem('language') : process.env.VUE_APP_I18N_LOCALE
        if(userData.admin === false) {   
            state.userData = new Account(userData);   
			state.userData.token = userData.token;			
			localStorage.setItem('current_user', JSON.stringify({token: userData.token, mandator: null})) 						 			
			setUserdata(userData)			
			
			/* store.dispatch('accountsModule/setDismantlerId') */
        } else {
            const user = localStorage.getItem('current_user')
			const userstorage = JSON.parse(user)						
            state.userData = new Account(userData);            
            state.userData.token = userData.token;			
			
            if (userstorage) {
					//localStorage.setItem('current_user', JSON.stringify({token: userData.token, mandator: userstorage.mandator ? userstorage.mandator : null})) 					
					/* store.dispatch('accountsModule/setDismantlerId') */
					
					state.userData.mandator = userstorage.mandator ? userstorage.mandator : null	
					userData.mandator = userstorage.mandator ? userstorage.mandator : null	
                    localStorage.setItem('current_user', JSON.stringify(userData))
									
                    setUserdata(userstorage)
            } else {
                localStorage.setItem('current_user', JSON.stringify({token: userData.token, mandator: null}))
                setUserdata(userData)
            }                        
        }
		if(state.userData.myRights.accessGranted('VN', 'R')) {
			store.dispatch('accountsModule/setDismantlerId')  
			
		}
		else if(state.userData.myRights.accessGranted('RM', 'R')) {
			store.dispatch('accountsModule/setRemanId')  
		}
	},
	LOGOUT (state) {        
        localStorage.removeItem('current_user');
        localStorage.removeItem('dismantlerId');
        localStorage.removeItem('remanId');
        //store.dispatch("resetAll");
        store.dispatch("resetState");
		removeTokens()
        //location.reload();
    	router.go({name: 'login'})
		return true
    }	
};
export const actions = {	
	/* ICRUD */
		/* Index accounts */
	async getPaymentInfos() {
		return await client.base.get('/accounts/payment/my').then((r) =>{
			if(r.status === 200) {
				return r.data
			}
		})
	},
	async deleteAccount({commit}, account) {
		return await client.base.delete('/accounts/' + account.id).then((r) =>{
			if(r.status === 200) {
				//commit("replaceAccount", r.data);
				return r.data
			}
		})
	},
	async resendConfirmEmail({commit}, accountIdorEmail) {
		return await VN_Service.resendConfirmEmail(accountIdorEmail).then((r) =>{
			if(r.status === 200) {
				//commit("replaceAccount", r.data);
				return r.data
			}
		})
	},
	async setDismantlerId({commit}) {
		await store.dispatch('departmentsModule/getAllDismantlers')			
		if(store.state.departmentsModule.dismantlers) {
			var disId = store.state.departmentsModule.dismantlers.length > 0 ? store.state.departmentsModule.dismantlers[0].id : null			
			localStorage.setItem('dismantlerId', JSON.stringify(disId))
			store.state.accountsModule.dismantlerId = disId			
			if(store.state.accountsModule.userData.myRights.accessGranted('BTB', 'R') && disId) {				
				store.dispatch('stockModule/getLatestEffectiveTill',disId)  
			}
		}	
	},
	async setRemanId({commit}) {
		await store.dispatch('remanufacturerModule/getRemanufacturers')			
		if(store.state.remanufacturerModule.remanufacturers) {			
			localStorage.setItem('remanId', JSON.stringify(store.state.remanufacturerModule.remanufacturers[0].id))
			//store.state.accountsModule.dismantlerId = disId	
		}	
	},
	getmyAccount({commit}) {
		return VN_Service.myAccount().then(r =>{				
			if( r.status === 200 ){
				const user = localStorage.getItem('current_user')
				const userstorage = JSON.parse(user)
				r.data.token = userstorage.token;							
				commit('SET_USER_DATA', r.data)
				return new Account(r.data)
			}
		})/* .catch(({ error }) => {
			commit('LOGOUT')
			
		});  */
	},
	updateMyAccount({ dispatch, commit }, account) {
		
		void commit;		
		return VN_Service.updateMyAccount(account).then(r => {
			
			store.dispatch("errorhandling/addMessage", $t.t('$gt.msg.system.success.account.edited'));		  			
			dispatch("auth", r.data);		  
			return new Account(r.data)
		});
	},
	getAllAccounts({ commit }){
		VN_Service.indexAccounts()
		.then(r => {
			if( r.status === 200 ){
				commit("clearAccounts");
				commit("addAccounts",
					JSON.parse(JSON.stringify(r.data))
				);
			}
		})
		.catch(e => {
			
			
			
		})
		;
	},
	/* Create account */
	newAccount({ commit }, account){
		/* reformat account data */
		let api_formatted_account = {
			redirectUrl: window.location.origin + "/app/confirm",
			...account,
		};
		
		
		/* /reformat account data */
		VN_Service.createAccount(api_formatted_account)
		.then(r => {
			if(r !== undefined) {
				if( r.status === 200 ){
					
					commit("addAccount", r.data);
					store.dispatch(
						"errorhandling/addMessage",
						$t.t("$gt.msg.system.success.account.created")
					);
				}
			}
		})
		.catch(e => {
			store.dispatch(
				"errorhandling/addMessage",
				$t.t("$gt.msg.system.success.account.created")
			);
			
			
			
		});
	},
	/* Read account */
	/** update a single account in the state by its id **/
	getAccount({ commit }, account_id){
		return VN_Service.readAccount(account_id)
		.then(r => {
			if( r.status === 200 ){
				return new Account(r.data);
			}
		})
		.catch(e => {
			
			
			
		})
		;
	},

	/* Update (destructive edit) account */
	updateAccount({ commit }, account){
		VN_Service.updateAccount(account)
		.then(r => {
			if( r.status === 200 ){
				
				commit("replaceAccount", r.data);
				store.dispatch(
					"errorhandling/addMessage",
					$t.t("$gt.msg.system.success.account.edited")
				);
			}
		})
		.catch(e => {
			
			
			
		})
		;
	},
	activateAccount({ commit }, account){
		VN_Service.activateAccount( account )
		.then(r => {
			if( r.status === 200 ){
				
				commit("replaceAccount", r.data);
				store.dispatch(
					"errorhandling/addMessage",
					$t.t("$gt.msg.system.success.account.activated")
				);
			}
		})
		.catch(e => {
			
			
			
		})
		;
	},
	deactivateAccount({ commit }, account){
		VN_Service.deactivateAccount( account )
		.then(r => {
			if( r.status === 200 ){
				
				commit("replaceAccount", r.data);
				store.dispatch(
					"errorhandling/addMessage",
					$t.t("$gt.msg.system.success.account.deactivated")
				);
			}
		})
		.catch(e => {
			
			
			
		})
		;
	},
	updatePermission({ commit }, account){
		return VN_Service.updatePermissions(account)
			.then(r => {
				if( r.status === 200 ){
					
					commit("replaceAccount", r.data);
					store.dispatch(
						"errorhandling/addMessage",
						$t.t("$gt.msg.system.success.account.edited")
					);
					return r.data;
				}
			}).catch(e => {
				
				
				
			});
	},
	
	register({ commit }, credentials) {
		//  
		//void commit;
		return VN_Service.registerAccount(credentials).then(r => {
			
			if(r.status === 200) {
				router.push({ name: "registered" });
			} 
		});
	},
	cookie_login({ commit }, data){
		commit("SET_USER_DATA", data);
	},
    async login({ dispatch,commit }, credentials) {
        return await client.base
            .post('/accounts/login', credentials)
            .then((r) => {     			
				
				commit('SET_USER_DATA', r.data)
				router.push({ name: 'dashboard' }).catch(error => {
					console.info(error.message)
				 })	
				
            }).catch( error => {      
				return error.response;        
			  });
    },
	/* init ({ dispatch,commit }, data) {       // Could also be async and use await instead of return
		return Promise.all([
			dispatch('setDismantlerId'),
		])
	}, */
    logout ({ commit }) {
		client.base.get('/accounts/logout?cause=regularLogout')
        commit('LOGOUT')
    },
	autoLogout({commit}) {
		client.base.get('/accounts/logout?cause=autoLogout')
        commit('LOGOUT')
	},
	confirm({ commit }, credentials) {
		return client.base
		  .patch("/accounts/confirm", credentials)
		  .then(({ data }) => {			
			commit("SET_USER_DATA", data);
			const user = localStorage.getItem('current_user')
			if(user) {
			
			router.push({ name: "dashboard" });
			}
		  })
		  .catch( error  => {
			
		  });
	  },
	  //only for mandatorchanges
	  editOnlyMandator({ commit }, credentials) {
		  
		  return commit("SET_MANDATOR", {data : credentials, token: credentials.token});		
	  },
	  list_all({ commit }) {
		// void commit;
		VN_Service.listAllAccounts()
		  .then(response => {
			
			void commit;
			return response;
		  })
		  .catch(error => {
			
		  });
	  },
	  deactivate({ commit }, id) {
		//deaktiviert nur!!!
		void commit;
		return client.base.patch("/accounts/deactivate/" + id).then(response => {
		  store.dispatch(
			"errorhandling/addMessage",
			$t('$gt.msg.system.success.account.deactivated')
		  );
		  return response;
		});
	  },
	  activate({ commit }, id) {
		//aktiviert nur!!!
		void commit;
		return client.base.patch("/accounts/activate/" + id).then(response => {
		  store.dispatch(
			"errorhandling/addMessage",
			$t('$gt.msg.system.success.account.activated')
		  );
		  return response;
		});
	  },
	  delete({ commit }, id) {
		void commit;
		return client.base.delete("/accounts/" + id);
	  },
	  async auth({commit,dispatch}, userstorage) {				
		return await VN_Service.myAccount().then(async r => {				
			if( r.status === 200 ){										
				r.data.token = userstorage.token;				
				commit('SET_USER_DATA', r.data)
				if(process.env.VUE_APP_SSE_ENABLED === '1') {
					store.dispatch("sseModule/init");
				}
				return r.data
			}
			
		}).catch(({ error }) => {
			commit('LOGOUT')
			
		}); 
		
	  },
	  requestPwf({commit}, data) {
		  data.redirectUrl = window.location.origin + '/app/pwf/confirm'
		  return client.base.post('/accounts/pwf/request', data).then(r => {
			if(r.status === 200) {
				return true
			}
		  })
	  },
	  confirmNewPwf({commit}, data) {		  
		  return client.base.post('/accounts/pwf/confirm', data).then(r => {
			if(r.status === 200) {
				return true
			}
		  })
	  },
	  updateMyLanguage({commit}, account) {
		void commit;		
		return VN_Service.updateMyAccount(account).then(r => {			
			return new Account(r.data)
		});  
	  },
	  updateMyTheme({commit}, account) {
		void commit;		
		return VN_Service.updateMyAccount(account).then(r => {						
			return new Account(r.data)
		});
	  },
	  async init({commit}) {
		const user = await service.getUser();
		commit('setUser', user);
	  }
};

export const getters = {

	getAccountByID: state => id => {
		return state.accounts.find(d => d.id === id);
	},
	has_accounts(state) {
		/* check locally stored account count first */
		let stored_account_count = localStorage.getItem( "account_count" );
		if( stored_account_count === null || stored_account_count == 0 ){
			stored_account_count = state.accounts.length;
			localStorage.setItem( "account_count", stored_account_count);
		}
		return stored_account_count > 0;
	},
	loggedIn (state) {    
        return !!state.userData
    },
	loggedInUser(state){
		return state.userData;
    },
	dismantlerId(state) {
		return function () { 
			if( !!state.dismantlerId ){
			return state.dismantlerId
			}
		}
	},
	isAdmin(state) {
		if( !!state.userData ){
			return state.userData.admin;
		} else {
			return false;
		}
	}
	  
};
export const helper = {};
