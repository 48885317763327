import Utils from "@/utils.js";
import Rights from "@/store/classes/Rights.js";

export default class Account{
	constructor( account ){
		/* properties list */
		this.id = null;
		this.created = null;
		this.email = null;
		this.firstname = null;
		this.lastname = null;
		this.active = null;
		this.pending = null;
		this.admin = null;
		this.head = null;
		this.language = 'de';
		this.uitheme = null;
		this.mandator = null;
		this.mandators = [];
		this.visibleMandators = [];
		this.token = null;
		this.theirRights = new Rights();
		this.myRights = new Rights();
		this.maxRights = new Rights();

		if( !!account ){
			// 
			// 

			if(typeof(account.id)        !== "undefined") this.id        = account.id;
			if(typeof(account.created)   !== "undefined") this.created   = Utils.ISOToDateString( account.created );
			if(typeof(account.email)     !== "undefined") this.email     = account.email;
			if(typeof(account.firstname) !== "undefined") this.firstname = account.firstname;
			if(typeof(account.lastname)  !== "undefined") this.lastname  = account.lastname;
			if(typeof(account.active)    !== "undefined") this.active    = account.active;
			if(typeof(account.pending)   !== "undefined") this.pending   = account.pending;
			if(typeof(account.admin)     !== "undefined") this.admin     = account.admin;
			if(typeof(account.head)      !== "undefined") this.head      = account.head;
			if(typeof(account.language)      !== "undefined") this.language      = account.language;
			if(typeof(account.uitheme)      !== "undefined")this.uitheme      = account.uitheme ? account.uitheme : localStorage.getItem('appTheme');
			if(typeof(account.mandator)!== "undefined") this.mandator = account.mandator;
			if(typeof(account.mandators)!== "undefined") this.mandators = account.mandators;
			if(typeof(account.pending)   !== "undefined") this.granted   = account.granted;
			if(typeof(account.token)   !== "undefined") this.token   = account.token;
			if(typeof(account.visibleMandators)!== "undefined") this.visibleMandators = account.visibleMandators;
			this.myRights = new Rights(account.granted, this.admin);
			this.theirRights = new Rights(account.access, this.admin);
			this.maxRights = new Rights(account.grantable, this.admin);
		} else {
			// 

		}
	}
}
