import { client } from "@/services/client.js";
import store from "@/store/store.js";
import router from "@/router.js";
import axios from "axios";
import { createSecureContext } from "tls";
import { defaults } from "underscore";
import * as moment from "moment";

let signal = axios.CancelToken.source();
const areRequestsCanceled = err => {  
  return err && axios.isCancel(err);
};
const cancelRequests = () => {
  signal.cancel({ isCanceled: true, message: 'Requests canceled' });
  signal = axios.CancelToken.source();
};
function parseError(error) {
  
  let canceled = areRequestsCanceled(error)
  store.commit("appModule/loading", false);
  if(canceled) {
    /*  
    store.dispatch("accountsModule/logout"); 
    store.dispatch("errorhandling/addError", error.response);
    router.push({name: 'home'}) */
  } else if(error.response.status === 401) { 
    cancelRequests(error)
    if(store.getters["accountsModule/loggedInUser"]) {
      store.dispatch("accountsModule/logout"); 
    }    
    store.dispatch("errorhandling/addError", error.response);
    if(!error.response.data.hasOwnProperty('errnum')) {
      
      router.push({name: 'login'})
    } 
  } else if(error.response.data.errnum === 1101 && !store.getters["accountsModule/loggedInUser"]) {
    router.push({ name: "accexists" });
  } else if (error.response.status === 426){     
    store.commit("setOldVersion", true)
  } else if(error.response.status === 403) {  //  && !store.state.accountsModule.userData.admin
    cancelRequests(error) 
    store.dispatch("errorhandling/addError", error.response);
    if(!error.response.errnum === 555)
    router.push({ name: "dashboard" });
  } else if(!error.response || error.response.status === 502 || error.response.status === 500) {
    cancelRequests(error)
    const errorItem = {
      response: {
        'customerror': true,
        'data': {
          "message":"Network Error",
          'status': 1,
          'errnum': 1
        }
      }
    };    
    store.dispatch("errorhandling/addError", errorItem.response);   
  } else {
    store.dispatch("errorhandling/addError", error.response);  
  }
}

axios.defaults.params = {};

client.base.interceptors.request.use(request => {
  store.commit('appModule/loading', true);
  request.params = request.params || {}
  if(store.getters["accountsModule/loggedInUser"]) {
    var user = store.getters["accountsModule/loggedInUser"]
    var v = store.getters["getPipe"] 
    if(user.mandator) {
      request.params['m'] = user.mandator ? user.mandator.id : null
      //request.params = {...params, m: user.mandator ? user.mandator.id : null}      
    }        
    request.params['v'] = v
  }   
  request.cancelToken = signal.token;    
  return request;
},parseError);

client.base.interceptors.response.use(
	response => {
    store.commit("appModule/loading", false);
		return response;
	}, parseError
  /* error => {    // to-do : !store.state.accountsModule.userData.admin    
    
    cancelRequests
    if(!error.response || error.response.status === 502 || error.response.status === 500) {
      
      const errorItem = {
        response: {
          'customerror': true,
          'data': {
            "message":"Network Error",
            'status': 1,
            'errnum': 1
          }
        }
      };
      store.dispatch("errorhandling/addError", errorItem.response);      
    } else if(error.response.status === 401){      
      store.dispatch("accountsModule/logout");      
      store.dispatch("errorhandling/addError", error.response);
    } else if(error.response.status === 401) {       
      if(!error.response.data.hasOwnProperty('errnum')) {
        if(store.getters['accountsModule/loggedIn'] && !store.state.accountsModule.userData.admin) {
          error.response.data.errnum = 401;
          store.dispatch("accountsModule/logout"); 
          return Promise.reject(error);
        }        
      }
    } else if(error.response.status === 403 && !store.state.accountsModule.userData.admin) {      
      router.push({ name: "dashboard" });
      if(!error.response.data.hasOwnProperty('errnum')) {
        error.response.data.errnum = 403;
        store.dispatch("accountsModule/getmyAccount")
        store.dispatch("errorhandling/addError", error.response);
        //store.$app.$children[0].$children[0].$refs['sidebar'].$forceUpdate()
        return Promise.reject(error);
      }
    }
    store.commit("appModule/loading", false);       
		return Promise.reject(error);
	} */
);

client.clean.interceptors.response.use(
	response => {
		return response;
	},
	error => {
    
    if(!error.response) {
      const errorItem = {
        response: {
          'customerror': true,
          'data': {
            "message":"Network Error",
            'status': 1,
            'errnum': 1
          }
        }
      };
      store.dispatch("errorhandling/addError", errorItem.response);
    } else {
      if(error.response.status === 401){
        store.dispatch("accountsModule/logout");
      }
      store.dispatch("errorhandling/addError", error.response);
    }
		return Promise.reject(error);
	}
);

client.blob.interceptors.request.use(config => {
  store.commit('appModule/loading', true);    
  config.params = config.params || {}
  if(store.getters["accountsModule/loggedInUser"]) {
    var user = store.getters["accountsModule/loggedInUser"]
    var v = store.getters["getPipe"]   
    if(user.mandator) {
      config.params['m'] = user.mandator ? user.mandator.id : null
      //config.params = {...params, m: user.mandator ? user.mandator.id : null}      
    }        
    config.params['v'] = v
  }   
  return config;
});

client.blob.interceptors.response.use(
  response => {    
    store.commit('appModule/loading', false);
    return response;
  },
  error => {
    store.commit('appModule/loading', false);
    if(!error.response) {
      const error = {
        response: {
          'customerror': true,
          'data': {
            "message":"Network Error",
            'status': 1,
            'errnum': 1
          }
        }
      };
      store.dispatch("errorhandling/addError", error.response);
    } else {
      store.dispatch("errorhandling/addError", error.response);
    }
    return Promise.reject(error);
  }
);
const date = new Date(Date.now() + ( 3600 * 1000 * 24)) 
var startDate = new Date();
startDate = startDate.setFullYear(startDate.getFullYear() - 1);

const defaultdates = {
    from: moment(startDate).format("YYYY-MM-DD"),
    to:  moment(date).format("YYYY-MM-DD")
};
export default {
 
  /* DESTRUCT (DELETE VARIANT) = recursive deletion of ressource */
  /* ICRUMD-PATTERN */

  getWasteTypeForCoSavings(dismantlerId) {
    return client.base.get('/cars/weight/of/' + dismantlerId + '/2020-01-01/' + defaultdates.to);
  },

  /* accountsModule */
  indexAccounts() {
    return client.base.get("/accounts");
  },
  createAccount(account) {
    return client.base.put("/accounts", account);
  },
  registerAccount(credentials) {
    return client.base.put("/accounts", credentials)
  },
  readAccount(id) {
    return client.base.get("/accounts/" + id);
  },
  updateAccount(account) {
    return client.base.put("/accounts/" + account.id, account);
  },
  myAccount() {
    return client.base.get("/accounts/my");
  },
  updateMyAccount(data) {
    return client.base.patch("/accounts", data)
  },
  resendConfirmEmail(accountIdorEmail) {
    return client.base.get("/accounts/confirm/resend/?url=" + window.location.origin + "/app/confirm"  + "&id=" + accountIdorEmail )
  },
  unlockReception(data) {
    return axios(process.env.VUE_APP_WEBCORE_API + '/mollie-payment' , {
        method: 'POST', 
        data: data,
        withCredentials: true,
        credentials: 'same-origin',
      })
  },
  updateCount(data) {
    return axios(process.env.VUE_APP_WEBCORE_API + '/updatecount' , {
        method: 'POST', 
        data: data,
        withCredentials: true,
        credentials: 'same-origin',
      })
  },
  unlockBtb(data) {
    return axios(process.env.VUE_APP_WEBCORE_API + '/subscribe' , {
        method: 'POST', 
        data: data,
        withCredentials: true,
        credentials: 'same-origin',
      })
  },
  activateAccount(account) {
    return client.base.patch("/accounts/activate/" + account.id);
  },
  deactivateAccount(account) {
    return client.base.patch("/accounts/deactivate/" + account.id);
  },
  
  updatePermissions(account) {
    return client.base.patch("/accounts/permissions/" + account.id, account.myRights.rights, {headers: {"Content-Type": "text/plain"}});
  },
  
  getSystemMessage() {
    return client.base.get("/sys/alerts");
  },
  getSystemInfo() {
    return client.base.get("/sys/env");
  },
  /* /accountsModule */

  /* RECEPTIONS */
  indexReceptions(dates) {
    if(dates) {
      return client.base.get("/receptions/"+ dates.from + '/' + dates.to);
    } else {
      return client.base.get("/receptions/"+ defaultdates.from + '/' + defaultdates.to); 
    }    
  },
  getReceptionByCar(carId) {
    return client.base.get('/receptions/id/of/car/' + carId)
  },
  
  createReception(reception) {
    /* TODO: change verb to POST - first serverside then here */
    return client.base.put("/receptions", reception);
  },
  updateReception(reception) {
    /* TODO: change verb to PUT - first serverside then here */
    return client.base.put("/receptions/" + reception.id, reception);
  },
  lockReception(reception_id) {
    return client.base.patch("/receptions/lock/" + reception_id);
  },
  unlockReception(reception_id) {
    return client.base.patch("/receptions/unlock/" + reception_id);
  },
  readReception(reception_id) {
    return client.base.get("/receptions/" + reception_id);
  },
  transferCarToBtb(carId) {
    return client.base.patch('/cars/transfer/btb/' + carId);
  },
  transferCarsToBtb(cars) {
    return client.base.patch('/cars/transfer/btb/', cars);
  },
  /* /RECEPTIONS */

  /* COLLECTORS */
  indexCollectors() {
    return client.base.get("/collectors");
  },
  getExternizableCollectors() {
    return client.base.get("/collectors/outsourceable");
  },
  getAssignableCollectors() {
    return client.base.get("/collectors/assignable");
  },
  getCollector(id) {
    return client.base.get("/collectors/" + id);
  },
  createCollector(collector) {
    /* TODO: change verb to POST - first serverside then here */
    return client.base.put("/collectors", collector);
  },
  updateCollector(collector) {
    /* TODO: change verb to PUT - first serverside then here */
    return client.base.put("/collectors/" + collector.id, collector);
  },
  outSourceCollector(collector_id, account_id) {
    return client.base.patch(
      "/collectors/chown/" + collector_id + "/" + account_id
    );
  },
  /* /COLLECTORS */
  /* Cargos */

  getAllCargos(looperId) {
    return client.base.get("/cargos/of/" + looperId)
  },
  getCargoById(cargoId) {
    return client.base.get("/cargos/" + cargoId)
  },
  createCargo(cargo) {
    return client.base.put("/cargos", cargo)
  },
  updateCargo(cargo) {
    return client.base.put("/cargos", cargo)
  },
  deleteCargoById(cargoId) {
    return client.base.delete("/cargos/" + cargoId)
  },
  getCargoTypes() {
    return client.base.get("/cargos/types")
  },  
  getOffersForCargoById(offerId) {
    return client.base.get("/cargos/offers/" + offerId)
  },  
  /* Cargos */
  /* Orders */
  getPickupOrdersOfLooper(looperId) {
    return client.base.get("/pickuporders/of/" + looperId)
  }, 
  getPickupOrderById(orderId) {
    return client.base.get("/pickuporders/" + orderId)
  }, 
  deletePickupOrderById(orderId) {
    return client.base.delete("/pickuporders/" + orderId)
  }, 
  createPickupOrder({cargoId,offerId}) {
    return client.base.put("/pickuporders/" + cargoId + '/' + offerId)
  }, 
  createPickupOrderRequest(request) {
    return client.base.put("/pickuporders/request", request)
  }, 
  updatePickupOrder(order) {
    return client.base.patch("/pickuporders" , order)
  }, 
  executePickupOrderById(orderId) {
    return client.base.put("/pickuporders/execute/"+ orderId)
  }, 
  /* Recycler Tours */

  getAllRecyclerOffers(recyclerId) {
    return client.base.get("/recycleroffers/of/" + recyclerId)
  },
  getRecyclerOfferById(offerId) {
    return client.base.get("/recycleroffers/" + offerId)
  },
  deleteRecyclerOfferById(offerId) {
    return client.base.delete("/recycleroffers/" + offerId)
  },
  createRecyclerOffer(recOffer) {
    return client.base.put("/recycleroffers", recOffer)
  },
  updateRecyclerOffer(recOffer) {
    return client.base.put("/recycleroffers", recOffer)
  },
  getCargosGeoById(cargoTypeId) {
    return client.base.get("cargos/geo/" + cargoTypeId)
  },

  /* Cargos */
  /* Departments */
  getAllRecyclers() {
    return client.base.get("/recyclers");
  },
  getRecycler(id) {
    return client.base.get("/recyclers/" + id);
  },
  getAllLoopers() {
    return client.base.get("/loopers");
  },
  getLooper(id) {
    return client.base.get("/loopers/" + id);
  },
  getReman(id) {
    return client.base.get("/reman/" + id);
  },
  createReman(reman) {
    return client.base.put("/reman", reman);
  },
  updateReman(reman) {
    return client.base.put("/reman/" + reman.id , reman);
  },
  saveUpdateDismantler(dismantler) {
    return client.base.put("/dismantlers", dismantler);
  },
  createRecycler(recycler) {
    return client.base.put("/recyclers", recycler);
  },
  updateRecycler(recycler) {
    return client.base.put("/recyclers/" + recycler.id , recycler);
  },
  createLooper(looper) {
    return client.base.put("/loopers", looper);
  },
  updateLooper(looper) {
    return client.base.put("/loopers/" + looper.id, looper);
  },

  /* Departments */
  
  /* DISMANTLERS */
  indexDismantlers() {
    return client.base.get("/dismantlers");
  },
  createDismantler(dismantler) {
    /* TODO: change verb to POST - first serverside then here */
    return client.base.put("/dismantlers", dismantler);
  },
  updateDismantler(dismantler) {
    /* TODO: change verb to PUT - first serverside then here */
    return client.base.put("/dismantlers/" + dismantler.id, dismantler);
  },
  getDismantlersForCurrentCollector() {
    return client.base.get("/dismantlers/assignable");
  },
  getDismantler(id) {
    return client.base.get("/dismantlers/" + id);
  },
  updateDismantlerVnr(vnr) {
    
    return client.base.patch("/dismantlers/vnr/" + vnr.dismantlerId, vnr);
  },
  dismantlersExist() {
    return client.base.get("dismantlers/allowed");
  },

  /* /DISMANTLERS */

  /* CERTIFICATES */
  updateCertificate(certificate) {
    /* TODO: change verb to PUT - first serverside then here */
    return client.base.patch("/certificates", certificate);
  },
  getCertificate(certificate) {
    /* TODO: change verb to PUT - first serverside then here */
    return client.base.get("/certificates/doc/" + certificate, {responseType: 'blob'});
  },
  uploadCertificate(certId, form) {
    return client.base.post("/certificates/doc/" + certId, form);
  },
  uploadTest(form) {
    return client.base.post("/btb/staff/testupload", form);
  },
  uploadTestCw(form) {
    return client.base.post("/btb/staff/testuploadCw", form);
  },
  uploadTestObject(form) {
    return client.base.post("/btb/staff/testuploadObject", form)
  },
  /* /CERTIFICATES */

  /* CarsKBA */
  readCarByHSN(hsn) {
    return client.clean.get("/kba/hsn/" + hsn);
  },
  readCarByHSNTSN(hsn, tsn) {
    return client.clean.get("/kba/hsntsn/" + hsn + "/" + tsn);
  },
  getCarByKba(kba) {
    return client.clean.get("/kba/"+kba);
  },
  /* /CARSearch */

  getMakers() {
    return client.base.get("/kba/ebay/makes")
  },
  getModelsByMaker(makerId) {
    return client.base.get("/kba/ebay/models/" + makerId)
  },
  getTypesByModel(modelId) {
    return client.base.get("/kba/ebay/types/" + modelId)
  },

  /* Purchaser */

  getTopsellerbyKBA(kba) {
    return client.base.get("/purchaser/topseller/price/kba/" + kba)
  },
  getTopsellerbyKBACount(kba) {
    return client.base.get("/purchaser/topseller/count/kba/" + kba)
  },
  getTopseller() {
    return client.base.get("/purchaser/topseller/price")
  },
  getTopsellerbyCount() {
    return client.base.get("/purchaser/topseller/count")
  },
  getPartsByIds(data) {
    return client.base.get("/purchaser/parts/" + data.kba + '/' + data.ids)
  },
  getMaterials() {
    return client.base.get("/purchaser/materials")
  },

  /* EXPERTS */
  indexExperts() {
    return client.base.get("/certifiers");
  },
  /* /EXPERTS */

  /* Units */
  getAllUnits() {
    return client.base.get("/units");
  },
  getUnit(type) {
    return client.base.get("/units/" + type);
  },
  
  /*Carriers */
  indexCarriers() {
    return client.base.get("/btb/carrier");     
  },
  getCarrier(carrierId) {
    return client.base.get("/btb/carrier/" + carrierId); 
  },
  createCarrier(carrier) {
    /* TODO: change verb to POST - first serverside then here */
    return client.base.put("/btb/carrier", carrier);
  },
  updateCarrier(carrier) {
    /* TODO: change verb to PUT - first serverside then here */
    return client.base.put("/btb/carrier/" + carrier.id, carrier);
  },

  /*Persons */

  indexKeepersFav() {
    return client.base.get('/persons/hotlist')
  },

  /*Disposers */
  indexDisposers() {
    return client.base.get("/btb/disposers/assignable");     
  },
  getMyDisposers() {
    return client.base.get("/btb/disposers/assigned"); 
  },
  getMyDisposer(disposerId) {
    return client.base.get("/btb/disposers/my/" + disposerId); 
  },
  createMyDisposer(disposer) {    
    /* TODO: change verb to POST - first serverside then here */
    return client.base.put("/btb/disposers", disposer);
  },
  updateMyDisposer(disposer) {    
    /* TODO: change verb to PUT - first serverside then here */
    return client.base.put("/btb/disposers/", disposer);
  },
  getAllMyDisposers() {    
    /* TODO: change verb to PUT - first serverside then here */
    return client.base.get("/btb/disposers");
  },
  
  /* CARS */
  saveUpdateCar(car) {    
    return client.base.put("/cars/of/" + car.dismantlerId, car);
  },
  getCarById(id) {
    return client.base.get('/cars/' + id)
  },
  getCarSteps() {
    return client.base.get('/cars/steps');
  },
  updateCarStep(car) {
    var options = {headers: { 'Content-Type': 'application/json' }}
    return client.base.patch('/cars/steps/'+ car.id, 
      car.step, options);
  },
  getAllCars(data) {
    if(data.dates) {
      return client.base.get('/cars/of/' + data.dismantlerId + '/' + data.dates.from + '/' + data.dates.to);
    } else {
      return client.base.get('/cars/of/' + data.dismantlerId + '/' + defaultdates.from + '/' + defaultdates.to); 
    }  
  },
  getCar(carId) {
    return client.base.get('/cars/' + carId);
  },
  prefillBtb(car) {
    return client.base.get('/btb/entries/in/prefill/'+ car.id)
  },

  /* BTB Entry  */
  getBtbCars(data) {
    if(data.dates) {
      return client.base.get('/cars/btb/of/' + data.dismantlerId + '/' + data.dates.from + '/' + data.dates.to);
    } else {
      return client.base.get('/cars/btb/of/' + data.dismantlerId + '/' + defaultdates.from + '/' + defaultdates.to); 
    }  
  },
  getBtbEntries(dismantlerId) {
    return client.base.get('/btb/entries/in/' + dismantlerId);
  },  
  saveBtBEntries(data) {
    return client.base.put('/btb/entries/car/' + data.carId, data.dismantled)
  },
  saveUpdateBtbEntries(entry) {
    return client.base.put('/btb/entries/in', entry);
  },
  getEntriesByCar(carId) {
    return client.base.get('/btb/entries/car/' + carId);
  },

  /*  BTB Outgoing */
  getBtbOutputs(dismantlerId) {
    return client.base.get('/btb/entries/out/' + dismantlerId );
  },
  /* getBtbOutputList(dismantlerId) {
    return client.base.get('/btb/statistics/stock/' + dismantlerId + '/2020-01-01/2021-12-13');
  }, */
  saveUpdateBtbOutputs(output) {
    return client.base.put('/btb/entries/out', output);
  },
  getDisposalProcessById(id) {
    return client.base.get('/btb/disposalprocess/' + id);
  },
  getBtbOutputList(dismantlerId) {
    return client.base.get('/btb/wastetypes/used/by/' + dismantlerId + '/2020-01-01/' + defaultdates.to) // need to be changed
  },
  getBtbOutputDetails(dismantlerId, wastetype) {
    return client.base.get('/btb/statistics/stock/' + dismantlerId + '/' + wastetype +'/2020-01-01/' + defaultdates.to) // need to be changed
  },
  /* SpareSells */
	getSpares() {
    return client.base.get('/btb/spares');
  },
	getSpareOutLists(dismantlerId) {
    return client.base.get('/btb/spares/of/'+ dismantlerId);
  },
  getSpareOutListById(spareOutList) {
    return client.base.get('/btb/spares/of/'+ spareOutList.dismantlerId + '/' + spareOutList.id);
  },
  getSpareOuts(dismantlerId) {
    return client.base.get('/btb/spares/outs/of/'+ dismantlerId);
  },
  saveUpdateSpareOutList(spareOutList) {
    return client.base.put('/btb/spares/of/'+ spareOutList.dismantlerId, spareOutList);
  },
  /* Disposals */
  getDisposalsOf(dismantlerId) {
    return client.base.get('/btb/disposal/of/'+ dismantlerId)
  },
  getDisposalById(disposal) {
    return client.base.get('/btb/disposal/of/' + disposal.dismantlerId + '/' + disposal.id)
  },  
  saveUpdateDisposal(disposal) {
    return client.base.put('/btb/disposal/of/' + disposal.dismantlerId, disposal);
  },
  getDisposables(dismantlerId) {
    return client.base.get('/btb/entries/in/disposables/'+ dismantlerId)
  },
  getDisposalOuts(outId) {
    return client.base.get('/btb/entries/in/out/' + outId);
  },
  deleteDisposal(disposalId) {
    return client.base.delete('/btb/disposals/' + disposalId);
  },
  /* Incidents */
  getIncidentsOf(dismantlerId) {
    return client.base.get('btb/incidents/of/' + dismantlerId);
  },
  saveUpdateIncident(dismantlerId, incident) {
    return client.base.put('btb/incidents/of/' + dismantlerId, incident);
  },
  /*  WasteTypes & Prefilles */
  getWasteTypeByFraction(fractionId) {
    return client.base.get('/btb/wastetypes/of/' + fractionId)
  },
  getWasteTypesTree() {
    return client.base.get('/btb/wastetypes')
  },
  getPrefillTypes() {
    return client.base.get('btb/prefilltypes')
  },

  /* Stock & Statistics & Statements */
  getStatements(dismantlerId) {
    return client.base.get('/btb/statement/of/' + dismantlerId)
  },
  getLatestEffectiveTill(dismantlerId) {
    return client.base.get('/btb/statement/latest/of/' + dismantlerId)
  },
  getStatementEntries(statementId) {
    return client.base.get('/btb/statement/entries/of/' + statementId)
  },
  createUpdateStatement(statement) {
    return client.base.put('/btb/statement/of/' + statement.dismantlerId, statement)
  },
  lockStatement(statement) {
    return client.base.patch('/btb/statement/lock/' + statement.id + '/of/' + statement.dismantlerId)
  },
  unlockStatement(statement) {
    return client.base.patch('/btb/statement/unlock/' + statement.id + '/of/' + statement.dismantlerId)
  },
  getStatisticStock(dismantlerId,enddate) {
    return client.base.get('/btb/statistics/stock/' + dismantlerId + '/' + enddate)
  },
  getFractionStock(dismantlerId, enddate) {
    return client.base.get('/btb/statistics/balance/' + dismantlerId + '/' + enddate)
  },
  getDisposalBalance(dismantlerId,startdate,enddate) {
    return client.base.get('/btb/statistics/recycling/' + dismantlerId + '/' + startdate + '/' + enddate)
  },

  /* Qualifications */
  getQualifications() {
    return client.base.get('/btb/qualificationTypes');
  },
  getQualificationCert(id) {
    return client.base.post('/btb/staff/qualification/doc/' + id)
  },
  getQualificationOfWasteType() {
    return client.base.get('btb/wastetypes/qualifications');
  },
  /* Employees */
  indexEmployees(dismantlerId) {
    return client.base.get('/btb/staff/' + dismantlerId);
  },
  newEmployee(dismantlerId,employee) {
    return client.base.put("/btb/staff/" + dismantlerId , employee)
  },
  updateEmployee(dismantlerId,employee) {
    return client.base.put("/btb/staff/" + dismantlerId , employee)
  },

  /* Exports */

  exportCars(data) {
    return client.base.get('/cars/'+data.format+'/'+data.dismantlerId+'/'+data.from+'/'+data.to)
  }
  /* Remanufacturer */
  

};
