import Certificate from "@/store/classes/Certificate.js";

export default class Company{
	constructor( company ){
		/* properties list */
		this.name = null;
		this.street = null;
		this.hsnr = null;
		this.country = null;
		this.zip = null;
		this.city = null;
		this.tel = null;
		this.fax = null;
		this.mobile = null;
		this.email = null;
		this.web = null;
		this.dmark = null;
		this.bankname = null;
		this.iban = null;
		this.bic = null;
		this.lat = 51.165691; 
		this.lon = 10.451526;
		this.distance = 200000
		this.taxnumber = null;
    	this.registrationnumber = null;
		/* if given data from API, assign it into field values */
		if( !!company ){
			// 
			// 

			if(typeof(company.name)        !== "undefined") this.name        = company.name;
			if(typeof(company.street)      !== "undefined") this.street      = company.street;
			if(typeof(company.hsnr)        !== "undefined") this.hsnr        = company.hsnr;
			if(typeof(company.country)     !== "undefined") this.country     = company.country;
			if(typeof(company.zip)         !== "undefined") this.zip         = company.zip;
			if(typeof(company.city)        !== "undefined") this.city        = company.city;
			if(typeof(company.tel)         !== "undefined") this.tel         = company.tel;
			if(typeof(company.fax)         !== "undefined") this.fax         = company.fax;
			if(typeof(company.mobile)         !== "undefined") this.mobile         = company.mobile;
			if(typeof(company.email)       !== "undefined") this.email       = company.email;
			if(typeof(company.web)         !== "undefined") this.web         = company.web;
			if(typeof(company.bankname)    !== "undefined") this.bankname    = company.bankname;
			if(typeof(company.iban)        !== "undefined") this.iban        = company.iban;
			if(typeof(company.bic)         !== "undefined") this.bic         = company.bic;
			if(typeof(company.lat)         !== "undefined") this.lat         = company.lat;
			if(typeof(company.lon)         !== "undefined") this.lon         = company.lon;
			this.distance         = 200000;
			if(typeof(company.dmark)	   !== "undefined")	this.dmark= company.dmark;			
			if (typeof company.taxnumber   !== "undefined") this.taxnumber = company.taxnumber;
		 	if (typeof company.registrationnumber !== "undefined")	this.registrationnumber = company.registrationnumber;

		} else {
			// 

		}
	}
}
