import Utils from "@/utils.js";

export default class Reception {
  constructor(reception) {
    this.identnr = null;
    this.date = null;
    this.receptiondate = new Date().toISOString().substr(0, 10);
    this.locked = null;
    this.dismantling = false
    this.sornc = null;
    this.sornd = null;
    this.refnr = null;
    this.printstamp = null;
    this.printdate = null;

    if (!!reception) {
      
      
      if (typeof reception.date !== "undefined")
        this.date = Utils.ISOToDateString(reception.date);
      if (typeof reception.receptiondate !== "undefined")
        this.receptiondate = Utils.ISOToDateString(reception.receptiondate);
      if (typeof reception.identnr !== "undefined")
        this.identnr = reception.identnr;
      if (typeof reception.locked !== "undefined")
        this.locked = reception.locked;
      if (typeof reception.dismantling !== "undefined")
        this.dismantling = reception.dismantling;
      if (typeof reception.sornc !== "undefined") this.sornc = reception.sornc;
      if (typeof reception.sornd !== "undefined") this.sornd = reception.sornd;
      if (typeof reception.refnr !== "undefined") this.refnr = reception.refnr;
      if (typeof reception.printstamp !== "undefined")
        this.printstamp = reception.printstamp;
      if (typeof reception.printdate !== "undefined")
        this.printdate = reception.printdate;
    } else {
      
    }
  }
}
