import VN_Service from "@/services/VN_Services.js";
import store from "@/store/store";
import i18n from "@/i18n";
import { Config } from "@/services/client.js";
import Utils from "@/utils.js";

const $t = i18n();
export const namespaced = true;

export const state = {
    StateName: []
}

export const mutations = {
    
}

export const actions = {
    downloadSpareList({commit}) {
        Utils.downloadFile(
            Config.baseURL + Config.sparelist,
            store.state.accountsModule.userData.token,
            '_blank'
        );
    },
    downloadDemontageList({ commit }, receptionId) {
      Utils.downloadFile(
        Config.baseURL + Config.disorders + receptionId,
        store.state.accountsModule.userData.token,
        '_blank'
      );
      //return VN_Service.downloadRemovalList(receptionId)    
    },
    downloadCoD({commit},receptionId) {
        Utils.downloadFile(
            Config.baseURL + Config.upload + receptionId,
            store.state.accountsModule.userData.token
          );
    }
}

export const getters = {
    
}