import Unit from "@/store/classes/Unit.js";

export default class UnitCarColor extends Unit {
  constructor(unit) {
    super(unit);
    this.code = null;
    if (!!unit) {
      if (typeof unit.code !== "undefined") this.code = unit.code;
    } else {
    }
  }
}
