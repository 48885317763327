import VN_Service from "@/services/VN_Services.js";
import store from "@/store/store";
import i18n from "@/i18n";
import Quote from "@/store/classes/Quote"; 
import Statement from "@/store/classes/Statement"; 

const $t = i18n();

export const namespaced = true;

export const state = {
    statisticStock: [],
    fractionStock: [],
    statements: [],
    lastEffectiveTill: null // TODO: Set by API CALL 
}
const isLastStatement = function(state,item) {
    if(item.id !== null) {
        var last = state.statements[state.statements.length -1];
        if(last.id === item.id) {                                             
            return true
        } else {
            return false
        }

    }
}
export const mutations = {    

    addStatisticStock(state, data) {     
        data.forEach(ol => {state.statisticStock.push(ol)})
        
    },
    clearStatisticStock(state) {
        state.statisticStock = [];
    },
    addfractionStock(state, data) {     
        data.forEach(ol => {state.fractionStock.push(ol)})
        
    },
    clearfractionStock(state) {
        state.fractionStock = [];
    },
    addStatements(state, data) {     
        data.forEach(ol => {
            
            state.statements.push(new Statement(ol))
        })
        state.statements = state.statements.filter(
            (r) => {r.isLast = isLastStatement(state,r)
                return r
            }    
        );  
    },
    clearStatements(state) {
        state.statements = [];
    },
    addStatement(state,data) {
        
        
        //data.isLast = isLastStatement(state,data)
        state.statements.push(new Statement(data))
        state.statements = state.statements.filter(
            (r) => {r.isLast = isLastStatement(state,r)
            return r}    
        );
    },
    updateStatement(state, data){         
        
        data.isLast = isLastStatement(state,data)
        let newStatement = state.statements.find(
            r => r.id === data.id      
        );
        Object.assign( newStatement, new Statement(data));       
        state.statements = state.statements.filter(
            (r) => {r.isLast = isLastStatement(state,r)
            return r}    
        );
    },    
    clearLatestEffectiveTill(state) {
        state.lastEffectiveTill = null;
    },
    addLatestEffectiveTill(state,data) {

        if(new Date(data).toLocaleDateString('en-CA') === '1970-01-01') {            
            state.lastEffectiveTill = null
        } else {
            var date = new Date(data)
            state.lastEffectiveTill = new Date(date.getTime() + ( 3600 * 1000 * 24) ).toLocaleDateString('en-CA')        
        }        
    },
}

export const actions = {
    getLatestEffectiveTill({commit}, dismantlerId) {
        return VN_Service.getLatestEffectiveTill(dismantlerId).then(r => {
            if(r.status === 200) {
                commit("clearLatestEffectiveTill")
                commit("addLatestEffectiveTill", r.data)
                return r.data                
            }
        })
    },
    getStatements({commit}, dismantlerId) {
        return VN_Service.getStatements(dismantlerId).then(r => {
            if(r.status === 200) {
                commit("clearStatements")
                commit("addStatements", r.data)
                return r.data                
            }
        })
    },
    getStatementEntries({commit}, statementId) {
        return VN_Service.getStatementEntries(statementId).then(r => {
            if(r.status === 200) {
                return r.data                
            }
        })
    },
    createUpdateStatement({commit}, statement) {
        return VN_Service.createUpdateStatement(statement).then(r => {
            if(r.status === 200) {
                if(statement.id === null) {
                    commit("addStatement", r.data)
                    store.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.statement.created")
                    );
                } else {
                    commit("updateStatement", r.data)
                    store.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.statement.edited")
                    );
                }
                return new Statement(r.data)
            }
        })
    },
    lockStatement({commit}, statement) {
        return VN_Service.lockStatement(statement).then(r => {
            if(r.status === 200) {                
                commit("updateStatement", r.data)       
                store.dispatch(
                    "errorhandling/addMessage",
                    $t.t("$gt.msg.system.success.statement.locked")
                );         
                return new Statement(r.data)
            }
        })
    },
    unlockStatement({commit}, statement) {
        return VN_Service.unlockStatement(statement).then(r => {
            if(r.status === 200) {                
                commit("updateStatement", r.data)     
                store.dispatch(
                    "errorhandling/addMessage",
                    $t.t("$gt.msg.system.success.statement.unlocked")
                );           
                return new Statement(r.data)
            }
        })
    },
    getStatisticStock({commit}, {dismantlerId, enddate}) {
        return VN_Service.getStatisticStock(dismantlerId,enddate).then(r => {
            if(r.status === 200) {
                commit("clearStatisticStock")
                commit("addStatisticStock", r.data)
                return r.data
            }
        })
    },
    getFractionStock({commit}, {dismantlerId, enddate}) {
        return VN_Service.getFractionStock(dismantlerId,enddate).then(r => {
            if(r.status === 200) {
                commit("clearfractionStock")
                commit("addfractionStock", r.data)
                return r.data
            }
        })
    },
    getDisposalBalance({commit}, {dismantlerId,startdate,enddate}) {        
        return VN_Service.getDisposalBalance(dismantlerId,startdate,enddate).then(r => {
            if(r.status === 200) {
                var data = [];
                r.data.forEach(item =>{ data.push(new Quote(item))})
                
                return data
            }
        })
    }
}