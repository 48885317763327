export default class WastetypesTree {
    constructor(wastetypes) {
        this.id = null,
        this.name = null,
        this.children = []
        
        if(!!wastetypes) {
            if (typeof wastetypes.id !== "undefined")
            this.id = wastetypes.id;
            if (typeof wastetypes.name !== "undefined")
            this.name = wastetypes.name;
            if (typeof wastetypes.children !== "undefined")
            this.children = wastetypes.children;
        }
    }

}