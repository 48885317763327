export default {
  // called by Vue.use(FirstPlugin)
  install(Vue, options) {
    // merge default options with arg options
    Vue.prototype.isMobile = () => {     
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    } 

  }
};
