export default class ReceptionStatus{
	constructor( status ){
		/* define base values */
		this.collectorCertifierOk = false;
		this.dismantlerCertifierOk = false;
		this.dismantlerCertificateDocOk = false;

		if( !!status ){
            //status = 0, "", null, undefined | !! null, undefined
			
			

			if(typeof(status.collectorCertifierOk)       !== "undefined")  this.collectorCertifierOk  = status.collectorCertifierOk;
            if(typeof(status.dismantlerCertifierOk)      !== "undefined")  this.dismantlerCertifierOk = status.dismantlerCertifierOk;
			if(typeof(status.dismantlerCertificateDocOk) !== "undefined")  this.dismantlerCertificateDocOk= status.dismantlerCertificateDocOk;
		} else {
			
		}
	};
}