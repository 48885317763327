import Person from "@/store/classes/Person.js";
import Reception from "@/store/classes/Reception.js";
import Car from "@/store/classes/Car.js";
import ReceptionStatus from "@/store/classes/composite/ReceptionStatus.js";
import Rights from "@/store/classes/Rights.js";

import Utils from "@/utils.js";
export default class ReceptionFormComposite {
  constructor(reception) {
    /* define base values */
    this.id = null;
    this.collectorId = null;
    this.company = null;
    this.vn = new Reception();
    this.car = new Car();
    this.keeper = new Person();
    this.status = new ReceptionStatus();
    this.theirRights = new Rights();

    if (!!reception) {
      
      

      if (typeof reception.id !== "undefined") this.id = reception.id;
      if (typeof reception.collectorId !== "undefined")
        this.collectorId = reception.collectorId;
      if (typeof reception.company !== "undefined")
        this.company = reception.company;
      if (typeof reception.vn !== "undefined")
        this.vn = new Reception(reception.vn);
      if (typeof reception.keeper !== "undefined")
        this.keeper = new Person(reception.keeper);
      if (typeof reception.car !== "undefined")
        this.car = new Car(reception.car);
      if (typeof reception.status !== "undefined")
        this.status = new ReceptionStatus(reception.status);
      this.theirRights = new Rights(!reception.access ? "FFFF" :reception.access);
    } else {
      
    }
  }
}
