import Utils from "@/utils.js";
import Disposer from "@/store/classes/Disposer.js";
import MyDisposer from "@/store/classes/MyDisposer.js";
import Rights from "@/store/classes/Rights.js";
export default class MyDisposerFormComposite {
  constructor(disposer) {
    /* define base values */
    this.id = null;
    this.disposerId = null;
    this.myDisposer = new MyDisposer();
    this.disposer = new Disposer();   
    this.theirRights = new Rights();

    if (!!disposer) {

      if (typeof disposer.id !== "undefined")      this.id      = disposer.id;
      if (typeof disposer.disposerId !== "undefined") this.disposerId = disposer.disposerId;
      if (typeof disposer.myDisposer !== "undefined")  this.myDisposer  = new MyDisposer(disposer.myDisposer);
      if (typeof disposer.disposer !== "undefined")    this.disposer    =  new Disposer(disposer.disposer);

      this.theirRights = new Rights(!disposer.access ? "FFFF" : disposer.access);
      
    } else {
      
    }
  }
}
