import VN_Service from "@/services/VN_Services.js";
//import Disposer from "@/store/classes/Disposer.js";
import Person from "@/store/classes/Person.js";
import Contact from "@/store/classes/Contact.js";
import store from "@/store/store";
import i18n from '@/i18n'
import { client } from "@/services/client.js";
const $t = i18n();

export const namespaced = true;

export const state = {
    contacts: [],
    keeperfav: []
};

export const mutations = {
    clearKeepers(state){
		state.keeperfav = [];
    },
    addKeepers(state, keeperfav_data){
        keeperfav_data.forEach(c => {state.keeperfav.push(new Contact(c))})
    },
    updateKeeperFav(state, keeper_data){
        state.keeperfav.push(new Contact(keeper_data))
    },
    mutate(state, payload) { // Test
        state[payload.property] = payload.with;        
    }
};

export const actions = {
    getAllKeeperFav({ commit}) {
        return VN_Service.indexKeepersFav()
        .then(r => {
            if( r.status === 200 ){
                commit("clearKeepers");
                commit("addKeepers", JSON.parse(JSON.stringify(r.data)) );
            }
        })
        .catch(e => {
            
            
        });
    },


    async fetchData({ state, commit }, payload) {        
        try {
            let response = {};
            if(payload.id){
                response = await client.base.get(
                    `${payload.url}` + '/' + payload.id              
                );
            } else {
                response = await client.base.get(
                    `${payload.url}`                
                ); 
            }
            
            if (response.status === 200) {
                commit('mutate', {
                    property: payload.stateProperty,
                    with: response.data
                });
            }
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}