const themes = [
	{
		name: "Default",
		dark: {
			primary: "#337AB7",
			secondary: "#424242",
			accent: "#82B1FF",
			success: "#4caf50",
			info: "#2196F3",
			warning: "#F39C12",
			error: "#eb4d4b",
			"colorsets-main-bg-primary": "#efeff9",// blueish white
			"colorsets-main-bg-quantary": "#ddd",
			"colorsets-main-bg-quinary": "#e4e5e6",
			"colorsets-main-bg": "#fff",
			"colorsets-main-font-color": "#333",
			"colorsets-main-button-color": "#eb4d4b", // ecar-red
			mainheader : "#337ab7",
			"colorsets-main-table-bg" : "#bbdefb",
			"whitey": "#eee"
		},
		light: {
			primary: "#337AB7",
			secondary: "#424242",
			accent: "#82B1FF",
			success: "#4caf50",
			info: "#2196F3",
			warning: "#F39C12",
			error: "#eb4d4b",
			"colorsets-main-bg-primary": "#efeff9",// blueish white
			"colorsets-main-bg-quantary": "#ddd",
			"colorsets-main-bg-quinary": "#e4e5e6",
			"colorsets-main-bg": "#fff",
			"colorsets-main-font-color": "#333",
			"colorsets-main-button-color": "#eb4d4b", // ecar-red
			mainheader : "#337ab7",
			"colorsets-main-table-bg" : "#bbdefb",
			"whitey": "#eee"
		}
	},
	{
		name: "Theme 1",
		dark: {
			primary: "#ff6b99",
			accent: "#FF4081",
			secondary: "#21dc79",
			success: "#ff6b99",
			info: "#f34fc6",
			warning: "#FB8C00",
			mainheader: "#ff6b99",
			error: "#FF5252"
		},
		light: {
			primary: "#ff6b99",
			accent: "#ff6b99",
			secondary: "#26ff8c",
			success: "#ff6b99",
			mainheader : "#ff6b99",
			info: "#ff53d0",
			warning: "#ff8e00",
			error: "#ff5252"
		}
	},
	{
		name: "Green",		
		light: {
			primary: "#4caf50",
			secondary: "#8bc34a",
			accent: "#00C853",
			error: "#FF5252",
			mainheader : "#4caf50",
			info: "#2196F3",
			success: "#4CAF50",
			warning: "#FFC107"
		  },
		dark: {
			primary: "#4caf50",
			secondary: "#8bc34a",
			accent: "#00C853",
			error: "#FF5252",			
			mainheader : "#4caf50",
			info: "#2196F3",
			success: "#4CAF50",
			warning: "#FB8C00"
		}
	},
	{
		name: "Red",				
		light: {
			primary: "#f44336",
			secondary: "#FF5252",
			accent: "#D50000",
			error: "#FF5252",
			mainheader : "#f44336",
			info: "#2196F3",
			success: "#4CAF50",
			warning: "#FFC107"
		  },
		dark: {
			primary: "#f44336",
			secondary: "#FF5252",
			accent: "#D50000",
			error: "#FF5252",
			mainheader : "#f44336",
			info: "#2196F3",
			success: "#4CAF50",
			warning: "#FB8C00"
		  }
	},
	{
		name: "Purple",  
		light: {
			primary: "#673ab7",
			secondary: "#9c27b0",
			accent: "#AA00FF",
			error: "#FF5252",
			mainheader : "#673ab7",
			info: "#2196F3",
			success: "#4CAF50",
			warning: "#FFC107"
		},
		dark: {
			primary: "#AB47BC",
			secondary: "#9c27b0",
			accent: "#AA00FF",
			error: "#FF5252",
			mainheader : "#AB47BC",
			info: "#2196F3",
			success: "#4CAF50",
			warning: "#FB8C00"
		}
	},
	{
		name: "Indigo",		
		light: {
			primary: "#3F51B5",
			secondary: "#9FA8DA",
			accent: "#304FFE",
			error: "#FF5252",
			mainheader : "#3F51B5",
			info: "#2196F3",
			success: "#4CAF50",
			warning: "#FFC107"
		},
		dark: {
			primary: "#3F51B5",
			secondary: "#9FA8DA",
			accent: "#304FFE",
			error: "#FF5252",
			mainheader : "#3F51B5",
			info: "#2196F3",
			success: "#4CAF50",
			warning: "#FB8C00"
		}
	},
	{
        name: "Theme 2",
        dark: {
			primary: "#21CFF3",
			accent: "#FF4081",
			secondary: "#21dc79",
			success: "#86af3f",
			mainheader : "#22daff",
			info: "#f34fc6",
			warning: "#FB8C00",
			error: "#FF5252"
        },
        light: {
			primary: "#22daff",
			accent: "#ff6b99",
			secondary: "#26ff8c",
			success: "#a5d64c",
			mainheader : "#22daff",
			info: "#ff53d0",
			warning: "#ff8e00",
			error: "#ff5252"
        }
    },
    {
        name: "Theme 3",
        dark: {
			primary: "#E65100",
			accent: "#7CB342",
			secondary: "#689F38",
			success: "#4CAF50",
			mainheader : "#ffa450",
			info: "#6156d8",
			warning: "#1565C0",
			error: "#FF7043"
        },
        light: {
			primary: "#ffa450",
			accent: "#a1e754",
			secondary: "#92de4e",
			success: "#6dff74",
			mainheader : "#ffa450",
			info: "#7365ff",
			warning: "#2e8ac0",
			error: "#ff5e3c"
        }
      },
      {
        name: "Theme 4",
        dark: {
			primary: "#33691E",
			accent: "#FFCA28",
			secondary: "#607D8B",
			success: "#FFEB3B",
			mainheader : "#6ae240",
			info: "#2196F3",
			warning: "#9C27B0",
			error: "#B71C1C"
        },
        light: {
			primary: "#6ae240",
			accent: "#ffe063",
			secondary: "#7ea4b6",
			success: "#ffea70",
			mainheader : "#6ae240",
			info: "#229eff",
			warning: "#e239ff",
			error: "#e82424"
        }
    },
]

export default themes;