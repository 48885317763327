import axios from "axios";
export const namespaced = true;
const CancelToken = axios.CancelToken.source();

export const Config = {
  baseURL: process.env.VUE_APP_ROOT_API,
  upload: "/receptions/doc/",
  disorders: '/disorders/doc/',
  sparelist: '/btb/spares/list/download'
};

export const client = {
  base: axios.create({
    baseURL: Config.baseURL,
  }),
  //clean no loading throbber
  clean: axios.create({
    baseURL: Config.baseURL
  }),
  blob: axios.create({
    baseURL: Config.baseURL,
    responseType: "blob"
  })
};