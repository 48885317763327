import Utils from "@/utils.js";

export default class Person{
	constructor( person ){
		/* properties list */
		this.id = null;
		this.firstname = null;
		this.lastname = null;
		this.country = null;
		this.zip = null;
		this.city = null;
		this.street = null;
		this.hsnr = null;
		this.nationality = null;
		this.incomplete = null;
		this.dob = null;
		this.legal = false;
		this.companyname = null;
		this.hotlist = null; 
		this.refid = null; 

		if( !!person ){
			if(typeof(person.id)   !== "undefined") this.id   = person.id;
			if(typeof(person.firstname)   !== "undefined") this.firstname   = person.firstname;
			if(typeof(person.lastname)    !== "undefined") this.lastname    = person.lastname;
			if(typeof(person.country)     !== "undefined") this.country     = person.country;
			if(typeof(person.zip)         !== "undefined") this.zip         = person.zip;
			if(typeof(person.city)        !== "undefined") this.city        = person.city;
			if(typeof(person.street)      !== "undefined") this.street      = person.street;
			if(typeof(person.hsnr)        !== "undefined") this.hsnr        = person.hsnr;
			if(typeof(person.dob)         !== "undefined") this.dob         = Utils.ISOToDateString(person.dob);
			if(typeof(person.nationality) !== "undefined") this.nationality = person.nationality;
			if(typeof(person.incomplete)  !== "undefined") this.incomplete  = person.incomplete;
			if(typeof(person.legal)       !== "undefined") this.legal = person.legal;
			if(typeof(person.hotlist)       !== "undefined") this.hotlist = person.hotlist;
			if(typeof(person.companyname) !== "undefined") this.companyname  = person.companyname;
			if(typeof(person.refid) !== "undefined") this.refid  = person.refid;

		} else {
			// 

			/**/
		}
	}
}
