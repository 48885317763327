import Utils from "@/utils.js";



export default class Disposal {
    constructor(disposal) {
        this.id = null;
        this.actiondate = null
        this.carrierId = null;
        this.disposerName = null;
        this.carrierName = null;
        this.myDisposerId = null;
        this.receiptnumber = null;
        this.disposalproofnumber = null;
        this.remark = null;
        this.tons = null;
        this.measure = null;
        this.amount = null;
        this.locked = false;
        this.wastetype = null;
        this.wastename = null;

        if(!!disposal) {
            
            if(typeof disposal.id !== 'undefined') this.id = disposal.id                                     
            if(typeof disposal.actiondate !== 'undefined') this.actiondate = Utils.ISOToDateString(disposal.actiondate);
            if(typeof disposal.carrierId !== 'undefined') this.carrierId = disposal.carrierId 
            if(typeof disposal.carrierName !== 'undefined') this.carrierName = disposal.carrierName 
            if(typeof disposal.myDisposerId !== 'undefined') this.myDisposerId = disposal.myDisposerId 
            if(typeof disposal.disposerName !== 'undefined') this.disposerName = disposal.disposerName 
            if(typeof disposal.receiptnumber !== 'undefined') this.receiptnumber = disposal.receiptnumber 
            if(typeof disposal.disposalproofnumber !== 'undefined') this.disposalproofnumber = disposal.disposalproofnumber 
            if(typeof disposal.remark !== 'undefined') this.remark = disposal.remark                         
            if(typeof disposal.tons !== 'undefined') this.tons = disposal.tons                         
            if(typeof disposal.measure !== 'undefined') this.measure = disposal.measure                         
            if(typeof disposal.amount !== 'undefined') this.amount = disposal.amount                                                
            if(typeof disposal.locked !== 'undefined') this.locked = disposal.locked                         
            if(typeof disposal.wastetype !== 'undefined') this.wastetype = disposal.wastetype 
            if(typeof disposal.wastename !== 'undefined') this.wastename = disposal.wastename                         
            

        }
    }
}