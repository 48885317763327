import Utils from "@/utils.js";
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : process.env.VUE_APP_I18N_LOCALE 
export default class DisposalOut {
    constructor(disposalout) {
        this.id = null;
        this.wastetype = null;
        this.amount = null;        
        this.carInfo = null;        
        this.carId = null;        
        this.measure = null;
        this.actiondate = null;
        this.outId = null;        
        this.remark = null;
        this.locked = false;
        this.staffId = null;                
        this.uiInfo = null;
        this.dismantlerId = null

        if(!!disposalout) {
            
            if(typeof disposalout.id !== 'undefined') this.id = disposalout.id 
            if(typeof disposalout.amount !== 'undefined') this.amount = disposalout.amount.toLocaleString(lang)
            if(typeof disposalout.staffId !== 'undefined') this.staffId = disposalout.staffId 
            if(typeof disposalout.wastetype !== 'undefined') this.wastetype = disposalout.wastetype 
            if(typeof disposalout.carInfo !== 'undefined') this.carInfo = disposalout.carInfo 
            if(typeof disposalout.uiInfo !== 'undefined') this.uiInfo = disposalout.uiInfo 
            if(typeof disposalout.outId !== 'undefined') this.outId = disposalout.outId 
            if(typeof disposalout.measure !== 'undefined') this.measure = disposalout.measure 
            if(typeof disposalout.dismantlerId !== 'undefined') this.dismantlerId = disposalout.dismantlerId 
            if(typeof disposalout.disposalId !== 'undefined') this.disposalId = disposalout.disposalId             
            if(typeof disposalout.remark !== 'undefined') this.remark = disposalout.remark 
            if(typeof disposalout.locked !== 'undefined') this.locked = disposalout.locked
            if(typeof disposalout.actiondate !== 'undefined') this.actiondate = Utils.ISOToDateString(disposalout.actiondate );

        }
    }
} 