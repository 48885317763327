
import VN_Service from "@/services/VN_Services.js";
import i18n from "@/i18n";
const $t = i18n();

import Unit from "@/store/classes/Unit.js";
import UnitCarColor from "@/store/classes/UnitCarColor.js";
export const namespaced = true;
export const state = {
  /* Liste aller sichtbaren Accounts */
  units: [],
  unitsFetched: false,
  qualifications: [],
  measureTypes: []
};

export const mutations = {
  addUnits(state, units) {
    state.units = units
  },
  clearUnits(state) {
    state.units = []
  },
  addQualifications(state, qualifications) {
    qualifications.forEach(qualification => {           
        state.qualifications.push(qualification);    
    });
  },
  clearQualifications(state){
		state.qualifications = [];
	},
};

export const actions = {
  /* ICRUD */
  /* Index collectors */
  getAllUnits({ commit, state }) {    
      return VN_Service.getAllUnits()
        .then(r => {
          if (r.status === 200) {
            commit('clearUnits')
            commit("addUnits", r.data);
            return r.data
          }
        })
        .catch(e => {
          
          
        });
  },
  getQualifications({commit}) {
    return VN_Service.getQualifications()
        .then(r => {
          if (r.status === 200) {
            commit("clearQualifications");
            commit("addQualifications", JSON.parse(JSON.stringify(r.data)));
            return r.data;
          }
        })
        .catch(e => {
          
          
        });
  },
  getQualificationOfWasteType() {
    return VN_Service.getQualificationOfWasteType().then(r => {
      return r.data
    })
  }
};

export const getters = {
  getUnit: state => type => {
    if(type === 'carSteps') {      
      return state.units[type].filter((carstep) => {                      
        return carstep = carstep.id !== 'RECEPTION' && carstep.id !==  'VNLOCKED' && carstep.id !==  'DISPOSED'
      }) 
    } else {
      return state.units[type]
    }
  },
  getCarInventorySteps: state => type => {    
    if(type === 'carSteps' && state.units[type]) {  
      return state.units[type].filter((carstep) => {                      
        return carstep = carstep.id !==  'DISPOSED'
      }) 
    }
  },
  getEntrySteps: state => type => {
    if(type === 'carSteps' && state.units[type]) {  
      return state.units[type].filter((carstep) => {                      
        return carstep = carstep.id !== 'RECEPTION' && carstep.id !==  'VNLOCKED'
      }) 
    }
  },
  getUnitById: state => id => {
    let unit = state.units.filter(unit =>{
        return unit.id === id;
    });
    if(unit && unit.length){
      return unit[0];
    }
    return null;
  },
  qualifications( state ){
    return state.qualifications;
  },
  getMeasureUnit: state => type => {    
    if(type[1] === 'car') {      
      return state.units[type[0]].filter((measure) => {                      
        return measure = measure.id !== 'l' && measure.id !==  'ml' 
      }) 
    } else {
      return state.units[type[0]]
    }
  }
};
