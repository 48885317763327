import { client } from "@/services/client.js";
import store from "@/store/store";
import i18n from '@/i18n'
import DeliveryComposite from "@/store/classes/composite/DeliveryComposite"; 
import RemanCart from "@/store/classes/RemanCart"; 
const $t = i18n();

export const namespaced = true;

export const state = {
    caroffers: null ,
    isSameKtype: false,    
    dismantlerCart: [],
    dismanRemanCart: [],
    mycart: new RemanCart(),
    hasOffers: false,
    remanufacturers: [],
    remancarts: [],
    deliverynotes: [],
    deliverynote: null,
    manufacturers: null
};

export const mutations = {
    clearCarOffers(state) {
        state.caroffers = []
        state.isSameKtype = false
    },
    setCarOffers(state, data) {
        var result = data.every((obj)=> obj.carktype === data[0].carktype);        
        
        data.filter(o => { o.quantity = 1})
        data.forEach(ol => {
            ol.offerq = 1
            state.caroffers.push(ol)})
        state.isSameKtype = result
        
    },
    updateCarOffers(state, data) {
        
        let newoffer = state.caroffers.find(entry => entry.id === data.id)
        Object.assign(newoffer, data)
    },
    setTempCart(state, articles) {
        state.dismantlerCart = articles
    },
    sethasOffers(state, value) {
        state.hasOffers = value
    },
    setMyCart(state, cart) {
        var total = 0;
        for(let article of cart.entries) {
            let price = article.quantity * article.articleprice
            article.deleted = false;
            total += price
            article.totalvalue = article.quantity * article.articleprice
            article.lastQuantity = article.quantity 
        }

        cart.total = total
        state.mycart = new RemanCart(cart)        
    },
    setRemanCarts(state, carts) {
        carts.forEach(cart => {state.remancarts.push(cart)})    
    },
    clearRemanCarts(state) {
        state.remancarts = []
    },
    clearDismanCart(state) {
        state.mycart = []
    },
    setDismantlerCart(state,data) {
        state.dismanRemanCart = data  
        //data.forEach(article => {state.dismanRemanCart.push(article)})    
    },
    clearDismantlerCart(state) {
        state.dismanRemanCart = []
    },
    setDeliveryNotes(state,deliveries) {
        deliveries.forEach(delivery => {state.deliverynotes.push(delivery)})  
    },
    clearDeliveryNotes(state) {
        state.deliverynotes = []
    },
    setDeliveryNote(state,delivery) {
        for(let article of delivery.entries) {            
            article.deleted = false;           
        }
        state.deliverynote = delivery
    },
    clearDeliveryNote(state) {
        state.deliverynote = []
    },
    addRemanufacturers(state, data){
        data.forEach(d => {
            state.remanufacturers.push(d);
        });
        
    },
    clearRemanufacturers(state){
        state.remanufacturers = [];
    },
    addManufacturers(state, data){
        data.forEach(d => {
            state.manufacturers.push(d);
        });
        
    },
    clearManufacturers(state){
        state.manufacturers = [];
    }
};

export const actions = {
    getManufacturers({commit}) {
        return client.base.get('kba/hsn').then((r) => {
            if(r.status === 200) {
                commit("clearManufacturers")
                commit("addManufacturers", r.data)
            }
        })
    },
    filterOffers({commit}, filter) {
        return client.base.get('reman/offers/list/byfilter/' + filter.dId + '?type=' + filter.filtertype + '&values=' + filter.value)
            .then((r) => {
                if(r.status === 200) {
                    commit("clearCarOffers")
                    commit("setCarOffers", r.data)
                    return r.data
                }
            })
    },
    async getRemanufacturers({commit}) {
        return await client.base.get('/reman').then((r) => {
            if( r.status === 200 ){
                commit("clearRemanufacturers");
                commit("addRemanufacturers", r.data);
            }
        })
    },
    createUpdateDelivery({commit,dispatch}, delivery) {
        return client.base.put('/reman/delivery', delivery).then((r) => {            
            dispatch("getMyCart", {remanId: delivery.remanId, dId: delivery.dismantlerId})
            if(r.status === 200) {
                if(delivery.id === null) {
                    store.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.reman.delivery.added")
                    );
                } else {                    
                    store.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.reman.delivery.edited")
                    );
                }
                commit("clearDeliveryNote")
                commit("setDeliveryNote", r.data)  
            }
            return r.data
        })
    },
    deleteFromDelivery({commit,dispatch}, delivery) {
        return client.base.put('/reman/delivery', delivery).then((r) => {                        
            if(r.status === 200) {     
                commit("clearDeliveryNote")
                commit("setDeliveryNote", r.data)           
                store.dispatch(
                    "errorhandling/addMessage",
                    $t.t("$gt.msg.system.success.reman.delivery.articledeleted")
                );                
            }
            return r.data
        })
    },
    getDeliveryById({commit}, id) {
        return client.base.get('/reman/delivery/' + id).then((r) => {
            commit("clearDeliveryNote")
            commit("setDeliveryNote", r.data)
            return r.data
        })
    },
    lockDelivery({commit}, id) {
        return client.base.patch('/reman/delivery/' +id).then((r) => {
            commit("clearDeliveryNote")
            commit("setDeliveryNote", r.data)
            store.dispatch(
                "errorhandling/addMessage",
                $t.t("$gt.msg.system.success.reman.delivery.closed")
            );    
            return r.data
        })
    },
    pickupCartDismantler({commit}, data) {
        return client.base.patch('/reman/cart/pickup/' + data.remanId + '/' + data.dismantlerId, data.readyForPickup, {headers: {"Content-Type": "application/json"}})
        .then((r) => {                 
            commit("setMyCart", r.data)
            if(data.readyForPickup === true) { 
                store.dispatch(
                    "errorhandling/addMessage",
                    $t.t("$gt.reman.dismantler.readyforpickup", {reman: data.remanName})
                ); 
            } else {
                store.dispatch(
                    "errorhandling/addMessage",
                    $t.t("$gt.reman.dismantler.notreadyforpickup", {reman: data.remanName})
                ); 
            }
            return r.data
        })
    },
    pickupCartReman({commit}, data) {
        return client.base.patch('/reman/cart/pickup/' + data.remanId + '/' + data.dismantlerId, data.readyForPickup, {headers: {"Content-Type": "application/json"}})
        .then((r) => {            
            if(data.readyForPickup === true) { 
                store.dispatch(
                    "errorhandling/addMessage",
                    $t.t("$gt.reman.reman.readyforpickup", {dismantler: data.dismantlerName})
                ); 
            } else {
                store.dispatch(
                    "errorhandling/addMessage",
                    $t.t("$gt.reman.reman.notreadyforpickup", {dismantler: data.dismantlerName})
                ); 
            }    
            return r.data
        })
    },
    deleteDelivery({commit,dispatch}, item) {
        
        return client.base.delete('/reman/delivery/' +item.id).then((r) => {
            dispatch("getDismantlerDeliveries", item.dismantlerId)
            store.dispatch(
                "errorhandling/addMessage",
                $t.t("$gt.msg.system.success.reman.delivery.deleted")
            );            
            return r.data
        })
    },
    getRemanDeliveries({commit}, rid) {
        return client.base.get('/reman/delivery/by/reman/' + rid).then((r) => {
            commit("clearDeliveryNotes")
            commit("setDeliveryNotes", r.data)
            return r.data
        })
    },
    getDismantlerDeliveries({commit}, did) {
        return client.base.get('/reman/delivery/by/dismantler/' + did).then((r) => {
            commit("clearDeliveryNotes")
            commit("setDeliveryNotes", r.data)
            return r.data
        })
    },
    /* remanufacturer */
    importSpares({commit}, data) {
        let formData = new FormData();
        formData.append("file", data.file);
        return client.base.post('/reman/upload/' + data.remanId, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }}
        ).then((r) => {
            store.dispatch(
                "errorhandling/addMessage",
                $t.t("$gt.msg.system.success.reman.imported", {count: r.data.rowsImported, read: r.data.rowsRead})
            );    
            return r.data
        })
    },
    getRemanCarts({commit}, rid) {
        return client.base.get('reman/cart/by/reman/' + rid).then((r) => {
            commit("clearRemanCarts")
            commit("setRemanCarts", r.data)
            return r.data
        })
    },
    /* dismantler */
    async offerExistsByCarId({commit}, carId) {
        return await client.base.get('reman/offers/exist/bycar/' + carId).then((r) => {            
            commit("sethasOffers", r.data)
        })
    },
    getOffersTreeByCarId({commit}, carId) {
        return client.base.get('reman/offers/tree/bycar/' + carId).then((r) => {
            commit("setCarOffers", r.data)
            return r.data
        })
    },
    getOffersByCarAndRemanId({commit}, data) {
        return client.base.get('reman/offers/list/bycarandreman/' + data.carId + '/' + data.remanId).then((r) => {
            commit("clearCarOffers")
            commit("setCarOffers", r.data)
            return r.data
        })
    },
    getOffersByCarId({commit}, data) {
        return client.base.get('/reman/offers/list/car/' + data.carId).then((r) => {
            commit("clearCarOffers")
            commit("setCarOffers", r.data)
            return r.data
        })
    },
    addOfferToCart({commit}, offer) {              
        var offerId = offer.offerId.replaceAll('|', '_') // must be replaced cause of nginx security
        return client.base.put('/reman/cart/'+ offer.contractId + '/'+ offerId + '/'+ offer.quantity).then((r) => {
            commit("updateCarOffers", r.data)
           
            store.dispatch(
                "errorhandling/addMessage",
                $t.t("$gt.msg.system.success.reman.cart.added")
            );
             return r.data
        })
    },
    addUpdateCart({commit}, articles) {
        return client.base.put('reman/cart', articles).then((r) => {
            commit("clearDismanCart")
            commit("setMyCart", r.data)
            store.dispatch(
                "errorhandling/addMessage",
                $t.t("$gt.msg.system.success.reman.cart.added")
            );
        })
    },
    updateCart({commit}, articles) {
        return client.base.put('reman/cart', articles).then((r) => {
            commit("clearDismanCart")
            commit("setMyCart", r.data)
            store.dispatch(
                "errorhandling/addMessage",
                $t.t("$gt.msg.system.success.reman.cart.updated")
            );
            return r.data
        })
    },
    removeArticleCart({commit}, articles) {
        return client.base.put('reman/cart', articles).then((r) => {
            commit("clearDismanCart")
            commit("setMyCart", r.data)
            store.dispatch(
                "errorhandling/addMessage",
                $t.t("$gt.msg.system.success.reman.cart.deleted")
            );
        })
    },
    getMyCart({commit}, data) {
        return client.clean.get('/reman/cart/by/reman/dismantler/' + data.remanId + '/' + data.dId)
        .then((r) => {
            commit("setMyCart", r.data)
            return r.data
        })
    },
    getDismantlerCart({commit}, data) {
        return client.clean.get('/reman/cart/by/reman/dismantler/' + data.remanId + '/' + data.dId)
        .then((r) => {
            commit("setDismantlerCart", r.data)
            return r.data
        })
    }
};

export const getters = {

};