import Spareout from "@/store/classes/SpareOut";  
import SpareoutList from "@/store/classes/SpareOutList";  
import Utils from "@/utils.js";

export default class SpareOutComposite {
  constructor(spareoutlist) {
        
    this.id = null;
    this.actionDate = new Date().toISOString().substr(0, 10);
    this.reference = null;
    this.staffId = null;     
    this.spareOuts = [];

    if (!!spareoutlist) {
      if (typeof spareoutlist.id !== "undefined") this.id = spareoutlist.id
      if (typeof spareoutlist.actionDate !== "undefined") this.actionDate = Utils.ISOToDateString(spareoutlist.actionDate);
      if (typeof spareoutlist.reference !== "undefined") this.reference = spareoutlist.reference
      if (typeof spareoutlist.staffId !== "undefined") this.staffId = spareoutlist.staffId
      if (typeof spareoutlist.spareOuts !== "undefined") spareoutlist.spareOuts.forEach(spareout => { this.spareOuts.push(new Spareout(spareout)); });   
    }
  }
}
