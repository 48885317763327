import Utils from "@/utils.js";

export default class Contact{
	constructor( contact ){
		/* properties list */		
		this.refid = null;
		this.firstname = null;
		this.lastname = null;
		this.country = null;
		this.zip = null;
		this.city = null;
		this.street = null;
		this.hsnr = null;
		this.nationality = null;
		this.incomplete = null;
		this.dob = null;
		this.legal = false;
		this.name = null
		this.companyname = null;		
		if( !!contact ){			
			if(!contact.legal) {         
                let firstname =  contact.firstname ? contact.firstname : '';
                let lastname =  contact.lastname ? contact.lastname : '';
                let city = contact.city ? ' (' + contact.city + ')' : '';       
                 this.name = firstname + ' ' + lastname + city
            } else {                 
                let company = contact.companyname ? contact.companyname : '';
                let city = contact.city ? ' (' + contact.city + ')' : '';
                this.name =  company + city;
            }
			if(typeof(contact.refid)   !== "undefined") this.refid   = contact.id;
			if(typeof(contact.firstname)   !== "undefined") this.firstname   = contact.firstname;
			if(typeof(contact.lastname)    !== "undefined") this.lastname    = contact.lastname;
			if(typeof(contact.country)     !== "undefined") this.country     = contact.country;
			if(typeof(contact.zip)         !== "undefined") this.zip         = contact.zip;
			if(typeof(contact.city)        !== "undefined") this.city        = contact.city;
			if(typeof(contact.street)      !== "undefined") this.street      = contact.street;
			if(typeof(contact.hsnr)        !== "undefined") this.hsnr        = contact.hsnr;
			if(typeof(contact.dob)         !== "undefined") this.dob         = Utils.ISOToDateString(contact.dob);
			if(typeof(contact.nationality) !== "undefined") this.nationality = contact.nationality;
			if(typeof(contact.incomplete)  !== "undefined") this.incomplete  = contact.incomplete;
			if(typeof(contact.legal)       !== "undefined") this.legal = contact.legal;
			
			
			if(typeof(contact.companyname) !== "undefined") this.companyname  = contact.companyname;

		} else {
			// 

			/**/
		}
	}
}
