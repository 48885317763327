import Utils from "@/utils.js";
import { Config } from "@/services/client.js";
import VN_Service from "@/services/VN_Services.js";
import store from "@/store/store";
import i18n from "@/i18n";

const $t = i18n();
export const namespaced = true;

export const state = {

}

export const actions = {
    unlockReception({commit},data) {
        return VN_Service.unlockReception(data)
            .then(r => {
                return r.data;
            })
    },
    unlockBtb({commit},data) {
        return VN_Service.unlockBtb(data)
            .then(r => {
                return r.data;
            })
    }
}

export const mutations = {

}

export const getters = {

}