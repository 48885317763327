import Disposal from "@/store/classes/Disposal"; 
import DisposalOutput from "@/store/classes/DisposalOutput"; 
import Utils from "@/utils.js";

export default class DisposalComposite {
  constructor(disposal) {
        
    this.disposal = new Disposal();
    this.btbEntryOuts = [];
    this.status = ''

    if (!!disposal) {
      if (typeof disposal.status !== "undefined")
        this.status = disposal.status;
      if (typeof disposal.disposal !== "undefined")
        this.disposal = new Disposal(disposal.disposal);
      if (typeof disposal.btbEntryOuts !== "undefined")
        disposal.btbEntryOuts.forEach(output => {
          this.btbEntryOuts.push(new DisposalOutput(output));
        });   
    }
  }
}
