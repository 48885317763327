import Vue from "vue";
import Vuetify from "./vuetify";
import App from "./DeserApp.vue";
import router from "./router";
import store from "./store/store";
import customFilters from './filters/index'
import { client } from "@/services/client.js";
import './assets/app.scss';
import i18n from './i18n'
import { VueReCaptcha } from "vue-recaptcha-v3"
import * as moment from 'moment';
import Chart from 'vue2-frappe'
import VueHtmlToPaper from 'vue-html-to-paper';
import VueSSE from "./sse";
import Pusher from 'pusher-js';
import L from 'leaflet';
import { LMap, LTileLayer, LMarker,LGeoJson, LCircle, LControl, LTooltip, LControlZoom, LControlScale } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import './assets/leaflet-routing-machine.css'
import '@mdi/font/css/materialdesignicons.css'
import { Icon } from 'leaflet';
import helpers from './vuex/helpers'

Vue.use(helpers)
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-geo-json', LGeoJson );
Vue.component('l-circle',  LCircle );
Vue.component('l-control',  LControl );
Vue.component('l-tooltip',  LTooltip );
Vue.component('l-control-zoom',  LControlZoom );
Vue.component('l-control-scale',  LControlScale );
const printoptions = {   
	name: '_blank',
	specs: [
		'fullscreen=no',
		'titlebar=no',
		'scrollbars=yes'
	],
	styles: [	  
		'https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css',
		"https://unpkg.com/kidlat-css/css/kidlat.css",
		'/landscape.css'
	]
  }
Vue.use(Chart)
Vue.use(VueSSE)
Vue.use(VueHtmlToPaper,printoptions)
Vue.use(VueReCaptcha, { 
	siteKey: '6LcfU-oUAAAAAMlFkVXwsugvVBIVb-7ALBhrK64D',
	loaderOptions: {
		useRecaptchaNet: true,
		autoHideBadge: true
	} 
})

window.pusher = new Pusher('6c513f81c80aefa6b638', {
	cluster: 'eu',
  }); 

import {
    ValidationObserver,
    ValidationProvider
  } from "vee-validate";
import "./validaterules";
let i18nInstance = i18n();

Vue.use(Vuetify);

Vue.component('ValidationProvider', ValidationProvider,)
Vue.component('ValidationObserver', ValidationObserver,)

let lang = localStorage.getItem('language') ? localStorage.getItem('language') : process.env.VUE_APP_I18N_LOCALE
moment.locale(lang)
moment.updateLocale('de', {
    invalidDate : 'Ungültiges Datum'
});
Vue.mixin({
	filters: customFilters,
});

Vue.config.productionTip = false;
const user = localStorage.getItem('current_user')
const userstorage = JSON.parse(user)

localStorage.setItem( "appTheme", 'Default');	// Workaround need to be changed

if (userstorage) {  
	client.base.defaults.headers.common["Accept-Language"] = lang;
	client.blob.defaults.headers.common["Authorization"] = `Bearer ${
		userstorage.token
	}`;
	  client.base.defaults.headers.common["Authorization"] = `Bearer ${
		userstorage.token
	}`;	
	if (userstorage)
	store.dispatch("accountsModule/auth", userstorage );			
}
var sse = null;
var msgServer = null;

export const vm = new Vue({		
    vuetify: Vuetify,
	store,
	router,
	i18n: i18nInstance,
	render: h => h(App),
	
})
store.$app = vm
.$mount("#app1");

