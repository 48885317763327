import Utils from "@/utils.js";
import i18n from "@/i18n";
const $t = i18n();
export default class Quote {
    constructor(quote) {
        this.month = null;
        this.carentry = 0.000;
        this.ftotal = 0.000;
        this.entryCarCount = 0;
        this.f2 = 0.000
        this.f3 = 0.000
        this.f4 = 0.000
        this.f5 = 0.000
        this.f6 = 0.000
        this.f7 = 0.000
        this.f8 = 0.000
        this.f9 = 0.000
        this.f10 = 0.000
        this.f11 = 0.000
        this.f12 = 0.000
        this.f13 = 0.000
        this.f14 = 0.000
        this.f99 = 0.000

        if(!!quote) {
            this.month = $t.t('$gt.months.' + quote.month) + ' - ' + quote.year            
            this.carentry = quote[1] ? quote[1] : 0.000;
            this.ftotal = 0.000;
            this.entryCarCount = quote.entryCarCount ? quote.entryCarCount : 0 
            this.f2 = quote[2] ? quote[2] : 0.000;
            this.f3 = quote[3] ? quote[3] : 0.000;
            this.f4 = quote[4] ? quote[4] : 0.000;
            this.f5 = quote[5] ? quote[5] : 0.000;
            this.f6 = quote[6] ? quote[6] : 0.000;
            this.f7 = quote[7] ? quote[7] : 0.000;
            this.f8 = quote[8] ? quote[8] : 0.000;
            this.f9 = quote[9] ? quote[9] : 0.000;
            this.f10 = quote[10] ? quote[10] : 0.000;
            this.f11 = quote[11] ? quote[11] : 0.000;
            this.f12 = quote[12] ? quote[12] : 0.000;
            this.f13 = quote[13] ? quote[13] : 0.000;
            this.f14 = quote[14] ? quote[14] : 0.000;
            this.f99 = quote[99] ? quote[99] : 0.000;            
        }
    }

}