import Utils from "@/utils.js";
export default class CompanyLite {
  constructor(department) {
    /* define base values */
    this.id = null;
    this.company = null;
    this.street = null;
    this.city = null;
    this.tel = null;
    this.certill = null;
    this.dismantler = null;

    if (!!department) {
      
      

      if (typeof department.id !== "undefined")      this.id      = department.id;
      if (typeof department.company !== "undefined") this.company = department.company;
      if (typeof department.street !== "undefined")  this.street  = department.street;
      if (typeof department.city !== "undefined")    this.city    = department.city;
      if (typeof department.tel !== "undefined")     this.tel     = department.tel;
      if (typeof department.certill !== "undefined") this.certill = Utils.ISOToDateString(department.certill);
      if (typeof department.dismantler !== "undefined")this.dismantler = department.dismantler;
      if (typeof department.vnprintdate !== "undefined") { this.vnprintdate = department.vnprintdate}  
      if (typeof department.vnprintstamp !== "undefined") { this.vnprintstamp = department.vnprintstamp}  
      if (typeof department.vnsornc !== "undefined") { this.vnsornc = department.vnsornc}  
      if (typeof department.vnsornd !== "undefined") { this.vnsornd = department.vnsornd}  
      
    } else {
      
    }
  }
}
