export default class Unit {
  constructor(unit) {
    /* properties list */
    this.id = null;
    this.name = null;
    this.value = null;
    this.sorter = null;
    if (!!unit) {
      if (typeof unit.id !== "undefined") this.id = unit.id;
      if (typeof unit.dtype !== "undefined") this.dtype = unit.dtype;
      if (typeof unit.value !== "undefined") this.value = unit.value;
      if (typeof unit.sorter !== "undefined") this.sorter = unit.sorter;
    } else {
    }
  }
}
