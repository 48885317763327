import Utils from "@/utils.js";
import { Config } from "@/services/client.js";
import VN_Service from "@/services/VN_Services.js";
import store from "@/store/store";
import Vue from "vue";

import CompanyLite from "@/store/classes/composite/CompanyLite.js";
import DepartmentFormComposite from "@/store/classes/composite/DepartmentFormComposite.js";

import i18n from '@/i18n'

const $t = i18n();

export const namespaced = true;
export const state = {
	/* Liste aller sichtbaren Demontagebetriebe */
	dismantlers: [],
	/* Liste aller sichtbaren Demontagebetriebe die nur über den Managerzeiger laufen*/
	disCmantlers : [],
	dismantlersFetched : false,
	dismantlersExist : false
};
export const mutations = {

	/* + */
		/* pushes a single dismantler into state */
		addDismantler(state, dismantler_data){
			state.dismantlers.push(new DepartmentFormComposite({id: dismantler_data.id}));
			localStorage.setItem( "dismantler_count", state.dismantlers.length );
			/* REFACTOR: sort after pushing into dismantlers */
		},
		/* pushes multiple dismantlers into state */
		addDismantlers(state, arr_of_dismantler_data){
			arr_of_dismantler_data.forEach(d => {
				state.dismantlers.push(d);
			});
			localStorage.setItem( "dismantler_count", state.dismantlers.length );
			/* REFACTOR: sort after pushing into dismantlers */
		},
		
		/* pushes multiple dismantlers into state */
		
		addDisCmantlers(state, arr_of_dismantler_data){
			arr_of_dismantler_data.forEach(d => {
				state.disCmantlers.push(d);
			});
			localStorage.setItem( "disCmantler_count", state.disCmantlers.length );
			/* REFACTOR: sort after pushing into dismantlers */
		},
		
		setDismantlerExist(state, exist){
			state.dismantlersExist = exist;
		},

	/* - */
		/* removes a dismantler from state */
		removeDismantler(state, dismantler){
			let index = state.dismantlers.indexOf( dismantler );
			state.dismantlers.splice(index, 1);
			localStorage.setItem( "dismantler_count", state.dismantlers.length );
		},
		/* wipes the currently tracked dismantlers (for reset) */
		clearDismantlers(state){
			state.dismantlers = [];
			state.dismantlersFetched = false;
		},
		
		clearDisCmantlers(state){
			state.disCmantlers = [];	
		},

	/* / */
		/* replaces a dismantler in state by its id */
		replaceDismantler(state, new_dismantler_data){
			
			

			/* get dismantler reference */
			let dismantler_to_be_replaced = state.dismantlers.find(
				r => r.id === new_dismantler_data.id
			);
			if( !!dismantler_to_be_replaced ){
				/* update dismantler reference with new data */
				Object.assign( dismantler_to_be_replaced, new DepartmentFormComposite(new_dismantler_data) );
				/* REFACTOR: sort after replacing account */
				return dismantler_to_be_replaced;
			} else {
				/* TODO: error handling */
				return null;
			}
		},
		/*??? replaceDismantlers(){}, ???*/
			/* REFACTOR: sort after replacing accounts */

};
export const actions = {

	/* ICRUD */
		/* Index dismantlers */
		async getAllDismantlers({ commit , state}){
			//if(!state.dismantlersFetched){
				state.dismantlersFetched = true;
				return await VN_Service.indexDismantlers()
				.then(r => {
					if( r.status === 200 ){
						commit("clearDismantlers");
						commit("addDismantlers",
							JSON.parse(JSON.stringify(r.data))
						);
					}
				})
			//}
			
		},
		
		getAllDisCmantlers({ commit }){
			return VN_Service.getDismantlersForCurrentCollector()
			.then(r => {
				if( r.status === 200 ){
					commit("clearDisCmantlers");
					commit("addDisCmantlers",
						JSON.parse(JSON.stringify(r.data))						
					);
					return r.data;
				}
			})
			;
		},

		/* Create dismantler */
		newDismantler({ commit }, dismantler) {

			/* !!! dependencies are created implicitly !!! */
			return VN_Service.createDismantler(dismantler)
			.then(r => {
				if ((r.status === 200) || (r.status===201)) {
					
					commit("addDismantler", r.data);
					store.dispatch(
						"errorhandling/addMessage",
						$t.t("$gt.msg.system.success.dismantler.created")
					);
					/* implicit dependencies, so fetch collectors */
					/* REFACTOR: only fetch new collector instead of all data including collectors and dismantlers */
					store.dispatch("collectorsModule/getAllCollectors", true);
					  //commit("updateDismantler", new DepartmentFormComposite(r.data));
					  
					/* upload certificate after this went through properly */
						if( dismantler.certificate.cert_file !== null ){
							let temp_form_data = new FormData();
							temp_form_data.append("file", dismantler.certificate.cert_file);

							VN_Service.uploadCertificate(
								r.data.certificate.id,
								temp_form_data
							)
							.then(response => {
								if (response.status === 200) {
									commit("replaceDismantler", response.data);
								}
							})
							.catch(error => {
								console.log(
									$t.t("$gt.msg.error.certificate.upload"),
									error
								);
							});
						}
					/* /upload certificate */
					return r.data;
				}
			})
			;
		},

		/* Read Dismantler */
		/** get a dismantler by its id **/
		getDismantler({ commit }, dismantler_id){
			return VN_Service.getDismantler(dismantler_id)
			.then(r => {
				if( r.status === 200 ){
					return r.data;
				}
			})
			;
		},
		/* Update (destructive edit) dismantler */
		async updateDismantler({ commit }, dismantler) {
				
				
				return VN_Service.updateDismantler( dismantler )
				.then(r => {
					if( r.status === 200 ){
						
						commit("replaceDismantler", r.data);
						store.dispatch(
							"errorhandling/addMessage",
							$t.t("$gt.msg.system.success.dismantler.edited")
						);
						if( dismantler.certificate.cert_file !== null ){
							let temp_form_data = new FormData();
							temp_form_data.append("file", dismantler.certificate.cert_file);
		
							VN_Service.uploadCertificate(
								dismantler.certificate.id,
								temp_form_data
							) 
							.then(response => {
								if (response.status === 200) {
									
									commit("replaceDismantler", response.data);
									
								}
							})
							.catch(error => {
								console.log(
									$t.t("$gt.msg.error.certificate.upload"),
									error
								);
							});
						}
						return r.data;
					};
				});
			/* /update dismantler */
		},
		
		getDismantlerExist({commit}){
			return VN_Service.dismantlersExist().then(r => {
				if( r.status === 200 ){
					commit("setDismantlerExist", r.data);
				};
			});
				
			
		},
		updateDismantlerVnr({commit}, vnr){
			return VN_Service.updateDismantlerVnr(vnr)
			.then(r => {
				if( r.status === 200 ){
					return r.data;
				}
			})

		}
		/* Delete dismantler */
		/** wont exist - only mark for deletion **/
		/** deleteDismantler({ commit }, dismantler) {}, **/
	/* /ICRUD */

};
export const getters = {
	getDismantlers(state){
		return state.dismantlers;
	},
	getDismantlerByID: state => id => {
		return state.dismantlers.find(d => d.id === id);
	},
	has_dismantlers(state) {
		/* check locally stored dismantler count first */
		let stored_dismantler_count = localStorage.getItem( "dismantler_count" );
		if( stored_dismantler_count === null || stored_dismantler_count == 0 ){
			stored_dismantler_count = state.dismantlers.length;
			localStorage.setItem( "dismantler_count", stored_dismantler_count);
		}
		return stored_dismantler_count > 0;
	},

};
export const helper = {};
