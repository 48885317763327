import VN_Service from "@/services/VN_Services.js";
import store from "@/store/store";
import i18n from "@/i18n";
import RecyclerOffer from "@/store/classes/RecyclerOffer.js";
const $t = i18n();
export const namespaced = true;

export const state = {
    recycleroffers: [],
    cargotypes: [],
    recycleroffer: new RecyclerOffer()
}

export const mutations = {
    clearRecyclerOffers(state) {
        state.recycleroffers = []
    },
    addRecyclerOffers(state, recyclerOffers) {
    
        state.recycleroffers = recyclerOffers
    },
    clearCargoTypes(state) {
        state.cargotypes = []
    },
    addCargoTypes(state, cargotypes) {        
        cargotypes.forEach(cargotype => {state.cargotypes.push(cargotype)})
    },
    clearRecyclerOffer(state) {
        state.recycleroffer = []
    },
    addRecyclerOffer(state, tour) {
        state.recycleroffers.push(tour.tr)
    },
    updateRecyclerOffer(state, recycleroffer) {
        let newrecycleroffer = state.recycleroffers.find(
            r => r.id === recycleroffer.id
        )
        Object.assign(newrecycleroffer,recycleroffer.tr)
    },
    deleteRecyclerOffer(state, offerId) {
        let deleted = state.recycleroffers.find(
            r => r.id === offerId      
        );
        const index = state.recycleroffers.indexOf(deleted);
        state.recycleroffers.splice(index,1)  
    },
}

export const actions = {
    getAllRecyclerOffers({commit}, recyclerId) {
        return VN_Service.getAllRecyclerOffers(recyclerId).then(r=> {
            if (r.status === 200) {
                commit("clearRecyclerOffers")
                commit("addRecyclerOffers", r.data)
            }
        })
    },
    getCargoTypes({commit}) {
        return VN_Service.getCargoTypes().then(r=> {
            if (r.status === 200) {
                commit("clearCargoTypes")
                commit("addCargoTypes", r.data)
            }
        })
    },
    deleteRecyclerOfferById({commit}, offerId) {
        return VN_Service.deleteRecyclerOfferById(offerId).then(r=> {
            if (r.status === 200) {                
                commit("deleteRecyclerOffer",offerId)
                store.dispatch(
                    "errorhandling/addMessage",
                    $t.t("$gt.msg.system.success.exchange.offer.deleted")
                );  
            }
        })
    },
    async getRecyclerOfferById({commit}, tourId) {
        return await VN_Service.getRecyclerOfferById(tourId).then(r=> {
            if(r.status === 200) {
                return new RecyclerOffer(r.data)
            }
        })
    },
    async getCargosGeoById({commit}, tourId) {
        return await VN_Service.getCargosGeoById(tourId).then(r=> {
            if(r.status === 200) {
                return r.data
            }
        })
    },
    saveUpdateRecyclerOffer({commit}, tour) {
        if(tour.id === null) {
            return VN_Service.createRecyclerOffer(tour).then(r=> {            
                if(r.status === 200) {
                    commit("clearRecyclerOffer")              
                    commit("addRecyclerOffer", r.data)
                    store.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.exchange.offer.created")
                    );  
                    return r.data
                }
            })
        } else {
            return VN_Service.updateRecyclerOffer(tour).then(r=> {            
                if(r.status === 200) {                
                    commit("updateRecyclerOffer", r.data)
                    store.dispatch(
                        "errorhandling/addMessage",
                        $t.t("$gt.msg.system.success.exchange.offer.edited")
                    );  
                    return r.data
                }
            })
        }
    }
}

export const getters = {

}