
export default class Disposer{
	constructor( disposer ){
		/* properties list */
		this.id = null
		this.name = null;
		this.street = null;
		this.hsnr = null;
		this.country = null;
		this.zip = null;
		this.city = null;
		this.tel = null;
		this.fax = null;
		this.email = null;
		this.web = null;
		this.disposalnumber = null;
		this.type = null;
		this.waste = null;
		this.shared = null;

		if( !!disposer ){
			if(typeof(disposer.id)        !== "undefined") this.id        = disposer.id;
			if(typeof(disposer.name)        !== "undefined") this.name        = disposer.name;
			if(typeof(disposer.street)      !== "undefined") this.street      = disposer.street;
			if(typeof(disposer.hsnr)        !== "undefined") this.hsnr        = disposer.hsnr;
			if(typeof(disposer.country)     !== "undefined") this.country     = disposer.country;
			if(typeof(disposer.zip)         !== "undefined") this.zip         = disposer.zip;
			if(typeof(disposer.city)        !== "undefined") this.city        = disposer.city;
			if(typeof(disposer.tel)         !== "undefined") this.tel         = disposer.tel;
			if(typeof(disposer.fax)         !== "undefined") this.fax         = disposer.fax;
			if(typeof(disposer.email)       !== "undefined") this.email       = disposer.email;
			if(typeof(disposer.web)         !== "undefined") this.web         = disposer.web;
			if(typeof(disposer.type)	    !== "undefined") this.type        = disposer.type;			
			if (typeof disposer.disposalnumber   !== "undefined") this.disposalnumber   = disposer.disposalnumber;
		 	if (typeof disposer.waste !== "undefined")	this.waste = disposer.waste;
		 	if (typeof disposer.shared !== "undefined")	this.shared = disposer.shared;

		} else {

		}
	}
}
