import Utils from "@/utils.js";
export default class Incident {
    constructor(incident) {
        this.id = null;
        this.date = new Date().toISOString().substr(0, 10);
        this.description = null
        this.staffId = null
        this.incidentType = null

        if(!!incident) {
            if(typeof incident.id !== 'undefined') this.id = incident.id                                 
            if(typeof incident.date !== 'undefined') this.date = Utils.ISOToDateString(incident.date);
            if(typeof incident.description !== 'undefined') this.description = incident.description
            if(typeof incident.staffId !== 'undefined') this.staffId = incident.staffId
            if(typeof incident.incidentType !== 'undefined') this.incidentType = incident.incidentType
        }
    }

}