import ReceptionStatus from  "@/store/classes/composite/ReceptionStatus.js";

import Utils from "@/utils.js";
export default class ReceptionLite{
	constructor( reception ){
		/* define base values */
		this.id = null;
		this.carId = null;
		this.modifieddate = null;
		this.locked = null;
		this.vnr = null;
		this.licenceplate = null;
		this.vin = null;
		this.hasRemanOffers= false;
		this.keeper = null;
		this.car = null;
		this.company = null;
		this.result = {};

		if( !!reception ){
			
			

			if(typeof(reception.id)         !== "undefined") this.id         = reception.id;
			if(typeof(reception.carId)         !== "undefined") this.carId         = reception.carId;
            if(typeof(reception.modifieddate)       !== "undefined") this.modifieddate       = Utils.ISOToDateString(reception.modifieddate);
			if(typeof(reception.locked)     !== "undefined") this.locked     = reception.locked;
			if(typeof(reception.vnr)        !== "undefined") this.vnr        = reception.vnr;
			if(typeof(reception.licenceplate)!== "undefined")this.licenceplate  = reception.licenceplate;
			if(typeof(reception.vin) !== "undefined")        this.vin        = reception.vin;
			if(typeof(reception.hasRemanOffers) !=="undefined")  this.hasRemanOffers = reception.hasRemanOffers;
			if(typeof(reception.keeper)     !== "undefined") this.keeper     = reception.keeper;
            if(typeof(reception.car)     !== "undefined")    this.car        = reception.car;
			if(typeof(reception.company)     !== "undefined")this.company    = reception.company;
			if(typeof(reception.result)     !== "undefined") this.result    = new ReceptionStatus(reception.result);
		} else {
			
		}
	};
}