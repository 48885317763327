import VN_Service from "@/services/VN_Services.js";
import store from "@/store/store";
import i18n from "@/i18n";
import Disposal from "@/store/classes/Disposal"; 
import DisposalOut from "@/store/classes/DisposalOut"; 
import DisposalComposite from "@/store/classes/composite/DisposalComposite"; 

const $t = i18n();
export const namespaced = true;

export const state = {
    disposals: [],
    disposables: [],
    disposalOuts: [],
    disposal: new DisposalComposite()
}

export const mutations = {
    clearDisposals(state) {
        state.disposals = [];
    },
    addDisposals(state,data) {
        data.forEach(disposal => {state.disposals.push(new Disposal(disposal))})
    },
    clearDisposal(state) {
        state.disposal = new DisposalComposite();
    },
    addDisposal(state,data) {
        state.disposal = new DisposalComposite(data);
    },
    addDisposables(state,data) {
        data.forEach(disposable => {state.disposables.push(disposable)})
    },
    clearDisposables(state) {
        state.disposables = [];
    },
    addDisposalOuts(state,data) {
        data.forEach(disposalOut => {state.disposalOuts.push(new DisposalOut(disposalOut))})
    },
    clearDisposalOuts(state) {
        state.disposalOuts = [];
    },

    /* updateSingleBtbEntry(state, data){
        data.forEach(entry => {
            let newentry = state.btbentries.find(
                r => r.id === entry.id      
            );
            Object.assign( newentry, entry);
        })
    },    */
    deleteDisposal(state,disposalId) {       
            let deleted = state.disposals.find(
                r => r.id === disposalId      
            );
            const index = state.disposals.indexOf(deleted);
            state.disposals.splice(index,1)      
    }
}

export const actions = {
    getDisposals({commit},dismantlerId) {
        return VN_Service.getDisposalsOf(dismantlerId).then(r => {
            if(r.status === 200 ) {
                commit("clearDisposals")
                commit("addDisposals", r.data)  
                return r.data
            }
        })
    },
    getDisposalById({commit},disposal) {        
        return VN_Service.getDisposalById(disposal).then(r => {
            if(r.status === 200 ) {
                commit("clearDisposal")
                commit("addDisposal", r.data)  
                return new DisposalComposite(r.data)                
                
            }
        })
    },
    getDisposables({commit}, dismantlerId) {
        return VN_Service.getDisposables(dismantlerId)
            .then(r => {
                if(r.status === 200) {
                    commit("clearDisposables");
                    commit("addDisposables", r.data);
                    return r.data
                }
            })
    },
    getDisposalOuts({commit}, outId) {
        return VN_Service.getDisposalOuts(outId).then(r => {
            if(r.status === 200) {
                commit("clearDisposalOuts");
                commit("addDisposalOuts", r.data);
                return r.data;
            }
        })
    },
    saveUpdateDisposal({commit,dispatch}, disposal) {        
        return VN_Service.saveUpdateDisposal(disposal)
            .then(r => {
            if(r !== undefined) {
                if(r.status === 200) {
                    if(disposal.outId) {
                        dispatch("getDisposalOuts", disposal.outId)     
                    } else if(r.data.btbEntryOuts.length >= 1 && r.data.btbEntryOuts[0].hasOwnProperty("entryIns") && r.data.btbEntryOuts[0].entryIns[0].outId !== null) {
                        dispatch("getDisposalOuts", r.data.btbEntryOuts[0].entryIns[0].outId)     
                    } else {
                        commit("clearDisposalOuts");
                    }
                    dispatch("getDisposables", disposal.dismantlerId)
                    
                    if(disposal.disposal.id === null) {
                        store.dispatch(
                            "errorhandling/addMessage",
                            $t.t("$gt.msg.system.success.disposal.created")
                        );                
                        commit("clearDisposal")
                        commit("addDisposal", r.data) 
                        return new DisposalComposite(r.data);
                    } else {
                        store.dispatch(
                            "errorhandling/addMessage",
                            $t.t("$gt.msg.system.success.disposal.edited")
                        );            
                        commit("clearDisposal")
                        commit("addDisposal", r.data) 
                        return new DisposalComposite(r.data);
                    }              
                }
            } else {
                disposal.status = '400'
                return new DisposalComposite(disposal);
            }
        })
    },
    deleteDisposalEntry({commit}, disposalId) {
        return VN_Service.deleteDisposal(disposalId).then(r => {
            commit("deleteDisposal", disposalId)
            store.dispatch(
                "errorhandling/addMessage",
                $t.t("$gt.msg.system.success.disposal.deleted")
            );
        })
    }
}