// vue.config.js
module.exports = {
  publicPath: '/app/',
  devServer: {
    disableHostCheck: true,
    port: 3000,
    /* compress: false, */
    /* public: process.env.VUE_DEV === 'local' ? 'https://dev2.kaputt-gmbh.de/app' : 'http://localhost:3000/app',
    sockPath: "/app/sockjs-node"  */ 
	},
  /* chainWebpack: (config) => {
    config.plugins.delete('prefetch');
  }, */ 
  configureWebpack: {
		devtool: "source-map",
	},
    chainWebpack: (config) => {
      config.plugins.delete('prefetch');
  },
  css: {
    extract:false,
    loaderOptions: {
      sass: {
        implementation: require('sass'),
        additionalData: `@import "@/assets/variables.scss"; @import "@/assets/mixins.scss";`
      }
    }
	},

  pluginOptions: {
    i18n: {
      locale: 'de',
      fallbackLocale: 'de',
      localeDir: 'locales',
      enableInSFC: true
    }
  }
};
