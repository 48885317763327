import { client } from "@/services/client.js";
import store from "@/store/store";
import i18n from '@/i18n'

const $t = i18n();

export const namespaced = true;

export const state = {
    dismantlercontracts: [],
    remancontracts: []
};

export const mutations = {
    setDismanContracts(state, contracts) {
        contracts.forEach(contract => {state.dismantlercontracts.push(contract)})
        state.dismantlercontracts = state.dismantlercontracts.filter(contract => contract.remanAgree === true)
    },
    setRemanContracts(state, contracts) {
        contracts.forEach(contract => {state.remancontracts.push(contract)})
        state.remancontracts = state.remancontracts.filter(contract => contract.dismantlerAgree === true)        
    },
    clearDismanContracts(state) {
        state.dismantlercontracts = []
    },
    clearRemanContracts(state) {
        state.remancontracts = []
    }
};

export const actions = {
    getremansContracts({commit}, rid) {
        return client.base.get('reman/contract/by/reman/' + rid).then((r) => {
            commit("clearRemanContracts")
            commit("setRemanContracts", r.data)
            return r.data
        })
    },
    getContractByIds({commit}, data) {
        return client.base.get('reman/contract/by/reman/dismantler/' + data.remanId + '/' + data.dismantlerId ).then((r) => {
            if(r.status === 200) {
                return r.data
            }            
        })
    },
    signRemanContract({commit}, data) {
        return client.base.patch('reman/contract/sign/reman/' + data.rid, data.sign, {headers: {"Content-Type": "application/json"}})
        .then((r) => {
            commit("clearDismanContracts")
            commit("setDismanContracts", r.data)
            return r.data
        })
    },
    getdismantlersContracts({commit}, did) {
        return client.base.get('reman/contract/by/dismantler/' + did).then((r) => {
            commit("clearDismanContracts")
            commit("setDismanContracts", r.data)
            return r.data
        })
    },    
    signDismanContract({commit}, data) {
        return client.base.patch('reman/contract/sign/dismantler/' + data.rid, data.sign, {headers: {"Content-Type": "application/json"}})
        .then((r) => {
            commit("clearDismanContracts")
            commit("setDismanContracts", r.data)
            return r.data
        })
    },
};

export const getters = {
    dismantlersContracts(state) {
        return state.dismantlercontracts;
    },
    remansContracts(state) {
        return state.remancontracts;
    }
};