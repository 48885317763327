import VN_Service from "@/services/VN_Services.js";
import DisposerLite from "@/store/classes/composite/DisposerLite.js";
import MyDisposerComposite from "@/store/classes/composite/MyDisposerComposite.js";
import store from "@/store/store";
import i18n from '@/i18n'

const $t = i18n();

export const namespaced = true;

export const state = {
  disposers: [],
  mydisposers: []
};

export const mutations = {
  clearDisposers(state){
    state.disposers = [];
  },
  addDisposers(state, disposers_data){
    disposers_data.forEach(c => {state.disposers.push(new DisposerLite(c))})
  },
  clearMyDisposers(state){
    state.mydisposers = [];
  },
  addMyDisposers(state, disposers_data) {
    disposers_data.forEach(c => {state.mydisposers.push(c)})
  },
  addMyDisposer(state, mydisposer) {
    mydisposer = new DisposerLite({id: mydisposer.id});
    state.mydisposers.push(mydisposer);
  },
  updateMyDisposer(state, disposer) {
    let new_mydisposer = state.mydisposers.find(
      r => r.id === disposer.id
    );
    return new_mydisposer
  }
};

export const actions = {
  async newMyDisposer({ commit }, mydisposer) {   
    return await VN_Service.createMyDisposer(mydisposer)
      .then(r => {
        if (r.status === 200 || r.status === 201) {              
          commit("addMyDisposer", r.data);
          store.dispatch(
            "errorhandling/addMessage",
            $t.t("$gt.msg.system.success.mydisposer.created")
          );
          let mydisposerFormComposite = new MyDisposerComposite(r.data);
          commit("updateMyDisposer", mydisposerFormComposite);
          return mydisposerFormComposite;
        }
      })
  },
  /* Update (destructive edit) reception */
  updateMyDisposer({ commit }, disposer) {    
    return VN_Service.updateMyDisposer(disposer)
      .then(r => {
        if (r.status === 200) {
                              
          commit("addMyDisposer", r.data);
          store.dispatch(
            "errorhandling/addMessage",
            $t.t("$gt.msg.system.success.mydisposer.edited")
          );
          let mydisposerFormComposite = new MyDisposerComposite(r.data);
          commit("updateMyDisposer", mydisposerFormComposite);
          return mydisposerFormComposite;
        }
      })
    /* /update reception */
  },
  getAllDisposers({ commit}) {
    return VN_Service.indexDisposers()
    .then(r => {            
        if( r.status === 200 ){
            commit("clearDisposers");
            commit("addDisposers", JSON.parse(JSON.stringify(r.data)) );                
        }
    })
  },
  getMyDisposers({ commit}) {
    return VN_Service.getMyDisposers()
    .then(r => {            
        if( r.status === 200 ){    
            commit("clearMyDisposers");            
            commit("addMyDisposers", JSON.parse(JSON.stringify(r.data)) );                
        }
    })
  },
  /**get a single carrier by its id **/
  getDisposer({ commit }, disposerID) {
    return VN_Service.getMyDisposer(disposerID)
    .then(r => {
        if (r.status === 200) {                
        return r.data;
        }
    })
  },
  getAllMyDisposers({ commit }) {
    return VN_Service.getAllMyDisposers()
    .then(r => {
        if (r.status === 200) {  
          commit("clearMyDisposers");            
          commit("addMyDisposers", JSON.parse(JSON.stringify(r.data)) );               
          return r.data;
        }
    })
  },
};

export const getters = {
  getDisposers(state) {
    return state.disposers;
  },
  MyDisposers(state) {
    return state.mydisposers;
  }
};