export default class DeliveryComposite {
    constructor(delivery) {
        this.id = null;
        this.remanId = null;
        this.dismantlerId = null;
        this.locked = false;
        this.deliveryNumber = null;
        this.actionDate = new Date().toISOString().substr(0, 10);
        this.entries = [];

        if(!!delivery) {
            if(typeof(delivery.id)              !== "undefined") this.id                = delivery.id;
            if(typeof(delivery.remanId)         !== "undefined") this.remanId           = delivery.remanId;
            if(typeof(delivery.dismantlerId)    !== "undefined") this.dismantlerId      = delivery.dismantlerId;
            if(typeof(delivery.locked)          !== "undefined") this.locked            = delivery.locked;
            if(typeof(delivery.deliveryNumber)  !== "undefined") this.deliveryNumber    = delivery.deliveryNumber;
            if(typeof(delivery.actionDate)      !== "undefined") this.actionDate        = delivery.actionDate;
            if(typeof(delivery.entries)         !== "undefined") this.entries           = delivery.entries;
        }
    }

}