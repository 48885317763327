import VN_Service from "@/services/VN_Services.js";
import store from "@/store/store";
import i18n from "@/i18n";
import Car from '@/store/classes/Car'
import CarInventory from "@/store/classes/CarInventory.js"; 
import { client } from "@/services/client.js";

const $t = i18n();

export const namespaced = true;

export const state = {
    cars: []
}

export const mutations =  {    
    clearCars(state) {
        state.cars = [];
    },   
    addCars(state,data) {
        data.forEach(ol => {state.cars.push(new CarInventory(ol))})
    },
    updateCar(state, car){
        car.isSelectable = false 
        let updatecar = state.cars.find(
            r => r.id === car.id      
        );
        Object.assign( updatecar, car);
    },
    updateCars(state, cars){
        cars.forEach(car => { 
            car.isSelectable = false 
            let updatecar = state.cars.find(
                r => r.id === car.id     
            );
            
            Object.assign( updatecar, car);

        })
    }
}

export const actions = { 
    getCarInventory({commit},data) { // by dismantlerId
        return VN_Service.getAllCars(data)
        .then(r => {
            if(r.status === 200) {
                r.data.filter((cars) => {                
                    if(cars.hasBtbEntries === false && cars.step !== 'RECEPTION' || cars.step === 'VNLOCKED') {
                        cars.isSelectable = true
                    } else {
                        cars.isSelectable = false
                    }                 
                })
                commit("clearCars")
                commit("addCars", r.data)
                return new CarInventory(r.data)
            }
        }).catch(e => {
            
            
        });
    },
    getCar({commit},id) {
        return VN_Service.getCarById(id)
        .then(r => {
            if(r.status === 200) {                              
                return new Car(r.data);
            }
        }).catch(e => {
            
            
        });
    },
    getCarSteps() {
        return VN_Service.getCarSteps().then(r => {
            var steps = [];
            r.data.forEach(step => steps.push({name: step}))
            return steps
        })
    },
    addUpdateCar({commit,dispatch}, car) {
        return VN_Service.saveUpdateCar(car).then(r => {            
            if(r.status === 200) {           
                //commit("updateCar", r.data)        
                store.dispatch(
                    "errorhandling/addMessage",
                    this.$app.$t("$gt.msg.system.success.car.created")
                );
                return r.data;
            }
        })
    },
    updateCar({commit,dispatch}, car) {
        return VN_Service.saveUpdateCar(car).then(r => {
            if(r.status === 200) {                 
                //commit("updateCar", r.data)                        
                return new Car(r.data);
            }
        })
    },
    transferCar({commit}, car) {
        return VN_Service.transferCarToBtb(car.id).then(r => {
            if(r.status === 200) {   
                store.dispatch(
                    "errorhandling/addMessage",
                    $t.t("$gt.msg.system.success.reception.transfered")
                );
                r.data.hasBtbEntries = true;
                commit("updateCar", r.data)
            }
        })    
    },
    transferCars({commit}, cars) {
        return VN_Service.transferCarsToBtb(cars).then(r => {
            if(r.status === 200) {
                store.dispatch(
                    "errorhandling/addMessage",
                    $t.t("$gt.msg.system.success.reception.carstransfered")
                );
                commit("updateCars", r.data)
            }
        })    
    },
    updateCarStep({commit}, car) {
        return VN_Service.updateCarStep(car).then(r => {
            //VN_Service.prefillBtb(car)      
            store.dispatch(
                "errorhandling/addMessage",
                $t.t("$gt.msg.system.success.reception.transfered")
              );
            commit("updateCar", car)
        })
    },
    getReceptionByCar({commit}, carId) {
        return VN_Service.getReceptionByCar(carId)
    },
}