import Utils from "@/utils.js";
import { Config } from "@/services/client.js";
import VN_Service from "@/services/VN_Services.js";
import store from "@/store/store";
import i18n from '@/i18n'
import Vue from 'vue';
import themes from "../../themes";

import Vuetify from '../../vuetify'
Vue.prototype.$vuetify = Vuetify;

export const namespaced = true;
export const state = {
	appTheme: "Default",
	helpType: '',
	isLoading: false,
	languages: [
        { value: "de", text: "Deutsch", code: "de_DE", country: "de" },
        { value: "en", text: "English", code: "en_US", country: "gb"},
        { value: "es", text: "Español", code: "es_ES", country: "es" },
        { value: "fr", text: "Français", code: "fr_FR", country: "fr" },
        { value: "it", text: "Italiano", code: "it_IT",  country: "it" },
		{ value: "pt", text: "Português", code: "pt_PT",  country: "pt" },
		{ value: "tr", text: "Türkiye", code: "tr_TR",  country: "tr" },
        { value: "ru", text: "Русский", code: "ru_RU",  country: "ru" },
        { value: "cs", text: "Čeština", code: "cs_CZ",  country: "cz" },
        { value: "pl", text: "Polska", code: "pl_PL",  country: "pl" },
        { value: "sv", text: "Svenska", code: "sv_SE",  country: "se" },
        { value: "da", text: "Dansk", code: "da_DK",  country: "dk" },
        /* { value: "hu", text: "Magyar", code: "hu_HU",  country: "hu" }, */
        /* { value: "lv", text: "Latviešu", code: "lv_LV",  country: "lv" } */
      ],
      left_sidebar: Vue.prototype.$vuetify.framework.breakpoint.mdAndDown ? false : true,
      helpbar: false,
	  systemMessage : [],
	  cartbar: false,
	  lang: localStorage.getItem('language') ? localStorage.getItem('language') : process.env.VUE_APP_I18N_LOCALE,
	  version: ''
};
export const mutations = {
		loading(state, isLoading) {							
			state.isLoading = isLoading;			
		},
		setAppTheme(state, selected){					
			localStorage.setItem( "appTheme", selected);
			
			var isDark = localStorage.getItem('dark')
			if(isDark === "true") {
				Vuetify.framework.theme.dark = true 
				localStorage.setItem('dark', true)
			} else if(isDark === "false") {
				Vuetify.framework.theme.dark = false 
				localStorage.setItem('dark', false)
			}
			const theme = themes.find(r => r.name === selected)			
			const name = theme.name
			const dark = theme.dark;
            const light = theme.light;
            // set themes
            Object.keys(dark).forEach(i => {
               Vuetify.framework.theme.themes.dark[i] = dark[i];
            });
            Object.keys(light).forEach(i => {
               Vuetify.framework.theme.themes.light[i] = light[i];
            });
            // also save theme name to disable selection
           	Vuetify.framework.theme.themes.name = name;
			state.appTheme = selected;
		},
		setAppLang(state, lang){			
			state.lang = lang;
		},
		setSystemMessage(state, systemMessage){
			state.systemMessage = systemMessage;
        },
        
        CHANGE_LEFT_SIDEBAR(state) {
            state.left_sidebar = !state.left_sidebar
        },
        
        SET_LEFT_SIDEBAR(state, value){
            state.left_sidebar = value;
		},
        CHANGE_HELPBAR(state) {
            state.helpbar = !state.helpbar
        },
        
        SET_HELPBAR(state, value){
            state.helpbar = value;
		},
        SET_CARTBAR(state, value){
            state.cartbar = value;
		},
		CHANGE_CARTBAR(state) {
            state.cartbar = !state.cartbar
        },
		setSystemVersion(state, data) {
			state.version = data.version
		}
	
};
export const actions = {
    
    toggleSidebarState({commit}){
        commit("CHANGE_LEFT_SIDEBAR");
    },
    toggleHelpBarState({commit}){
        commit("CHANGE_HELPBAR");
    },
    toggleCartBar({commit}, value){
        commit("SET_CARTBAR", value);
    },
    
    setSidebarState({commit}, value){
        commit("SET_LEFT_SIDEBAR", value);
    },
    setHelpBarState({commit}, value){
        commit("SET_HELPBAR", value);
    },
	
	getAppTheme({commit}){
		const theme = localStorage.getItem("appTheme");
		commit("setAppTheme", (theme!=null) ? theme : "Default");
	},
	setAppLang({commit}, lang){		
		commit("setAppLang", lang);
	},
	
	setAppTheme({commit}, theme){
		commit("setAppTheme", theme);
	},
	
	getSystemMessage({commit}){
		VN_Service.getSystemMessage().then(r=>{
			commit("setSystemMessage", r.data)
		}).catch(e=>{
		/* 	
			 */
		});
	},
	getSystemInfo({commit}){
		VN_Service.getSystemInfo().then(r=>{
			commit("setSystemVersion", r.data)
		}).catch(e=>{
			/* 
			 */
		});
	},
};
export const getters = {
	getLang(state) {
		return state.lang;
	},
	getLanguageByLanguageCode: state => code =>{
		return state.languages.find(d => d.value === code);
	}
};
export const helper = {};
